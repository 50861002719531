import { COMMON_TRANSLATION_FILE, CUSTOM_PRJ_TRANSLATION_FILE, 
  ConfigurationPanleContentInputDialog, ConfigurationServiceSourceType, 
  Entity, IConfigurationService, ICustomService, ICustomServiceResponse } from "@lcs/frontend";
import i18next from "i18next";
import { Button } from "primereact/button";
import React, { Fragment, useRef, useState } from "react";
import api from "../api";
import StdEditableDataService from "../services/std.edit.data.service";
import { Toast } from "primereact/toast";
import AppointmentCustomService from "../services/appointment.custom.service";
import AppointmentConfigurationService from "../services/appointment.configuration.service";


export interface ButtonPlanningSettingsProps{
    culture: string;
}
  
function ButtonPlanningSettings(props: ButtonPlanningSettingsProps) {

    function openPlanningSettingsDlg() {
      dataService.getData().then((result) => {
        let res: any = result.data[0];
        res.startDate = new Date(res.startDate);
        setCurrentObj(res);
        changeSubComponentsVisibility("inputDialog", true);
        setModalType("EDIT");
        });
    }

  
    const [currentObj, setCurrentObj] = useState<any>({});
    const [modalType, setModalType] = useState("");
    const [needReload, setNeedReload] = useState(false);
    const myToast = useRef<any>();

    const randomKey = () => {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    };

    const [dialogKey, setDialogKey] = useState(() => {
        return randomKey();
      });
    const [subComponentVisibility, setSubComponentVisibility] = useState({
        inputDialog: false,
      });


      const currentEntity: Entity = {
        id: 0,
        label: "problema",
        apiPath: "problema"
    }

      const configurationService : IConfigurationService = new AppointmentConfigurationService({
        entityDefinitionSource: ConfigurationServiceSourceType.axios,
        axios: api,
        entityDefinitionJsonFilePath: null,
        entityRoleJsonFilePath: null        
      });
    const customService: ICustomService = new AppointmentCustomService(api);
    const dataService = new StdEditableDataService(currentEntity, customService, configurationService);

    const getData = () => {
      dataService.getData().then((result) => {
        let res: any = result.data[0];
        res.startDate = new Date(res.startDate);
        setCurrentObj(res);
      });
    }
  
    // useEffect(() => {
    //   getData();        
    // }, []);

    const entityFields = [{
        id: 0,
        name: "startDate",
        type: "DATETIME",
        label: "StartDate",
        title: "",
        mandatory: true,
        defaultValue: null,
        enumValues: null,
        srcData: "",
        srcDescr: "",
        maxTextLength: 1,
        numDecDigit: null,
        hidden: false,
        minNumValue: null,
        maxNumValue: null,
        dtButtonsHidden: true,
        dtFixedMidnight: true,
        dtStepMinute: 1,
        readonly: false,
        value: null
    }, {
        id: 1,
        name: "planningDays",
        type: "INT",
        label: "PlanningDays",
        title: "",
        mandatory: true,
        defaultValue: null,
        enumValues: null,
        srcData: "",
        srcDescr: "",
        maxTextLength: 1,
        numDecDigit: null,
        hidden: false,
        minNumValue: null,
        maxNumValue: null,
        dtButtonsHidden: false,
        dtFixedMidnight: false,
        dtStepMinute: 1,
        readonly: false,
        value: null
    }];

    const changeSubComponentsVisibility = (component: any, visible: boolean) => {
        setSubComponentVisibility({
          ...subComponentVisibility,
          ...{ [component]: visible },
        });
        visible && setDialogKey(randomKey());
      };
    
      const handleResult = (result: Promise<ICustomServiceResponse>):Promise<void> => {
        const inputDialogVisible: boolean = subComponentVisibility.inputDialog;
        return result.then(
          (response: ICustomServiceResponse) => {
            if (response.success) {
              myToast.current.show({
                severity: "success",
                detail: i18next.t("OperationCompleted"),
              });
              changeSubComponentsVisibility("inputDialog", false);
              setNeedReload(true);
            } else {
              myToast.current.show({
                severity: "error",
                summary: i18next.t("Error"),
                detail: i18next.t(response.reason, {
                  ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                }),
              });
            }
          },
          () => {
            myToast.current.show({
              severity: "error",
              summary: i18next.t("Error"),
              detail: i18next.t("RequestedOpFailed"),
            });
          }
        );
      };
    
      const addObj = (obj: any): Promise<void> => {
        return handleResult(dataService.addObj(obj));
      };
    
      const updateObj = (obj: any):Promise<void> => {
        obj.id = currentObj.id;
        return handleResult(dataService.updateObj(obj));
      };
    


    const dialog = (
        <ConfigurationPanleContentInputDialog
          currentObject={currentObj}
          modalType={modalType}
          changeVisibility={changeSubComponentsVisibility}
          dialogKey={dialogKey}
          dialogVisibility={subComponentVisibility.inputDialog}
          onCreateClick={addObj}
          onUpdateClick={updateObj}
          fields={entityFields}
          dataService={dataService}
          culture={props.culture}
        />);
    
    return (
        <Fragment>
            <Toast ref={myToast} />
            {dialog}
            <Button
                // label={i18next.t("PlanningSettings", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                icon="pi pi-cog"
                tooltip={i18next.t("PlanningSettings", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                style={{ margin: "0px" }}
                onClick={openPlanningSettingsDlg}
                />
        </Fragment>
    )
}

export default ButtonPlanningSettings;