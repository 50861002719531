import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { COMMON_TRANSLATION_FILE, CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';

export interface AppointmentClosureProps{
    eventDetails: any;
    setState: (state: string) => void;    
    setNote: (note: string) => void; 
    setDescrizione: (descrizione: string) => void; 
}

const AppointmentClosure = (props: AppointmentClosureProps) => {
    const NOTE_MAX_LENGTH = 255;
    const states = [{name: i18next.t("CHIUSO_SUCCESSO", { ns: CUSTOM_PRJ_TRANSLATION_FILE}), key: "CHIUSO_SUCCESSO"}, 
                    {name: i18next.t("CHIUSO_INTERLOCUTORIO", { ns: CUSTOM_PRJ_TRANSLATION_FILE}), key: "CHIUSO_INTERLOCUTORIO"},
                    {name: i18next.t("CHIUSO_FALLIMENTO", { ns: CUSTOM_PRJ_TRANSLATION_FILE}), key: "CHIUSO_FALLIMENTO"}];

    const [appointmentState, setAppointmentState] = useState<{ name: string; key: string; }>(states[0]);
    const [appointmentNote, setAppointmentNote] = useState<string>("");
    const [appointmentDescrizione, setAppointmentDescrizione] = useState<string>("");

    useEffect(() => {
        props.setState("CHIUSO_SUCCESSO");
    }, []);

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <label style={{ width: "200px", paddingLeft: "0px", color: "#007ad9"}}>
                {i18next.t("Status", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })}:
            </label>
            {
                    states.map((state) => {
                        return (
                            <div key={state.key} className="field-radiobutton">
                            <RadioButton inputId={state.key} name="state" value={state} 
                                        onChange={(e) => {
                                            setAppointmentState(e.value);
                                            props.setState(e.value.key);
                                        }}  
                                        checked={appointmentState.key === state.key}                                        
                            />
                            <label htmlFor={state.key}>{state.name}</label>
                        </div>)
                    })
            }
            <label style={{ width: "200px", paddingLeft: "0px", paddingTop: "0px", color: "#007ad9"}}>
                {i18next.t("Note", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })}: {props.eventDetails.tipoCliente}
            </label>            
            <InputTextarea value={appointmentNote} onChange={(e) => {
                setAppointmentNote(e.target.value);
                props.setNote(e.target.value);
                
                }}
                maxLength={NOTE_MAX_LENGTH}
                style={{
                    width: "100%",
                    resize: "none",
                    overflowY: "scroll",
                    height: "100px",
                }}/>     


            <label style={{ width: "200px", paddingLeft: "0px", paddingTop: "0px", color: "#007ad9"}}>
                {i18next.t("Descrizione", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })}: {props.eventDetails.tipoCliente}
            </label>        
            <InputTextarea value={appointmentDescrizione} onChange={(e) => {
                setAppointmentDescrizione(e.target.value);
                props.setDescrizione(e.target.value);
                
                }}
                maxLength={NOTE_MAX_LENGTH}
                style={{
                    width: "100%",
                    resize: "none",
                    overflowY: "scroll",
                    height: "100px",
                }}/> 
        </div>         
    );
}

export default AppointmentClosure;