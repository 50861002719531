import React from "react"
import PanelRegister from "../components/PanelRegister"

const PageRegister = () => {

    return(
        <>
            <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/images/LCSSfondo.png"})`, width: "100%", height: "99vh"}}>
                <PanelRegister />
            </div>
        </>
    )
}

export default PageRegister