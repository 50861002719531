import React, { useEffect, useState, Fragment } from "react";
import EmployeeService from "../services/employee.service";
import api from "../api";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";

export interface PanelSingleEmployeeProps {
    employeeId: number
}

function PanelSingleEmployee(props: PanelSingleEmployeeProps) {

    const [employee, setEmployee] = useState <any> ();
    let employeeId = props.employeeId;

    useEffect(() => {
        const employeeService = new EmployeeService(api);
        employeeService.getById(String(employeeId))
        .then(data => {
            setEmployee(data.data);
      });
 
    }, []);

    return <Fragment>
        <strong>{i18next.t("Dipendente", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}: {employee?employee.denominazione:""}</strong>
    </Fragment>
}

export default PanelSingleEmployee;