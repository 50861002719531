import { ConfigurationServiceSourceType, 
    EditableDataService, EditableDataServiceResponse, EntityField, IConfigurationService, 
    ICustomService, IDataServiceResponse } from "@lcs/frontend";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";
import AppointmentCustomService from "./appointment.custom.service";
import AppointmentConfigurationService from "./appointment.configuration.service";

class AppointmentEsclService implements EditableDataService {

    readonly axios: AxiosInstance;
    readonly appointmentId: number;
    readonly configurationService : IConfigurationService;
    readonly customService: ICustomService;
    AppointmentBasePath : string;
    
    constructor(axios: AxiosInstance, idAppuntamento: number) {
        this.axios = axios;
        this.appointmentId = idAppuntamento;
        this.configurationService = new AppointmentConfigurationService({
            entityDefinitionSource: ConfigurationServiceSourceType.axios,
            axios: this.axios,
            entityDefinitionJsonFilePath: null,
            entityRoleJsonFilePath: null
          });
        this.customService = new AppointmentCustomService(this.axios);
        this.AppointmentBasePath = (process.env.REACT_APP_BE_URL??"") + 
        BE_PREFIX.APPOINTMENT +
        "/api";
    }

    getData(): Promise<IDataServiceResponse> {
        let config: AxiosRequestConfig = {
            params: {
                idAppuntamento: this.appointmentId
            }
        };
        return this.axios
        .get(this.AppointmentBasePath + "/data/esclusione/filterByAppuntamento", config)
        .then((result: AxiosResponse<any>) => {
            let response = {
                data: result.data.data,
                totals: {}
            }
            return response;
        });
    }
    getReferenceData(path: string): Promise<object[]> {
        return this.customService.getEntityData(path);
    }

    addObj(obj: object): Promise<EditableDataServiceResponse> {
        return this.axios
          .post(this.AppointmentBasePath + "/data/esclusione", obj)
          .then((result : AxiosResponse<any>) => {
            return result.data;
        });
    }
    updateObj(obj: any): Promise<EditableDataServiceResponse> {
        const params = new URLSearchParams([['itemId', obj.id]]);
        return this.axios
          .put(this.AppointmentBasePath + "/data/esclusione", obj, { params })
          .then((result : AxiosResponse<any>) => result.data);
    }
    deleteObj(obj: any): Promise<EditableDataServiceResponse> {
        const params = new URLSearchParams([['itemId', obj.id]]);
        return this.axios
            .delete(this.AppointmentBasePath + "/data/esclusione", {params})
            .then((result : AxiosResponse<any>) => result.data);
    }
    getConfiguration(): Promise<EntityField[]> {
        return this.configurationService.getDefinitions("esclusione")
        .then( (result: EntityField[]) => {
            result.forEach((ef: EntityField) => {
                if (ef.name === "idAppuntamento") {
                    ef.hidden = true;
                    ef.readonly = true;
                    ef.defaultValue = this.appointmentId.toString();
                }
            })
            return result;
        });
    }
    getDocument(documentCode: string): Promise<string> {
        throw new Error("Method not implemented.");
    }
    
}

export default AppointmentEsclService;