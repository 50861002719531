import { AxiosInstance, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";
import { ICustomServiceResponse } from "@lcs/frontend";

export enum IntergrationType{
    MONITORING = "MONITORING",
    GOOGLE_CALENDAR = "GOOGLE-CALENDAR"
}

export interface CompanyIntegration{
    nome: string,
    integrato: boolean                    
}

export interface CompanyIntegrationServiceResponse extends ICustomServiceResponse {
    data: CompanyIntegration[]
}

class CompanyService  {
    readonly axios : AxiosInstance;   

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }


    getIntegrations = () : Promise<CompanyIntegrationServiceResponse> => {
        return this.axios
             .get((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/azienda/integrazioni")
             .then((result : AxiosResponse<CompanyIntegrationServiceResponse>) => result.data)
             .catch((e) => {
                var resp: CompanyIntegrationServiceResponse = {
                    data: [],
                    success: false,
                    reason: ""
                }
                return resp;
                } );      
    }
    
}

export default CompanyService;