import React from "react";
import { Tooltip } from "primereact/tooltip";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";

import "./ColorLegend.css";
export interface ColorLegendItem {
  color: string;
  label: string;
}

export interface ColorLegendProps {
  items: ColorLegendItem[];
}

const ColorLegend = (props: ColorLegendProps) => {
  return (
    <div className="container-color-legend">
      <div className="title">
        {i18next.t("Legend", { ns: [CUSTOM_PRJ_TRANSLATION_FILE] })}
      </div>
      <ul className="legend-ul">
        <Tooltip target=".legend-colorBox" mouseTrack mouseTrackTop={-30} />

        {props.items.map((item: ColorLegendItem, index) => (
          <li key={index}>
            <span
              key={index}
              className="legend-colorBox"
              style={{ backgroundColor: item.color }}
              data-pr-tooltip={item.label}
            ></span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ColorLegend;
