import { CalendarServiceResponse, ICalendarService } from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";

class CalendarService implements ICalendarService {

    readonly axios: AxiosInstance;
    readonly AppointmentBasePath : string;

    constructor(axios: AxiosInstance) {
      this.axios = axios;
      this.AppointmentBasePath = (process.env.REACT_APP_BE_URL??"") + 
                                    BE_PREFIX.APPOINTMENT +
                                    "/api/data/googlecal/";
    }

    getCalendar(id: string): Promise<CalendarServiceResponse> {
        let result: CalendarServiceResponse = {
            success: false,
            reason: "Generic error"
        };        
        const body = {
            calendarId: id
        }
        return this.axios
            .post(this.AppointmentBasePath+ "/caldetails", body)
            .then((response: AxiosResponse<any, any>) => response.data??result)
            .catch(() => {
                result.reason = "Generic error. See log.";
                return result;
            }
        );
    }

    getSincronizzazione(): Promise<CalendarServiceResponse> {
        let result: CalendarServiceResponse = {
            success: false,
            reason: "Generic error"
        };
        const config = {
            headers:{
               'Content-Type': 'application/json'
               }
        }
        return this.axios
            .post(this.AppointmentBasePath+ "/import", {}, config) //?idDipendente=1
            .then((res: AxiosResponse<any>) =>{
                return res.data??result ;
            })
            .catch(() => {
                result.reason = "Generic error. See log.";
                return result;
            }
        );
    }
}

export default CalendarService;