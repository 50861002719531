import React, { useRef } from "react";
import { FileUpload } from "primereact/fileupload";
import { BE_PREFIX } from "../utils/Constants";
import AppointmentService from "../services/appointment.service";
import api from "../api";
import { Toast } from "primereact/toast";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";

const FileUploadComponent = (props: {
  page: string;
  refresh: boolean;
  setRefresh: (e: boolean) => void;
  disabled?: boolean;
}) => {
  const appointmentService: AppointmentService = new AppointmentService(api);
  const fileUploadRef = useRef<FileUpload>(null);
  const myToast = useRef<any>();

  const url =
    (process.env.REACT_APP_BE_URL ?? "") +
    BE_PREFIX.APPOINTMENT +
    "/api/data/loader/" +
    props.page;

  const customBase64Uploader = async (event: any) => {
    const fileData = new Blob([event.files[0]]);
    const reader = new FileReader();
    reader.readAsDataURL(fileData);

    reader.onloadend = function () {
      const base64data = reader.result;
      const base64 = base64data?.slice(37);

      appointmentService
        .upload(props.page, { b64Bytes: base64 })
        .then((response: any) => {
          if (response.data.failedRecords === 0) {
            myToast.current.show({
              severity: "success",
              summary: i18next.t("UploadSuccess", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
            });
            props.setRefresh(!props.refresh);
            fileUploadRef.current?.clear();
            return;
          } else {
            myToast.current.show({
              severity: "error",
              summary:
                i18next.t("UploadError", {
                  ns: CUSTOM_PRJ_TRANSLATION_FILE,
                }) + response.data.failedRecords,
            });
            fileUploadRef.current?.clear();
            return;
          }
        });
    };
  };

  return (
    <>
      <Toast ref={myToast} />
      <FileUpload
        disabled={props.disabled}
        ref={fileUploadRef}
        mode="basic"
        name="b64Bytes"
        url={url}
        accept=".txt, .csv, .xlsx"
        uploadHandler={customBase64Uploader}
        maxFileSize={1000000}
        auto
        customUpload
        chooseLabel={i18next.t("Upload", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        })}
      />
    </>
  );
};

export default FileUploadComponent;
