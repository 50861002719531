/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from "react";
import {
  calculateAppointmentEndDate,
  getDateAndHour,
} from "../../utils/Common";

import { Button } from "primereact/button";
import AppointmentService from "../../services/appointment.service";
import api from "../../api";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import DialogEventDetails from "../DialogEventDetails";
import "./PanelActionDashboard.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import { Chart } from "primereact/chart";
import { Tooltip } from "primereact/tooltip";
import { DEFAULT_TABLE_PAGE_ROWS } from "../../utils/Constants";

interface DataAppointment {
  appointmentId: number;
  denominazione: string;
  idCliente: {
    id: number;
    value: string;
  };
  idDipendentePianificato: {
    id: number;
    value: string;
  };
  orarioPianificato: string;
  durataPianificata: string;
  flagRitardo: boolean;
  azioniEseguite: number;
}

function PanelActionDashboard(props: any) {
  const [isActive, setIsActive] = useState<Boolean>(false);
  const [eventDetailsVisible, setEventDetailsVisible] =
    useState<boolean>(false);
  const [appointmentId, setAppointmentId] = useState<number>();
  const [listAppointment, setListAppointment] = useState<DataAppointment[]>([]);
  const [redStopLigth, setRedStopLigth] = useState<number>(0);
  const [yellowStopLigth, setYellowStopLigth] = useState<number>(0);
  const [greenStopLigth, setGreenStopLigth] = useState<number>(0);
  const [bgStopLigthRed, setBgStopLigthRed] = useState<string>("");
  const [bgStopLigthYellow, setBgStopLigthYellow] = useState<string>("");
  const [bgStopLigthGreen, setBgStopLigthGreen] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const appointmentService = new AppointmentService(api);
  const [chartData, setChartData] = useState<any>([]);
  const [chartOptions, setChartOptions] = useState({});
  const initialFilters = {
    "idDipendentePianificato.value": {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    denominazione: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "idCliente.value": { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  };
  const [filters, setFilters] = useState(initialFilters);
  const [origListAppointment, setOrigListAppointment] = useState<any>();

  const filterChart = (result: any) => {
    let red: number = 0;
    let yellow: number = 0;
    let green: number = 0;

    if (chartData.length > 0) {
      return;
    }

    result.map((res: any) => {
      if (res.stato === "CHIUSO_FALLIMENTO") {
        red = red + 1;
      }
      if (res.stato === "CHIUSO_INTERLOCUTORIO") {
        yellow = yellow + 1;
      } else if (res.stato === "CHIUSO_SUCCESSO") {
        green = green + 1;
      }
    });
    chartData.push(red, yellow, green);
  };

  const getAppointments = (): any => {
    let params: any = [];
    if (props.stateView !== "orderClosed") {
      params = ["CONFERMATO"];
    } else {
      params = [
        "CHIUSO_FALLIMENTO",
        "CHIUSO_INTERLOCUTORIO",
        "CHIUSO_SUCCESSO",
      ];
    }
    return appointmentService
      .getAppointementDashboard(params)
      .then((response) => {
        if (response.success) {
          setListAppointment(response.data);
          setOrigListAppointment(response.data);
          const red = response.data.filter(
            (el: any) => el.flagRitardo === true && el.azioniEseguite === 0
          );

          const yellow = response.data.filter(
            (el: any) => el.flagRitardo === true && el.azioniEseguite > 0
          );

          const green = response.data.filter(
            (el: any) => el.flagRitardo === false
          );
          setYellowStopLigth(yellow.length);
          setRedStopLigth(red.length);
          setGreenStopLigth(green.length);
          filterChart(response.data);
        } else null;
      })
      .catch((reason) => {
        return [];
      });
  };

  useEffect(() => {
    setChartData([]);
    getAppointments();

    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setChartOptions(options);

    const data = {
      labels: [
        "Chiuso fallimento ",
        "Chiuso interlocutorio ",
        "Chiuso successo ",
      ],
      datasets: [
        {
          data: chartData,
          backgroundColor: ["#cc3232", "#e7b416", "#2dc937"],
          hoverBackgroundColor: ["#c86f6f", "#e8c557", "#5db062"],
        },
      ],
    };

    setChartData(data);
  }, []);

  const hide = () => {
    setVisible(false);
    setChartData;
  };

  //CLICK SUL SEMAFORO ROSSO
  const reportRed = () => {
    if (!isActive) {
      const red = origListAppointment.filter(
        (el: any) => el.flagRitardo === true && el.azioniEseguite === 0
      );

      setListAppointment(red);
      setBgStopLigthRed("#cc3232ad");
      setBgStopLigthGreen("");
      setBgStopLigthYellow("");
    } else {
      setListAppointment(origListAppointment);
      setBgStopLigthRed("");
      setBgStopLigthGreen("");
      setBgStopLigthYellow("");
    }
    setIsActive(!isActive);
  };
  //CLICK SUL SEMAFORO GIALLO
  const reportYellow = () => {
    if (!isActive) {
      const yellow = origListAppointment.filter(
        (el: any) => el.flagRitardo === true && el.azioniEseguite !== 0
      );

      setListAppointment(yellow);
      setBgStopLigthYellow("#ffc40fad");
      setBgStopLigthGreen("");
      setBgStopLigthRed("");
    } else {
      setListAppointment(origListAppointment);
      setBgStopLigthRed("");
      setBgStopLigthGreen("");
      setBgStopLigthYellow("");
    }
    setIsActive(!isActive);
  };
  //CLICK SUL SEMAFORO VERDE
  const reportGreen = () => {
    if (!isActive) {
      const green = origListAppointment.filter(
        (el: any) => el.flagRitardo === false
      );
      setListAppointment(green);
      setBgStopLigthGreen("#2dc937ad");
      setBgStopLigthRed("");
      setBgStopLigthYellow("");
    } else {
      setListAppointment(origListAppointment);
      setBgStopLigthRed("");
      setBgStopLigthGreen("");
      setBgStopLigthYellow("");
    }
    setIsActive(!isActive);
  };

  const getSeverity = (el: any) => {
    let result: any = "";
    el.stato === "CHIUSO_SUCCESSO"
      ? (result = "success")
      : el.stato === "CHIUSO_FALLIMENTO"
      ? (result = "danger")
      : el.stato === "CHIUSO_INTERLOCUTORIO"
      ? (result = "warning")
      : null;
    return result;
  };
  const calcFineAttivita = (el: any) => {
    if (props.stateView === "orderClosed") {
      const endDate = calculateAppointmentEndDate(
        new Date(el.orarioPianificato),
        el.durataPianificata
      );
      if (el.flagRitardo === true) {
        return (
          <Tag
            value={getDateAndHour(endDate.toString(), "it-IT")}
            severity={getSeverity(el)}
          />
        );
      } else
        return (
          <Tag
            value={getDateAndHour(endDate.toString(), "it-IT")}
            severity={getSeverity(el)}
          />
        );
    } else if (props.stateView !== "orderClosed") {
      const endDate = calculateAppointmentEndDate(
        new Date(el.orarioPianificato),
        el.durataPianificata
      );

      if (el.flagRitardo === true) {
        return (
          <Tag
            value={getDateAndHour(endDate.toString(), "it-IT")}
            severity="danger"
          />
        );
      } else return getDateAndHour(endDate.toString(), "it-IT");
    }
  };
  const delay = (el: any) => {
    return el.flagRitardo === true ? (
      <Tag value="Ritardo" severity="danger" />
    ) : (
      <Tag value="Puntuale" severity="success" />
    );
  };

  const openEventDetails = (id: any) => {
    return (
      <Button
        icon="pi pi-search"
        onClick={() => {
          setEventDetailsVisible(true);
          setAppointmentId(id.appointmentId);
        }}
      />
    );
  };
  return (
    <>
      <div className="containerStopLigth">
        <div
          onClick={() => reportRed()}
          style={{ cursor: "pointer", backgroundColor: `${bgStopLigthRed}` }}
          className={redStopLigth > 0 ? "stoplightRedActive" : "stoplightRed"}
          data-pr-tooltip="Appuntamenti in ritardo senza azioni correttive"
        >
          <div className="numberStopLigth">{redStopLigth}</div>
        </div>

        <div
          onClick={() => reportYellow()}
          style={{ cursor: "pointer", backgroundColor: `${bgStopLigthYellow}` }}
          className={
            yellowStopLigth > 0 ? "stoplightYellowActive" : "stoplightYellow"
          }
        >
          <div className="numberStopLigth">{yellowStopLigth}</div>
        </div>

        <div
          onClick={() => reportGreen()}
          style={{ cursor: "pointer", backgroundColor: `${bgStopLigthGreen}` }}
          className="stoplightGreen"
        >
          <div className="numberStopLigth">{greenStopLigth}</div>
        </div>
      </div>
      <div className="container_button">
        <div className="legend">
          <div className="legend-item">
            <Tooltip
              target=".legend-color"
              mouseTrack
              mouseTrackLeft={10}
              mouseTrackTop={10}
              position="top"
            />

            <div
              className="legend-color"
              style={{ backgroundColor: "#cc3232" }}
              data-pr-tooltip="Appuntamenti in ritardo senza azioni correttive"
            ></div>
          </div>
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "#ffc40f" }}
              data-pr-tooltip="Appuntamenti in ritardo con azioni correttive"
            ></div>
          </div>
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "#2dc937" }}
              data-pr-tooltip="Appuntamenti puntuali"
            ></div>
          </div>
        </div>
        <Button
          style={{ marginRight: "5px" }}
          icon="pi pi-sync"
          onClick={() => getAppointments()}
          tooltip={i18next.t("Refresh", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          })}
          tooltipOptions={{ showDelay: 1000, hideDelay: 150 }}
        />
        <Button
          icon="pi pi-filter-slash"
          onClick={() => {
            setFilters(initialFilters);
            setListAppointment(origListAppointment);
          }}
          tooltip={i18next.t("ResetAllFilter", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          })}
          tooltipOptions={{ showDelay: 1000, hideDelay: 150 }}
        />
        {props.stateView === "orderClosed" ? (
          <Button
            style={{ marginLeft: "5px" }}
            icon="pi pi-chart-pie"
            onClick={() => setVisible(true)}
            tooltip={i18next.t("ViewHistoricalChart", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            tooltipOptions={{ showDelay: 1000, hideDelay: 150 }}
          />
        ) : null}
      </div>
      <div style={{ marginTop: "50px" }}>
        {" "}
        <DataTable
          className="red-border-row"
          value={listAppointment}
          paginator
          rows={DEFAULT_TABLE_PAGE_ROWS}
          dataKey="denominazione"
          filters={filters}
          filterDisplay="row"
          // loading={loading}
          globalFilterFields={[
            "idDipendentePianificato.value",
            "denominazione",
            "Cliente",
            "status",
          ]}
          // header={header}
          emptyMessage={i18next.t("No data found", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          })}
        >
          <Column field="appointmentId" body={openEventDetails} />
          <Column
            field="idDipendentePianificato.value"
            header={i18next.t("Dipendente", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            filter
            style={{ minWidth: "12rem" }}
          />
          <Column
            field="denominazione"
            header={i18next.t("Denominazione", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            filterField="denominazione"
            style={{ minWidth: "12rem" }}
            filter
            // filterPlaceholder="Search"
          />
          <Column
            field="idCliente.value"
            header={i18next.t("Cliente", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            filterMenuStyle={{ width: "14rem" }}
            style={{ minWidth: "14rem" }}
            filter
          />
          <Column
            field="flagRitardo"
            header={i18next.t("Ritardo", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            filterMenuStyle={{ width: "14rem" }}
            style={{ minWidth: "14rem" }}
            body={delay}
            sortable
          />
          <Column
            header={i18next.t("Fine Attività", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            dataType="boolean"
            style={{ minWidth: "6rem" }}
            body={calcFineAttivita}
            sortable
          />
        </DataTable>
        <DialogEventDetails
          eventDetailsVisible={eventDetailsVisible}
          setEventDetailsVisible={setEventDetailsVisible}
          appointmentId={appointmentId}
          culture={props.culture ?? "it-IT"}
          monIntegration={props.monIntegration}
          onAction={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
        <Dialog
          header="Totale appuntamenti chiusi"
          visible={visible}
          style={{ width: "35vw", height: "30vw" }}
          onHide={() => {
            hide();
          }}
        >
          <div className="card flex justify-content-center">
            <Chart
              type="pie"
              data={chartData}
              options={chartOptions}
              className="chart-w-full"
            />
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default PanelActionDashboard;
