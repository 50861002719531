import {
  EditableDataService,
  EditableDataServiceResponse,
  EntityField,
  ICustomServiceResponse,
  IDataServiceResponse,
} from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";
import { LinkedResource } from "./common.types";
import CustomerFilterService from "./customer.filter.service";
import EmployeeFilterService from "./employee.filter.service";
import SpecializationFilterService from "./specialization.filter.service";
import { BE_PREFIX } from "../utils/Constants";

export interface AppointmentIn {
  id: number;
  idDipendenteStabilito?: LinkedResource;
  orarioStabilito?: string;
}

export enum APPOINTMENT_LOCATION {
  REMOTO, // Nessun vincolo
  PRESSO_DIPENDENTE, //
  PRESSO_CLIENTE,
  ALTRA_SEDE,
}

export interface AppointmentOut extends AppointmentIn {
  idDipendentePianificato?: LinkedResource;
  orarioPianificato?: string;
  idCliente?: LinkedResource;
  denominazione?: string;
  durataPianificata?: string;
  durataStimataInMezzore?: string;
  idAzienda?: LinkedResource;
  idDipendenteEscluso?: LinkedResource;
  idDipendentePreferito?: LinkedResource;
  idProblema?: LinkedResource;
  luogoAppuntamento?: APPOINTMENT_LOCATION;
  indirizzo?: string;
  specializzazioni?: LinkedResource[];
  idTipoCliente?: LinkedResource;
  orarioPreferito?: string;
  stato?: APPOINTMENT_STATE;
  wfStatus?: APPOINTMENT_WF_STATE;
  citta?: string;
  provincia?: string;
  indirizzoPianificato?: string;
  cittaPianificata?: string;
  provinciaPianificata?: string;
  note?: string;
  risultatoCheck?: any; //RisultatoChecks,
  descrizione?: string;
}

export enum APPOINTMENT_STATE {
  PIANIFICATO = "PIANIFICATO",
  CHIUSO_INTERLOCUTORIO = "CHIUSO_INTERLOCUTORIO",
  CHIUSO_FALLIMENTO = "CHIUSO_FALLIMENTO",
  CHIUSO_SUCCESSO = "CHIUSO_SUCCESSO",
}

export enum APPOINTMENT_WF_STATE {
  INIIZIALE = "INIZIALE",
  PIANIFICATO = "PIANIFICATO",
  CONFERMATO = "CONFERMATO",
  CHIUSO_INTERLOCUTORIO = "CHIUSO_INTERLOCUTORIO",
  CHIUSO_FALLIMENTO = "CHIUSO_FALLIMENTO",
  CHIUSO_SUCCESSO = "CHIUSO_SUCCESSO",
}

export interface AppointmentServiceResponse extends ICustomServiceResponse {
  data: AppointmentOut[];
}

export interface AppointmentServiceSingleResponse
  extends ICustomServiceResponse {
  data: AppointmentOut;
}

export interface Plan {
  id: number;
  denominazione: string;
  idProblema: number;
  exportDate: Date;
  submitDate: Date;
  solutionDate: Date;
  completionDate: Date;
  problemJson: string;
  solutionJson: string;
  stato: string; // EXPORTED,SUBMITTED,SOLVED,COMPLETED
}

export interface SimpleAppointmentRequest {
  appointmentId: number;
}

export interface CloseAppointmentRequest {
  appointmentId: number;
  status: APPOINTMENT_STATE;
  note: string;
  descrizione: string;
}

export interface PlanAppointmentRequest {
  appointmentId: number;
  planEmployeeId: number;
  planTime: Date;
  plannedAddress: string | null;
  plannedCity: string | null;
  plannedProvince: string | null;
}

export interface ConfirmAppointmentRequest {
  appointmentId: number;
  idDipendenteStabilito: number;
  orarioStabilito: string;
}

export interface ConfirmAppointmentListRequest {
  appointmentList: ConfirmAppointmentRequest[];
}

export interface ConfirmAppointmentResponse extends ICustomServiceResponse {
  appointmentId: number;
}

export interface ConfirmAppointmentListResponse extends ICustomServiceResponse {
  cappWithErrorsResponseList: ConfirmAppointmentResponse[];
}


export interface Regole {
  res: RegoleConfigurazione;
  success: boolean;
  reason: string;
  rulesConfig: RegoleConfigurazione[];
}

export interface RegoleConfigurazione {
  name: string;
  description: string;
  enable: boolean;
  blocking: boolean;
  type: string;
  scoreLevel: number;
  penaltyWeight: number;
  order: number;
}

class AppointmentService implements EditableDataService {
  readonly axios: AxiosInstance;
  readonly customerFilterService: CustomerFilterService;
  readonly employeeFilterService: EmployeeFilterService;
  readonly specializationFilterService: SpecializationFilterService;
  readonly manipulateEntityFields?: (efArray: any) => void;
  readonly AppointmentBasePath: string;

  constructor(
    axios: AxiosInstance,
    manipulateEntityFields?: (efArray: any) => void
  ) {
    this.axios = axios;
    this.customerFilterService = new CustomerFilterService(axios);
    this.employeeFilterService = new EmployeeFilterService(axios);
    this.specializationFilterService = new SpecializationFilterService(axios);
    this.manipulateEntityFields = manipulateEntityFields;
    this.AppointmentBasePath =
      (process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api";
  }

  getAllClosed = (): Promise<AppointmentServiceResponse> => {
    return this.axios
      .get(this.AppointmentBasePath + "/data/appuntamento/chiuso/")
      .then((result: AxiosResponse<AppointmentServiceResponse>) => result.data);
  };

  getAll = (): Promise<AppointmentServiceResponse> => {
    return this.axios
      .get(this.AppointmentBasePath + "/data/appuntamento/")
      .then((result: AxiosResponse<AppointmentServiceResponse>) => result.data);
  };

  getById = (
    appointmentId: string
  ): Promise<AppointmentServiceSingleResponse> => {
    return this.axios
      .get(this.AppointmentBasePath + "/data/appuntamento/" + appointmentId)
      .then(
        (result: AxiosResponse<AppointmentServiceSingleResponse>) => result.data
      );
  };

  plan = (
    planAppointmentRequest: PlanAppointmentRequest
  ): Promise<ICustomServiceResponse> => {
    return this.axios
      .post(
        this.AppointmentBasePath + "/behavior/planAppuntamento",
        planAppointmentRequest
      )
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  };

  unplan = (
    planAppointmentRequest: SimpleAppointmentRequest
  ): Promise<ICustomServiceResponse> => {
    return this.axios
      .post(
        this.AppointmentBasePath + "/behavior/unplanAppuntamento",
        planAppointmentRequest
      )
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  };

  comfirm = (
    ConfirmAppointmentRequest: ConfirmAppointmentRequest
  ): Promise<ICustomServiceResponse> => {
    return this.axios
      .post(
        this.AppointmentBasePath + "/behavior/confirmAppuntamento",
        ConfirmAppointmentRequest
      )
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  };

  unconfirm = (
    ConfirmAppointmentRequest: SimpleAppointmentRequest
  ): Promise<ICustomServiceResponse> => {
    return this.axios
      .post(
        this.AppointmentBasePath + "/behavior/unconfirmAppuntamento",
        ConfirmAppointmentRequest
      )
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  };

  confirmAppointmentList = (
    confirmAppointmentRequest: ConfirmAppointmentRequest[]
  ): Promise<ConfirmAppointmentListResponse> => {
    const request: ConfirmAppointmentListRequest = {
      appointmentList: confirmAppointmentRequest
    }
    return this.axios
      .post(this.AppointmentBasePath + "/behavior/confirmListaAppuntamenti", request)
      .then((result: AxiosResponse<ConfirmAppointmentListResponse>) => result.data);
  };

  unconfirmAppointmentList = (
    ConfirmAppointmentRequest: ConfirmAppointmentRequest[]
  ): Promise<ConfirmAppointmentListResponse> => {
    
    return this.axios
      .post(this.AppointmentBasePath + "/behavior/unconfirmListaAppuntamenti", {
        appointmentList: ConfirmAppointmentRequest,
      })
      .then((result: AxiosResponse<ConfirmAppointmentListResponse>) => result.data);
  };

  close = (
    appointment: CloseAppointmentRequest
  ): Promise<ICustomServiceResponse> => {
    return this.axios
      .post(
        this.AppointmentBasePath + "/behavior/closeAppuntamento",
        appointment
      )
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  };

  getMostRecentPlan = (): Promise<Plan> => {
    return this.axios
      .get(this.AppointmentBasePath + "/data/pianificazione/mostrecent")
      .then((result: AxiosResponse<Plan>) => result.data);
  };

  deleteMostRecentPlan(obj: any): Promise<EditableDataServiceResponse> {
    const params = new URLSearchParams([["itemId", obj.id]]);
    return this.axios
      .delete(this.AppointmentBasePath + "/data/pianificazione", { params })
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  }

  getData(): Promise<IDataServiceResponse> {
    return this.axios
      .get(this.AppointmentBasePath + "/data/appuntamento")
      .then((result: AxiosResponse<any>) => result.data);
  }
  getReferenceData(path: string): Promise<object[]> {
    switch (path) {
      case "cliente":
        return this.customerFilterService.getData();
      case "specializzazione":
        return this.specializationFilterService.getData();
      case "dipendente":
        return this.employeeFilterService.getData();
      default:
        return Promise.resolve([]);
    }
  }

  addObj(obj: object): Promise<EditableDataServiceResponse> {
    return this.axios
      .post(this.AppointmentBasePath + "/data/appuntamento", obj)
      .then((result: AxiosResponse<any>) => {
        return result.data;
      });
  }

  updateObj(obj: any): Promise<EditableDataServiceResponse> {
    const params = new URLSearchParams([["itemId", obj.id]]);
    return this.axios
      .put(this.AppointmentBasePath + "/data/appuntamento", obj, { params })
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  }

  deleteObj(obj: any): Promise<EditableDataServiceResponse> {
    const params = new URLSearchParams([["itemId", obj.id]]);
    return this.axios
      .delete(this.AppointmentBasePath + "/data/appuntamento", { params })
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  }

  getConfiguration(): Promise<EntityField[]> {
    return this.axios
      .get<EntityField[]>(
        this.AppointmentBasePath + "/configuration/entityField/appuntamento"
      )
      .then((result) => {
        let r = result.data;
        if (this.manipulateEntityFields) this.manipulateEntityFields(r);
        return r;
      });
    //return Promise.resolve(this.codedConfiguration);
  }

  getAppuntamentoIstogramma(dataInizio: any, dataFine: any): Promise<any> {
    let config = {
      params: {
        from: dataInizio,
        to: dataFine,
      },
    };
    return this.axios
      .get(
        this.AppointmentBasePath + "/data/appuntamento/printHistogram",
        config
      )
      .then((result: AxiosResponse<any>) => result.data);
  }

  getMostrecentdetails(): Promise<any> {
    return this.axios
      .get(this.AppointmentBasePath + "/data/pianificazione/mostrecentdetails")
      .then((result: AxiosResponse<any>) => result.data);
  }

  initRegoleConfigurazione = (): Promise<any> => {
    return this.axios
      .post(this.AppointmentBasePath + "/data/regolaPianificazione/init")
      .then((result: AxiosResponse<any>) => result);
  };

  clearRegole(): Promise<any> {
    return this.axios
      .put(this.AppointmentBasePath + "/data/regolaPianificazione/clear")
      .then((result: AxiosResponse<any>) => result.data);
  }

  resetRegole(): Promise<any> {
    return this.axios
      .post(this.AppointmentBasePath + "/data/regolaPianificazione/reset")
      .then((result: AxiosResponse<any>) => result.data);
  }

  getRegoleConfigurazione = (): Promise<Regole> => {
    return this.axios
      .get(this.AppointmentBasePath + "/data/regolaPianificazione")
      .then((result: AxiosResponse<Regole>) => result.data);
  };

  postRegoleConfigurazione = (regole: RegoleConfigurazione): Promise<any> => {
    const body = regole;

    return this.axios
      .post(this.AppointmentBasePath + "/data/regolaPianificazione", body)
      .then((result: AxiosResponse<any>) => result);
  };

  getAppointmentWithoutSpec = (): Promise<any> => {
    return this.axios
      .get(
        this.AppointmentBasePath +
          "/data/appuntamento/appointmentsWithoutSpecialization"
      )
      .then((result: AxiosResponse<any>) => result.data);
  };

  getAppointementKpi = (data: any): Promise<any> => {
    return this.axios
      .post(
        (process.env.REACT_APP_BE_URL ?? "") +
          BE_PREFIX.APPOINTMENT +
          "/api/data/appuntamento/kpi",
        data
      )
      .then((result: AxiosResponse<any>) => result.data);
  };

  getAppointementDashboard = (data: any): Promise<any> => {
    const status = "status=";
    const separator = "&";
    let params = "";
    // if data.length = 1 status+data[0] else ciclo
    if (data.length === 1) {
      params = `${status}${data[0]}`;
    } else {
      for (let i = 0; i < data.length; i++) {
        params += `${status}${data[i]}${separator}`;
      }
      params = params.slice(0, -1);
    }
    return this.axios
      .get(
        (process.env.REACT_APP_BE_URL ?? "") +
          BE_PREFIX.APPOINTMENT +
          `/api/data/appuntamento/dashboard?${params}`
        // "/api/data/appuntamento/dashboard"
      )
      .then((result: AxiosResponse<any>) => result.data);
  };

  upload = (url: any, base64: any): Promise<any> => {
    return this.axios
      .post((process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api/data/loader/" + url , base64)
      .then((result: AxiosResponse<any>) => result.data);
  };

  codedConfiguration: EntityField[] = [
    {
      id: 10,
      name: "stato",
      type: "ENUM",
      label: "Stato",
      title: "",
      mandatory: false,
      defaultValue: null,
      enumValues: null,
      srcData:
        '["PIANIFICATO","CHIUSO_INTERLOCUTORIO","CHIUSO_FALLIMENTO","CHIUSO_SUCCESSO"]',
      srcDescr: "",
      maxTextLength: 1,
      numDecDigit: null,
      hidden: false,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: true,
      value: null,
    },
    {
      id: 0,
      name: "denominazione",
      type: "TEXT",
      label: "Denominazione",
      title: "",
      mandatory: true,
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: 100,
      numDecDigit: null,
      hidden: false,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 1,
      name: "idCliente",
      type: "ENTITY",
      label: "Cliente",
      title: "",
      mandatory: true,
      defaultValue: null,
      enumValues: null,
      srcData: "cliente",
      srcDescr: "denominazione",
      maxTextLength: null,
      numDecDigit: null,
      hidden: false,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 2,
      name: "idSpecializzazione",
      type: "ENTITY",
      label: "Specializzazione",
      title: "",
      mandatory: true,
      defaultValue: null,
      enumValues: null,
      srcData: "specializzazione",
      srcDescr: "denominazione",
      maxTextLength: null,
      numDecDigit: null,
      hidden: false,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 3,
      name: "durataStimataInMezzore",
      type: "TIME",
      label: "DurataStimataInMezzore",
      mandatory: true,
      title: "",
      defaultValue: "01:00",
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: null,
      numDecDigit: null,
      hidden: false,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: true,
      dtFixedMidnight: false,
      dtStepMinute: 30,
      readonly: false,
      value: null,
    },
    {
      id: 4,
      name: "luogoAppuntamento",
      type: "ENUM",
      label: "LuogoAppuntamento",
      mandatory: true,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: '["REMOTO","PRESSO_DIPENDENTE","PRESSO_CLIENTE","ALTRA_SEDE"]',
      srcDescr: "",
      maxTextLength: null,
      numDecDigit: null,
      hidden: false,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 5,
      name: "indirizzo",
      type: "TEXT",
      label: "Indirizzo",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: 100,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 6,
      name: "citta",
      type: "TEXT",
      label: "Citta",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: 100,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 7,
      name: "provincia",
      type: "ENUM",
      label: "Provincia",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData:
        '["AG","AL","AN","AO","AP","AQ","AR","AT","AV","BA","BG","BI","BL","BN","BO","BR","BS","BT","BZ","CA","CB","CE","CH","CL","CN","CO","CR","CS","CT","CZ","EN","FC","FE","FG","FI","FM","FR","GE","GO","GR","IM","IS","KR","LC","LE","LI","LO","LT","LU","MB","MC","ME","MI","MN","MO","MS","MT","NA","NO","NU","OR","PA","PC","PD","PE","PG","PI","PN","PO","PR","PT","PU","PV","PZ","RA","RC","RE","RG","RI","RM","RN","RO","SA","SI","SO","SP","SR","SS","SU","SV","TA","TE","TN","TO","TP","TR","TS","TV","UD","VA","VB","VC","VE","VI","VR","VT","VV"]',
      srcDescr: "",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 8,
      name: "idDipendentePreferito",
      type: "ENTITY",
      label: "DipendentePreferito",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "dipendente",
      srcDescr: "denominazione",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 9,
      name: "orarioPreferito",
      type: "DATETIME",
      label: "OrarioPreferito",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: true,
      dtFixedMidnight: false,
      dtStepMinute: 30,
      readonly: false,
      value: null,
    },
    {
      id: 10,
      name: "idDipendenteEscluso",
      type: "ENTITY",
      label: "DipendenteEscluso",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "dipendente",
      srcDescr: "denominazione",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 11,
      name: "idDipendentePianificato",
      type: "ENTITY",
      label: "DipendentePianificato",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "dipendente",
      srcDescr: "denominazione",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
    {
      id: 12,
      name: "orarioPianificato",
      type: "DATETIME",
      label: "OrarioPianificato",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: true,
      dtFixedMidnight: false,
      dtStepMinute: 30,
      readonly: false,
      value: null,
    },
    {
      id: 13,
      name: "indirizzoPianificato",
      type: "TEXT",
      label: "IndirizzoPianificato",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: true,
      value: null,
    },
    {
      id: 14,
      name: "cittaPianificata",
      type: "TEXT",
      label: "CittaPianificata",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: true,
      value: null,
    },
    {
      id: 15,
      name: "provinciaPianificata",
      type: "TEXT",
      label: "ProvinciaPianificata",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "null",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: true,
      value: null,
    },
    {
      id: 16,
      name: "idDipendenteStabilito",
      type: "ENTITY",
      label: "DipendenteStabilito",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "dipendente",
      srcDescr: "denominazione",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: true,
      value: null,
    },
    {
      id: 17,
      name: "orarioStabilito",
      type: "DATETIME",
      label: "OrarioStabilito",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: true,
      dtFixedMidnight: false,
      dtStepMinute: 30,
      readonly: true,
      value: null,
    },
    {
      id: 18,
      name: "note",
      type: "TEXTAREA",
      label: "Note",
      mandatory: false,
      title: "",
      defaultValue: null,
      enumValues: null,
      srcData: "",
      srcDescr: "",
      maxTextLength: null,
      numDecDigit: null,
      hidden: true,
      minNumValue: null,
      maxNumValue: null,
      dtButtonsHidden: false,
      dtFixedMidnight: false,
      dtStepMinute: 1,
      readonly: false,
      value: null,
    },
  ];

  getDocument(documentCode: string): Promise<string> {
    throw new Error("Method not implemented.");
  }
}

export default AppointmentService;
