import React, { useState, useEffect } from "react";
import ChartDashboard from "../ChartDashboard";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import AppointmentService from "../../services/appointment.service";
import api from "../../api";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import ProblemService, { DefinedProblemResponse } from "../../services/problem.service";
import { rangeOneMonth } from "../../utils/Common";
import { Message } from "primereact/message";

import "./PerformanceEmployees.css";

function PerformanceEmployees() {
  const [dates, setDates] = useState<any>();
  const [chartOptions, setChartOptions] = useState({});
  const appointmentService = new AppointmentService(api);
  const [dati, setDati] = useState<any>();
  const [message, setMessage] = useState<string>("");

  const problemService: ProblemService = new ProblemService(api);

  const changeData = () => {
    const startDate = dates[0];
    const endDate = dates[1];
    if (!startDate || !endDate) {
      setMessage("Inserire due date valide");
    } else if (startDate && endDate !== null) {
      setMessage("");
      sendData(startDate, endDate);
    }
  };
  const sendData = async (startDate: object, endDate: object) => {
    appointmentService.getAppuntamentoIstogramma(startDate, endDate).then((res) => {
      setDati(res.data);
    });
  };

  const initialiseDate = () => {
    //Automatic planning disabled
    // problemService
    //   .getDefinedProblem()
    //   .then(() => {

    let endDate: Date = new Date();
    const range = rangeOneMonth(endDate);
    setDates(range);
    sendData(range[0], range[1]);
    // });
  };

  useEffect(() => {
    initialiseDate();
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
            stepSize: 1,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartOptions(options);
  }, []);

  return (
    <div className="container">
      <Calendar
        className="calendar_input"
        inputId="inserisci_periodo"
        value={dates}
        onChange={(e) => setDates(e.value)}
        selectionMode="range"
        readOnlyInput
        dateFormat="dd/mm/yy"
      />
      <Button icon="pi pi-search" onClick={changeData} />
      {message !== "" ? (
        <div>
          <Message severity="error" text={message} className="messageErrorRange" />
        </div>
      ) : null}

      {dati ? <ChartDashboard dati={dati} opzioni={chartOptions} /> : null}
    </div>
  );
}

export default PerformanceEmployees;
