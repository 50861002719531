import { AxiosInstance, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";
import { ICustomServiceResponse } from "@lcs/frontend";

export const appenderId_timeEmail: string = "timeEmailAppender";
export const appenderId_eventEmail: string = "eventEmailAppender";
// export const appenderId_timeLog: string = "timeLogAppender";
// export const appenderId_eventLog: string = "eventLogAppender";

export enum aaInputInfoCloseResult{
    FAILURE = "FALLIMENTO",
    INTERIM = "INTERLOCUTORIO",
    SUCCESS = "SUCCESSO"
}

export interface aaInputInfo{ 
    closeResult: aaInputInfoCloseResult,
    when: Date,
    //reason?: aaReason, //?? c'è già in oggetto principale
    //severity?: aaSeverity //?? c'è già in oggetto principale
}

export interface aaOutputInfo {
    from: string,
    to: string[],
    cc: string[],
    bcc: string[],
    subject: string,
    content: string
}

export enum aaSeverity{
    ALARM = "ALARM",
    WARNING = "WARNING",
    INFO = "INFO"
}

export enum aaReason{
    EVENT = "EVENT",
    TIME = "TIME"
}

export interface AppenderActivity{
    id: number,
    appenderId: string,
    workflowName: string,
    //entityPayloadClassRefId: string, // "appuntamento"
    entityCorrelationId: string, //number AppointmentId
    //success: null,
    timestamp: Date,
    severity: aaSeverity,
    reason: aaReason,
    appointmentInputJson: aaInputInfo,
    appointmentOutputJson: aaOutputInfo
}

export interface AppenderActivityServiceResponse extends ICustomServiceResponse {
    data: AppenderActivity[];
}

class AppenderActivitiesService {
    readonly axios : AxiosInstance;
    readonly appointmentId: number;
    APPOINTMENT_BASE_PATH: string;

    constructor(axios: AxiosInstance, appointmentId: number) {
        this.axios = axios; // con base path sull'AppointmentService
        this.appointmentId = appointmentId;
        this.APPOINTMENT_BASE_PATH = (process.env.REACT_APP_BE_URL??"") + 
                                 BE_PREFIX.APPOINTMENT + "/api/data";      
    }    

    getAppenderActivities = () : Promise<AppenderActivityServiceResponse> => {
        return this.axios
            .get(this.APPOINTMENT_BASE_PATH + "/activities/appender/" + this.appointmentId)
            .then((result : AxiosResponse<AppenderActivityServiceResponse>) => result.data);        
    }
}

export default AppenderActivitiesService;