import React from "react";
import PanelAppenderDeclarations, { DETAILS_POSITION } from "../components/PanelAppenderDeclarations";


export interface PageActionManagerProps {
  culture: string;
  monIntegration: boolean;
}

function PageActionManager(props: PageActionManagerProps) {
    
    return(
        <>
            <PanelAppenderDeclarations culture={props.culture} monIntegration={props.monIntegration}
            detailsPosition={DETAILS_POSITION.RIGHT}/>
        </>
    )
}

export default PageActionManager;
