import { CUSTOM_PRJ_TRANSLATION_FILE, IAuthService, Login, TokenService, setComponentsCulture } from "@lcs/frontend";
import React from "react";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import api from "../api";
import { ROLE_ADMIN, ROUTE } from "../utils/Constants";
import SmartHelperAuthService, { SHAppointmentUser } from "../services/smarthelper.auth.service";
import CompanyService, { CompanyIntegrationServiceResponse, IntergrationType } from "../services/company.service";

function PageLogin () {
    const authService : IAuthService = new SmartHelperAuthService(api);
    const navigate = useNavigate();

    let onSuccessfulLogin = async (user: SHAppointmentUser) => {
        const companyService: CompanyService = new CompanyService(api);
        const resp: CompanyIntegrationServiceResponse = await companyService.getIntegrations();
        user.mon = resp.data.find(p => p.nome === IntergrationType.MONITORING)?.integrato;
        user.gc = resp.data.find(p => p.nome === IntergrationType.GOOGLE_CALENDAR)?.integrato;            
        TokenService.setUser(user);
        i18next.changeLanguage(user.language);
        setComponentsCulture(user.language);

        if (user.roles.includes(ROLE_ADMIN))
            navigate(ROUTE.ADMINISTRATION);
        else
            navigate(ROUTE.AGENDA);
     };

    const navigaRegister = () => {
        navigate(ROUTE.REGISTER)
    }
   
    return (
        <>
        <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/images/LCSSfondo.png"})`, width: "100%", height: "99vh"}}>
        {
                <Login onSuccessfulLogin={onSuccessfulLogin} title="" 
                authService={authService} showCreateAccountOption={true} 
                onCreateAccountClick={navigaRegister}
                showDescriptionPanel={true}
                description={i18next.t("SHDescrizione", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                dropdownData={[{ name: 'Appointments', code: 'Appointments' }]}/>
        }
        </div>
        </>
    );
}

export default PageLogin;