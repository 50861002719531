import React, { Fragment, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { COMMON_TRANSLATION_FILE, CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import i18next from "i18next";
import AppointmentService, { PlanAppointmentRequest } from "../services/appointment.service";
import api from "../api";
import EmployeeFilterService from "../services/employee.filter.service";
import { Controller, useForm } from "react-hook-form";

export interface DialogAppointmentManualPlanningProps {
    appointmentId: string;
    onAction: (refresh: boolean) => void;
    visible: boolean;
}


function DialogAppointmentManualPlanning(props: DialogAppointmentManualPlanningProps) {

    const myToast = useRef<any>();
    const [working, setWorking] = useState<boolean>(false);
    const [employees, setEmployees] = useState<object[]>([]);

    const employeeFilterService = new EmployeeFilterService(api);
    const appointmentService = new AppointmentService(api);

    useEffect(() => {
        loadData();
    }, []);

    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm({});

    function t (label: string): string {
        return i18next.t(label, {
          ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
        });
    }

    function loadData() {
        employeeFilterService.getData()
            .then((result: any[]) => {
                let arr: object[] = [];
                result.forEach((v: any) => {
                    let ele = {
                        label: v.denominazione,
                        value: v.id
                    }
                    arr.push(ele);
                })
                setEmployees(arr);
            });
    }

    function planAppointment(data: any) {
        setWorking(true);
        if (!data.employee)
            return;
        if (!data.plannedTime)
            return
        if (!(data.plannedTime instanceof Date))
            return;

        let appointmentId: number = +(props.appointmentId);
        let planEmployeeId: number = data.employee;
        let planTime: Date = data.plannedTime;
        let plannedAddress: string | null = data.plannedAddress;
        let plannedCity: string | null = data.plannedCity;
        let plannedProvince: string | null = data.plannedProvince;

        let request: PlanAppointmentRequest = {
            appointmentId: appointmentId,
            planEmployeeId: planEmployeeId,
            planTime: planTime,
            plannedAddress: plannedAddress,
            plannedCity: plannedCity,
            plannedProvince: plannedProvince
        }
        appointmentService.plan(request)
            .then((response: any) => {
                setWorking(false);
                props.onAction(true);
            })
    }

    function getSelectionComponent(name: any, label: string, rules: any, getInnerComponent: (field: any) => any) {
        let aa = errors[name];
        return (
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row", marginTop: "5px", marginBottom: "5px" }}>
                <div style={{ flex: "0 0 300px", display: "flex", flexDirection: "column" }}>
                    <div style={{ fontSize: "20px", height: "40px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {t(label)}
                    </div>
                    <div style={{ height: "20px" }} />
                </div>
                <div style={{ flexGrow: "1", flexShrink: "1", display: "flex", flexDirection: "column", alignItems: "top" }} className="p-float-label">
                    <Controller
                        name={name}
                        control={control}
                        rules={rules}
                        render={({ field, fieldState }) => { return (getInnerComponent(field)); }} />
                    <div style={{ height: "20px" }}>
                        <small className="p-error">
                            {aa && aa.message}
                        </small>
                    </div>
                </div>
            </div>
        );
    }

    function getPlannedEmployeeSelectionControl() {
        let rules = {
            required: `${t("DipendentePianificato")} ${t("IS_REQUIRED")}`
         };
        return getSelectionComponent("employee", "DipendentePianificato", rules, (field) => (
            <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value ? e.value : "")}
                options={employees}
                placeholder="Select a Value"
                style={{ width: "100%", height: "40px" }}
                showClear />
        ));
    }

    function getPlannedTimeSelectionControl() {
        let rules = { 
            required: `${t("OrarioPianificato")} ${t("IS_REQUIRED")}`
        };
        return getSelectionComponent("plannedTime", "OrarioPianificato", rules, (field) => (
            <Calendar
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value ? e.value : "")}
                showTime={true}
                showButtonBar={false}
                hourFormat="24"
                showIcon
                style={{ width: "100%", height: "40px" }}
                stepHour={1}
                stepMinute={15} />
        ));
    }

    function getPlannedAddressSelectionControl() {
        let rules = { 
            maxLength: { 
                value: 255,
                message: `${t("IndirizzoPianificato")} ${t("MUST_BE_LESS")} 255 ${t("CHARACTERS")}`
            }
        };
        return getSelectionComponent("plannedAddress", "IndirizzoPianificato", rules, (field) => (
            <InputText
                id={field.name}
                {...field}
                style={{ width: "100%", height: "40px" }} />
        ));
    }

    function getPlannedCitySelectionControl() {
        let rules = { 
            maxLength: { 
                value: 255,
                message: `${t("CittaPianificata")} ${t("MUST_BE_LESS")} 255 ${t("CHARACTERS")}`
            }
        };
        return getSelectionComponent("plannedCity", "CittaPianificata", rules, (field) => (
            <InputText
                id={field.name}
                {...field}
                style={{ width: "100%", height: "40px" }} />
        ));
    }

    function getPlannedProvinceSelectionControl() {
        let rules = { 
            maxLength: { 
                value: 255,
                message: `${t("ProvinciaPianificata")} ${t("MUST_BE_LESS")} 255 ${t("CHARACTERS")}`
            }
        };
        return getSelectionComponent("plannedProvince", "ProvinciaPianificata", rules, (field) => (
            <InputText
                id={field.name}
                {...field}
                style={{ width: "100%", height: "40px" }} />
        ));
    }


    function getDialogBody() {
        return (<Fragment>
            <form onSubmit={handleSubmit(planAppointment)}>
                {getPlannedEmployeeSelectionControl()}
                {getPlannedTimeSelectionControl()}
                {getPlannedAddressSelectionControl()}
                {getPlannedCitySelectionControl()}
                {getPlannedProvinceSelectionControl()}
                {getFooter()}
            </form>
        </Fragment>);
    }

    function getFooter() {
        return (
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
                <Button
                    label={t("OK")}
                    type="submit"
                    loading={working}
                    style={{ marginRight: "10px" }}
                />
                <Button
                    label={t("Cancel")}
                    type="button"
                    onClick={() => props.onAction(false)}
                />
            </div>
        );
    }


    return (
        <Fragment>
            <Toast ref={myToast} />
            <Dialog
                style={{ width: "800px", height: "500px" }}
                header={t("AppointmentManualPlanning")}
                visible={props.visible}
                onHide={() => props.onAction(false)}>
                {getDialogBody()}
            </Dialog>
        </Fragment>
    );

}

export default DialogAppointmentManualPlanning