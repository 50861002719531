import { ICustomServiceResponse } from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";
import { LinkedResource } from "./common.types";
import { BE_PREFIX } from "../utils/Constants";


export interface AbsenceOut{ 
    id:number,             
    da: Date,
    a: Date,   
    denominazione: string,     
    descrizione: string,        
    idDipendente: { id: number, value: string}  
}

export interface AbsenceServiceResponse extends ICustomServiceResponse {
    data: AbsenceOut[]
}

class AbsenceService {
    readonly axios : AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    getAll = () : Promise<AbsenceServiceResponse> => {
        return this.axios
            .get((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/assenza/")
            .then((result : AxiosResponse<AbsenceServiceResponse>) => result.data);
    }

    getByEmployeeId = (employeeId: number) : Promise<AbsenceServiceResponse> => {
        return this.axios
            .get((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/assenza/filterByDipendente?idDipendente=" + employeeId)
            .then((result : AxiosResponse<AbsenceServiceResponse>) => result.data);
    }

}

export default AbsenceService;