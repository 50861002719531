import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PanelSingleEmployee from "../components/PanelSingleEmployee";
import PanelEmployeeAbsence from "../components/PanelEmployeeAbsence";
import { Button } from "primereact/button";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import { TabPanel, TabView } from "primereact/tabview";
import PanelEmployeSpec from "../components/PanelEmployeSpec";
import 'primeicons/primeicons.css';

export interface PageSingleEmployeeProps {
    culture: string;
}

function PageSingleEmployee(props: PageSingleEmployeeProps) {
    let params = useParams();
    const navigate = useNavigate();
    let currentEmployeeId: string = params.id ? params.id : "";

    return (
        <>
           <div>
            <Button style={{ marginTop: "10px" }}
                label={i18next.t("BackToEmployees", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                icon="pi pi-angle-left"
                onClick={() => {
                    navigate(-1);
                }}/>
                <div style={{marginTop: "10px", float: "right"}}>
                    <i className="pi pi-user" style={{marginRight: "10px"}}></i>
                    <PanelSingleEmployee employeeId={Number(currentEmployeeId)} />
                </div>
            </div>
            <TabView style={{ marginTop: "10px" }}>
                <TabPanel header={i18next.t("SPECIALIZZAZIONI", { ns: CUSTOM_PRJ_TRANSLATION_FILE })} >
                    <PanelEmployeSpec culture={props.culture} employeeId={Number(currentEmployeeId)} />
                </TabPanel>
                <TabPanel header={i18next.t("ASSENZE", { ns: CUSTOM_PRJ_TRANSLATION_FILE })} >
                    <PanelEmployeeAbsence culture={props.culture} employeeId={Number(currentEmployeeId)} />
                </TabPanel>
            </TabView>
        </>
    );
}

export default PageSingleEmployee;