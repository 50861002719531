import {
  COMMON_TRANSLATION_FILE,
  CUSTOM_PRJ_TRANSLATION_FILE,
  EditableDataServiceResponse,
  EntityField,
  IDataServiceResponse,
  MailEditor,
  SpeechToTextTA,
  Viewer,
} from "@lcs/frontend";
import React, { useEffect, useRef, useState } from "react";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import api from "../api";
import i18next from "i18next";
import { Toast } from "primereact/toast";
import AppenderDeclarationsService, {
  EmailAppenderDeclaration,
  adAType,
  adActivationType,
  adAppenderConditionId,
  adAppenderConditionParam,
  adSeverity,
  adWhatStatus,
} from "../services/appender.declarations.service";

import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Form, useForm } from "react-hook-form";
import { MultiSelect } from "primereact/multiselect";
import { Avatar } from "primereact/avatar";
import { APPOINTMENT_WF_STATE } from "../services/appointment.service";
import { Dropdown } from "primereact/dropdown";
import CustomerFilterService from "../services/customer.filter.service";

import { ConfirmPopup } from "primereact/confirmpopup"; // To use <ConfirmPopup> tag
import { confirmPopup } from "primereact/confirmpopup"; // To use confirmPopup method
import { TieredMenu } from "primereact/tieredmenu";

export enum DETAILS_POSITION {
  RIGHT,
  DOWN,
}

interface PanelAppenderDeclarationsProps {
  culture: string;
  monIntegration: boolean;
  detailsPosition: DETAILS_POSITION;
}

function PanelAppenderDeclarations(props: PanelAppenderDeclarationsProps) {
  const substitutions: any = [
    {
      value: "#Nome_Utente#",
      label: i18next.t("MailSubst_Nome_Utente", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
    },
    {
      value: "#Denominazione_App#",
      label: i18next.t("MailSubst_Denominazione_App", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
    },
    {
      value: "#Stato_Appuntamento#",
      label: i18next.t("MailSubst_Stato_Appuntamento", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
    },
    {
      value: "#Note_Chiusura#",
      label: i18next.t("MailSubst_Note_Chiusura", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
    },
  ];
  const associationsOptions: any = [
    {
      code: "CHIUSO_SUCCESSO",
      name: i18next.t("CHIUSO_SUCCESSO", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
    },
    {
      code: "CHIUSO_INTERLOCUTORIO",
      name: i18next.t("CHIUSO_INTERLOCUTORIO", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
    },
    {
      code: "CHIUSO_FALLIMENTO",
      name: i18next.t("CHIUSO_FALLIMENTO", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
    },
  ];

  const activationTypeOptions: any = [
    {
      code: adActivationType.EVENT,
      name: i18next.t("Appender_ActivationType_EVENT", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
    },
    {
      code: adActivationType.TIME,
      name: i18next.t("Appender_ActivationType_TIME", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
    },
  ];

  const emptyAppender: EmailAppenderDeclaration = {
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    content: "",
    appenderId: "",
    activationType: adActivationType.EVENT,
    type: adAType.EMAIL,
    status: adWhatStatus.CLOSED,
    severities: [
      { name: adSeverity.ALARM, value: false },
      { name: adSeverity.ERROR, value: false },
      { name: adSeverity.INFO, value: false },
      { name: adSeverity.WARNING, value: false },
    ],
    conditions: [],
  };

  const defaultCustomerName: string = i18next.t("Appender_NoSelectedCustomerLbl", {
    ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
  });

  const appenderAddMenu = useRef<any>(null);
  const [currentMailAppender, setCurrentMailAppender] = useState<EmailAppenderDeclaration>(emptyAppender); //TODO per ora solo mail
  const [lastSelectedAppenderId, setLastSelectedAppenderId] = useState<string>("");
  const [currentMailContent, setCurrentMailContent] = useState<string>("");
  const [currentActivationType, setCurrentActivationType] = useState<any>(activationTypeOptions[0]);
  const [customerList, setCustomerList] = useState<{ name: string; code?: string }[]>([{ name: defaultCustomerName }]);
  const [currentSelectedCustomer, setCurrentSelectedCustomer] = useState<any>("");
  const [currentQuestion, setCurrentQuestion] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [underConstruction, setUnderConstruction] = useState<boolean>(false);
  const [associatedWhatStates, setAssociationWhatStates] = useState<any>(null);
  const dataService = new AppenderDeclarationsService(api);
  const customerFilterService: CustomerFilterService = new CustomerFilterService(api);
  const myToast = useRef<any>();
  const myToastForEmailMissingF = useRef<any>();
  const {
    register,
    control,
    formState: { errors },
  } = useForm();
  const toast = useRef(null);

  const [data, setData] = useState<any[]>([]);
  const [fields, setFields] = useState<EntityField[]>([]);

  const getData = () => {
    dataService.getData().then((result: IDataServiceResponse) => {
      if (result.data && result.data.length >= 0) {
        setData(result.data);
      }
    });
  };

  useEffect(() => {
    dataService.getConfiguration().then((response: EntityField[]) => {
      if (response && response.length > 0) {
        setFields(response);
        getData();
      }
    });
    customerFilterService.getData().then((customers: any[]) => {
      if (customers.length > 0) {
        var currCList: { name: string; code?: string }[] = [];
        currCList.push({ name: defaultCustomerName });
        for (let index = 0; index < customers.length; index++) {
          const customer = customers[index];
          currCList.push({
            name: customer.denominazione,
            code: customer.denominazione,
          });
        }
        setCurrentSelectedCustomer({ name: defaultCustomerName });        
        setCustomerList(currCList);
      } else {
        setCurrentSelectedCustomer({ name: defaultCustomerName });
        setCustomerList([{ name: defaultCustomerName }]);
      }
    });
  }, []);

  useEffect(() => {}, [data]);

  // const editMailAppender = () => {
  //     setIsEditing(true);
  // }

  const removeAppender = (mailAppender: EmailAppenderDeclaration) => {
    let objAppender = {
      appender: mailAppender.appenderId,
      whatName: "CLOSED",
    };

    dataService.removeAppender(objAppender).then((resp) => {
      if (resp.success) {
        getData();
        myToast.current.show({
          severity: "success",
          summary: i18next.t("OperationCompleted", {
            ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
          }),
          detail: "OK",
        });
      } else {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error"),
          detail: i18next.t(resp.reason, {
            ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
          }),
        });
      }
    });
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const validateEmail = (emailList: string): boolean => {
    var allEmailsValidate: boolean = true;
    if (emailList.includes(",")) {
      var emailArray: string[] = [];
      emailArray = emailList.split(", ");
      for (let index = 0; index < emailArray.length; index++) {
        const email = emailArray[index];
        if (!emailRegex.test(email)) {
          allEmailsValidate = false;
          break;
        }
      }
    } else {
      allEmailsValidate = emailRegex.test(emailList);
    }

    return allEmailsValidate;
  };

  const clearToastForEmailMissingF = () => {
    myToastForEmailMissingF.current.clear();
  };

  const isHTMLEmpty = (content: string): boolean => {
    if (content.trim().length === 0) return true;
    let cleanContent = content.replace(/<p>/g, "");
    cleanContent = cleanContent.replace(new RegExp("</p>", "g"), "");
    cleanContent = cleanContent.replace(/<br>/g, "");
    return cleanContent.trim().length === 0;
  };
  const saveMailAppender = () => {
    const isToEmpty: boolean = currentMailAppender.to.trim().length === 0;
    const isContentEMpty: boolean = isHTMLEmpty(currentMailContent);
    var isToValid: boolean = false;
    if (!isToEmpty) {
      isToValid = validateEmail(currentMailAppender.to);
    }
    const isSubjectEmpty: boolean = currentMailAppender.subject.trim().length === 0;
    if (!isToEmpty && isToValid && !isSubjectEmpty && !isContentEMpty) {
      let tempAppender = { ...currentMailAppender };
      tempAppender.content = currentMailContent;
      tempAppender.activationType = currentActivationType.code;
      if (currentActivationType.code === adActivationType.TIME) {
        /** when on time set fixed thresholds alarm and warning */
        tempAppender.severities = [
          { name: adSeverity.ALARM, value: true },
          { name: adSeverity.ERROR, value: false },
          { name: adSeverity.INFO, value: false },
          { name: adSeverity.WARNING, value: true },
        ];
        /** when on time set the check resend condition */
        tempAppender.conditions.push({
          id: adAppenderConditionId.CHECK_RESEND,
          params: [
            {
              name: adAppenderConditionParam.TIMEOUT,
              value: 60, //minutes
            },
            {
              name: adAppenderConditionParam.HOW_MANY,
              value: 3, //max number to be sent mail
            }
          ],
        });
      }
      if (currentQuestion) {
        tempAppender.conditions.push({
          id: adAppenderConditionId.CHECK_QUESTION,
          params: [
            {
              name: adAppenderConditionParam.QUESTION,
              value: currentQuestion.replace(/'/g, "`"),
            },
          ],
        });
      }
      if (currentSelectedCustomer.code) {
        tempAppender.conditions.push({
          id: adAppenderConditionId.CHECK_CUSTOMER,
          params: [
            {
              name: adAppenderConditionParam.CUSTOMER_NAME,
              value: currentSelectedCustomer.code,
            },
          ],
        });
      }
      if (tempAppender.conditions.length === 0) {
        tempAppender.conditions.push({
          id: adAppenderConditionId.ALWAYS_TRUE,
          params: [],
        });
      }
      dataService.addObj(tempAppender).then((resp: EditableDataServiceResponse) => {
        if (resp.success) {
          setIsEditing(false);
          getData();
          myToast.current.show({
            severity: "success",
            summary: i18next.t("OperationCompleted", {
              ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
            }),
            detail: "OK",
          });
        } else {
          myToast.current.show({
            severity: "error",
            summary: i18next.t("Error"),
            detail: i18next.t(resp.reason, {
              ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
            }),
          });
        }
      });
    } else {
      myToastForEmailMissingF.current.show({
        severity: "info",
        summary: i18next.t("Warning"),
        detail: i18next.t("Appender_MailMissingFields", {
          ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
        }),
        sticky: true,
        content: (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div className=" flex flex-column align-items-center font-bold text-xl my-3">
              {i18next.t("Warning", {
                ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
              })}
            </div>
            <div
              style={{
                display: "flex",
                rowGap: "0.5rem",
                flexDirection: "column",
              }}
            >
              {isToEmpty ? (
                <div>
                  {i18next.t("Appender_EmailToRecipients", {
                    ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                  }) + " "}
                  {i18next.t("IS_REQUIRED")}
                </div>
              ) : (
                ""
              )}
              {!isToValid ? (
                <div>
                  {i18next.t("Appender_EmailToRecipients", {
                    ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                  }) + " "}
                  {i18next.t("non ha un formato corretto. Es. name@server.it, name2@server.it")}
                </div>
              ) : (
                ""
              )}
              {isSubjectEmpty ? (
                <div>
                  {i18next.t("Appender_EmailSubject", {
                    ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                  }) + " "}
                  {i18next.t("IS_REQUIRED")}
                </div>
              ) : (
                ""
              )}
              {isContentEMpty ? (
                <div>
                  {i18next.t("Appender_Content", {
                    ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                  }) + " "}
                  {i18next.t("IS_REQUIRED")}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.5rem",
              }}
            >
              <Button onClick={(e) => clearToastForEmailMissingF()} type="button" label="Ok" />
            </div>
          </div>
        ),
      });
    }
  };

  const resetAllControls = () => {
    setCurrentMailAppender(emptyAppender);
    setAssociationWhatStates([]);
    setIsEditing(true);
    setCurrentMailContent("");
    setCurrentActivationType(activationTypeOptions[0]);
    setCurrentSelectedCustomer(customerList[0]);
    setCurrentQuestion(" ");
  };

  const appenderTypes = [
    {
      label: i18next.t("EMAIL", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
      icon: "pi pi-envelope",
      command: () => {
        resetAllControls();
        setUnderConstruction(false);
      },
    },
    {
      label: i18next.t("LOG", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
      icon: "pi pi-pencil",
      command: () => {
        resetAllControls();
        setUnderConstruction(true);
      },
    },
    {
      label: i18next.t("CUSTOMACTION", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
      icon: "pi pi-external-link",
      command: () => {
        resetAllControls();
        setUnderConstruction(true);
      },
    },
  ];

  const stopEditing = (event: any) => {
    var appenderData: EmailAppenderDeclaration;
    if (event.data) appenderData = { ...emptyAppender, ...event.data };
    else {
      const lastAppenderData = data.find((app: EmailAppenderDeclaration) => app.appenderId === lastSelectedAppenderId);
      appenderData = lastAppenderData ?? emptyAppender;
    }
    setCurrentMailAppender(appenderData);
    setCurrentMailContent(appenderData.content);
    const cond = appenderData.conditions.find((c: any) => c.id === adAppenderConditionId.CHECK_QUESTION);
    if (cond){
      const param = cond.params.find((p: any) => p.name === adAppenderConditionParam.QUESTION);
      if (param && typeof param.value === 'string')
        setCurrentQuestion(param.value.replace(/`/g, "'"),);
      else 
        setCurrentQuestion("");
    }
    else
      setCurrentQuestion("");
    setCurrentActivationType(activationTypeOptions.find((o: any) => o.code === appenderData.activationType));
    updateAssociationWhatStatesFromSeverities(appenderData.severities);
    setIsEditing(false);
    setUnderConstruction(false);
  };

  const reject = () => {};

  const confirmStopEditing = (event: any) => {
    confirmDialog({
      message: i18next.t("Appender_StopEditingMessage", {
        ns: CUSTOM_PRJ_TRANSLATION_FILE,
      }),
      header: i18next.t("Appender_StopEditingMessageHeader", {
        ns: CUSTOM_PRJ_TRANSLATION_FILE,
      }),
      icon: "pi pi-exclamation-triangle",
      accept: () => stopEditing(event),
      reject,
    });
  };

  const whatStateTemplate = (option: any) => {
    let iconColor = "";
    switch (option.code) {
      case "CHIUSO_SUCCESSO":
        iconColor = "green";
        break;
      case "CHIUSO_INTERLOCUTORIO":
        iconColor = "yellow";
        break;
      case "CHIUSO_FALLIMENTO":
        iconColor = "red";
        break;
      default:
        iconColor = "grey";
    }
    return (
      <div className="flex align-items-center">
        {/* <i className="pi pi-times"></i> */}
        <Avatar
          /* icon="pi pi-circle" */ style={{
            backgroundColor: iconColor,
            color: "black",
            marginRight: "0.5rem",
          }}
          shape="circle"
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const onWhatStateSelectionChange = (e: any) => {
    setAssociationWhatStates(e.value);
    var tempSeverities: { name: adSeverity; value: boolean }[] = currentMailAppender.severities;
    const foundSuccessInSelected = e.value.findIndex(
      (sel: { code: string }) => sel.code === APPOINTMENT_WF_STATE.CHIUSO_SUCCESSO
    );
    const indexSuccess = tempSeverities.findIndex((sel: { name: string }) => sel.name === adSeverity.INFO);
    if (indexSuccess >= 0) tempSeverities[indexSuccess].value = foundSuccessInSelected >= 0;
    const foundInterlInSelected = e.value.findIndex(
      (sel: { code: string }) => sel.code === APPOINTMENT_WF_STATE.CHIUSO_INTERLOCUTORIO
    );
    const indexInterl = tempSeverities.findIndex((sel: { name: string }) => sel.name === adSeverity.WARNING);
    if (indexInterl >= 0) tempSeverities[indexInterl].value = foundInterlInSelected >= 0;
    const foundFailureInSelected = e.value.findIndex(
      (sel: { code: string }) => sel.code === APPOINTMENT_WF_STATE.CHIUSO_FALLIMENTO
    );
    const indexFailure = tempSeverities.findIndex((sel: { name: string }) => sel.name === adSeverity.ALARM);
    if (indexFailure >= 0) tempSeverities[indexFailure].value = foundFailureInSelected >= 0;
    setCurrentMailAppender({
      ...currentMailAppender,
      severities: tempSeverities,
    });
  };

  const convertSeverityToAppointmentState = (severity: adSeverity): APPOINTMENT_WF_STATE => {
    var appState: APPOINTMENT_WF_STATE = APPOINTMENT_WF_STATE.CHIUSO_SUCCESSO;
    switch (severity) {
      case adSeverity.INFO:
        appState = APPOINTMENT_WF_STATE.CHIUSO_SUCCESSO;
        break;
      case adSeverity.ERROR:
      case adSeverity.ALARM:
        appState = APPOINTMENT_WF_STATE.CHIUSO_FALLIMENTO;
        break;
      case adSeverity.WARNING:
        appState = APPOINTMENT_WF_STATE.CHIUSO_INTERLOCUTORIO;
        break;
    }
    return appState;
  };

  const updateAssociationWhatStatesFromSeverities = (severities: { name: adSeverity; value: boolean }[]) => {
    const trueSeverities: adSeverity[] = [];
    if (severities) {
      severities.forEach((s) => {
        if (s.value) trueSeverities.push(s.name);
      });
      var associations: any[] = [];
      trueSeverities.forEach((ts) => {
        const appstate = convertSeverityToAppointmentState(ts);
        associations.push({
          code: appstate,
          name: i18next.t(appstate, {
            ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
          }),
        });
      });
      setAssociationWhatStates(associations);
    } else {
      setAssociationWhatStates([]);
    }
  };

  const confirmDelete = (event: any, data: any) => {
    setCurrentMailAppender(data);
    confirmPopup({
      target: event.currentTarget,
      message: i18next.t("DELETE_RECORD_MESSAGE", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
      acceptLabel: i18next.t("YES", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
      rejectLabel: i18next.t("NO", {
        ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
      }),
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        removeAppender(data);
      },
    });
  };

  function getDeleteButton() {
    return {
      name: "DeleteDelivery",
      label: "Cancella",
      icon: "pi pi-trash",
      className: "cpc-delete-button",
      onClick: (data: any, event: any) => {
        confirmDelete(event, data);
        setCurrentMailAppender(emptyAppender);
      },
    };
  }

  return (
    <div
      className={
        props.detailsPosition === DETAILS_POSITION.RIGHT
          ? "panel-appender-decl-container-dr"
          : "panel-appender-decl-container-dd"
      }
    >
      <Toast ref={myToast} />
      <Toast ref={myToastForEmailMissingF} position="bottom-right" />
      <div className="panel-appender-decl-cont-details">
        <TieredMenu ref={appenderAddMenu} popup model={appenderTypes} breakpoint="767px" />
        <Button
          icon="pi pi-plus"
          style={{ margin: "0.5rem" }}
          tooltip={i18next.t("Add", {
            ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
          })}
          tooltipOptions={{ position: "bottom" }}
          onClick={(e) => appenderAddMenu?.current?.toggle(e)}
        />
        <ConfirmDialog />
        <Viewer
          culture={props.culture}
          entityFields={fields}
          data={data ?? []}
          totals={{}}
          singleSelection={true}
          onRowSelect={(event: any) => {
            setLastSelectedAppenderId(event.data?.appenderId);
            if (isEditing) {
              confirmStopEditing(event);
            } else {
              const newAppenderData = event.data ?? event;
              var appenderData = { ...emptyAppender, ...newAppenderData };
              setCurrentMailAppender(appenderData);
              setCurrentMailContent(appenderData.content);
              setCurrentActivationType(activationTypeOptions.find((o: any) => o.code === appenderData.activationType));
              const customerCondition: any = appenderData.conditions?.find(
                (c: any) => c.id === adAppenderConditionId.CHECK_CUSTOMER
              );
              if (customerCondition) {
                const customerName: string = customerCondition.params?.filter(
                  (p: any) => p.name === adAppenderConditionParam.CUSTOMER_NAME
                )[0]?.value;
                setCurrentSelectedCustomer({
                  name: customerName,
                  code: customerName,
                });
              } else {
                setCurrentSelectedCustomer(customerList[0]);
              }
              const questionCondition = appenderData.conditions?.find(
                (c: any) => c.id === adAppenderConditionId.CHECK_QUESTION
              );
              if (questionCondition && questionCondition.params)
                setCurrentQuestion(questionCondition.params[0].value);
              updateAssociationWhatStatesFromSeverities(appenderData.severities);
            }
          }}
          firstSelected={true}
          columnButtons={isEditing ? [] : [getDeleteButton()]}
        ></Viewer>
      </div>
      <Form
        style={{ width: "-webkit-fill-available" }}
        onSubmit={(e: any) => {
          saveMailAppender();
        }}
        control={control}
      >
        <div className="containerMailComp">
          <div style={{ height: "3rem", alignContent: "center", margin: "0.5rem" }}>
            {isEditing ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              >
                <label>
                  {i18next.t("Appender_Id", {
                    ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                  })}
                  :
                </label>
                <InputText
                  {...register("appenderId", { required: true })}
                  value={currentMailAppender?.appenderId}
                  style={{ width: "100%" }}
                  placeholder={i18next.t("Appender_Id", {
                    ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                  })}
                  maxLength={200} //TODO max length to be verified
                  onChange={(e) => {
                    setCurrentMailAppender({
                      ...currentMailAppender,
                      appenderId: e.target.value,
                    });
                  }}
                />
                {errors.appenderId?.type === "required" && (
                  <p
                    role="alert"
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    {i18next.t("IS_REQUIRED")}
                  </p>
                )}
              </div>
            ) : (
              <label>
                {i18next.t("Appender_Id", {
                  ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                })}
                : {currentMailAppender?.appenderId}
              </label>
            )}
          </div>
          <hr></hr>
          {underConstruction ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "0.5rem",
                margin: "0.5rem",
                height: "10rem",
                width: "50rem",
                background: "lightgrey",
              }}
            >
              Under construction
            </div>
          ) : (
            <MailEditor
              readOnly={!isEditing}
              disabled={!isEditing}
              valueText={currentMailContent}
              onChangeText={(e: any) => {
                setCurrentMailContent(e);
              }}
              valueTo={currentMailAppender.to}
              onChangeTo={(e: any) => {
                setCurrentMailAppender({
                  ...currentMailAppender,
                  to: e.target.value,
                });
              }}
              valueCc={currentMailAppender.cc ?? ""}
              onChangeCc={(e: any) => {
                setCurrentMailAppender({
                  ...currentMailAppender,
                  cc: e.target.value,
                });
              }}
              valueBcc={currentMailAppender.bcc ?? ""}
              onChangeBcc={(e: any) => {
                setCurrentMailAppender({
                  ...currentMailAppender,
                  bcc: e.target.value,
                });
              }}
              valueSubject={currentMailAppender ? currentMailAppender.subject : ""}
              onChangeSubject={(e: any) => {
                setCurrentMailAppender({
                  ...currentMailAppender,
                  subject: e.target.value,
                });
              }}
              tag={substitutions}
            />
          )}
          <div className="panel-appender-decl-filter-container">
            <div className="panel-appender-decl-filter-field">
              <label style={{ minWidth: "25rem" }}>
                {i18next.t("Appender_SelectedActivTypeLbl", {
                  ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                })}
              </label>
              {errors.associatedWhatStates?.type === "required" && <p></p>}
              <Dropdown
                options={activationTypeOptions}
                value={currentActivationType}
                {...register("activationType", {
                  required: currentActivationType ? false : true,
                })}
                optionLabel="name"
                disabled={!isEditing}
                style={{ minWidth: "20rem" }}
                onChange={(e: any) => {
                  setCurrentActivationType(e.value);
                }}
              ></Dropdown>
              {errors.activationType?.type === "required" && (
                <p
                  role="alert"
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  {i18next.t("IS_REQUIRED")}
                </p>
              )}
            </div>
            <div className="panel-appender-decl-filter-field">
              {currentActivationType.code === adActivationType.EVENT ? (
                <>
                  <label style={{ minWidth: "25rem" }}>
                    {i18next.t("Appender_SelectedWhatLbl", {
                      ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                    })}
                  </label>
                  <MultiSelect
                    {...register("associatedWhatStates", {
                      required: associatedWhatStates?.length === 0,
                    })}
                    options={associationsOptions}
                    optionLabel="name"
                    value={associatedWhatStates}
                    style={{ minWidth: "20rem" }}
                    onChange={(e: any) => {
                      onWhatStateSelectionChange(e);
                    }}
                    itemTemplate={whatStateTemplate}
                    disabled={!isEditing}
                  ></MultiSelect>
                  {errors.associatedWhatStates?.type === "required" && (
                    <p
                      role="alert"
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      {i18next.t("IS_REQUIRED")}
                    </p>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
            <div className="panel-appender-decl-filter-field">
              {currentActivationType.code === adActivationType.EVENT ? (
                <>
                  <label style={{ minWidth: "25rem" }}>
                    {i18next.t("Appender_QuestionLbl", {
                      ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                    })}
                  </label>
                  <IconField iconPosition="right">
                  <InputIcon className="pi pi-times" 
                    onClick={() => setCurrentQuestion("")}> </InputIcon>
                  <SpeechToTextTA
                      id="AppenderQuestion"
                      value={currentQuestion}
                      onChange={(text: any) => {
                        setCurrentQuestion(text);
                      } }
                      style={{
                        minWidth: "20rem",
                        resize: "none",
                        overflowY: "scroll",
                        height: "100px",
                      }}
                      disabled={!isEditing} 
                      speechCulture={props.culture} 
                  />
                </IconField>                 
                </>
              ) : (
                ""
              )}
            </div>
            <div className="panel-appender-decl-filter-field">
              <label style={{ minWidth: "25rem" }}>
                {i18next.t("Appender_SelectedCustomerLbl", {
                  ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
                })}
              </label>
              <Dropdown
                options={customerList}
                value={currentSelectedCustomer}
                optionLabel="name"
                disabled={!isEditing}
                style={{ minWidth: "20rem" }}
                onChange={(e: any) => {
                  setCurrentSelectedCustomer(e.value);
                }}
              ></Dropdown>
            </div>
          </div>
        </div>
        {isEditing ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "0.5rem",
              marginTop: "0.5rem",
            }}
          >
            <Button type="button" className="panel-cancel-custom-button" onClick={() => stopEditing({})}>
              {i18next.t("Annulla", {
                ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
              })}
            </Button>
            <Button type="submit">
              {i18next.t("Create", {
                ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
              })}
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "0.5rem",
              marginTop: "0.5rem",
            }}
          >
            <Toast ref={toast} />
            <ConfirmPopup />
          </div>
        )}
      </Form>
    </div>
  );
}

export default PanelAppenderDeclarations;
