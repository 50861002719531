import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PanelCustomerUnavailability from "../components/PanelCustomerUnavailability";
import PanelSingleCustomer from "../components/PanelSingleCustomer";
import { Button } from "primereact/button";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import { TabPanel, TabView } from "primereact/tabview";

export interface PageSingleCustomerProps {
    culture: string;
}
function PageSingleCustomer(props: PageSingleCustomerProps) {
    let params = useParams();
    const navigate = useNavigate();
    let currentCustomerId: string = params.id ? params.id : "";

    return (
        <>
            <div>

            <Button style={{ marginTop: "10px" }}
                label={i18next.t("BackToCustomers", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                icon="pi pi-angle-left"
                onClick={() => {
                    navigate(-1);
                }}/>
                <div style={{marginTop: "10px", float: "right"}}>
                    <i className="pi pi-users" style={{marginRight: "10px"}}></i>
                    <PanelSingleCustomer customerId={Number(currentCustomerId)} />                    
                </div>
            </div>
            <TabView style={{ marginTop: "10px" }}>
                <TabPanel header={i18next.t("INDISPONIBILITA", { ns: CUSTOM_PRJ_TRANSLATION_FILE })} >
                    <PanelCustomerUnavailability culture={props.culture} selectedCustomerId={Number(currentCustomerId)} />
                </TabPanel>
            </TabView>
        </>);
}

export default PageSingleCustomer;