import { AxiosInstance, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";

export interface ICustomServiceResponse{
    success: boolean;
    reason: string;
}

export interface ICustomService{
    getEntityData (entityPath: string) : Promise<any>;
    addEntity (entityPath: string, newObj: any) : Promise<ICustomServiceResponse>;
    updateEntity (entityPath: string, obj: any) : Promise<ICustomServiceResponse>;
    deleteEntity (entityPath: string, obj: any) : Promise<ICustomServiceResponse>;
}

class AppointmentCustomService implements ICustomService {
    readonly axios : AxiosInstance;
    AppointmentBasePath : string;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
        this.AppointmentBasePath = (process.env.REACT_APP_BE_URL??"") + 
                                       BE_PREFIX.APPOINTMENT +
                                       "/api/data/";
    }

    getEntityData = (entityPath: string) : Promise<any> => {
        return this.axios
            .get(this.AppointmentBasePath + entityPath)
            .then((result : AxiosResponse<any>) => result.data.data);
    }

    addEntity = (entityPath: string, newObj: any) : Promise<ICustomServiceResponse> => {
        return this.axios
            .post(this.AppointmentBasePath + entityPath, newObj)
            .then((result : AxiosResponse<ICustomServiceResponse>) => result.data);
    }

    updateEntity = (entityPath: string, obj: any) : Promise<ICustomServiceResponse> => {
        const params = new URLSearchParams([['itemId', obj.id]]);
        return this.axios
            .put(this.AppointmentBasePath + entityPath, obj, {params})
            .then((result : AxiosResponse<ICustomServiceResponse>) => result.data);
    }

    deleteEntity = (entityPath: string, obj: any) : Promise<ICustomServiceResponse> => {
        const params = new URLSearchParams([['itemId', obj.id]]);
        return this.axios
            .delete(this.AppointmentBasePath + entityPath, {params})
            .then((result : AxiosResponse<ICustomServiceResponse>) => result.data);
    }
}

export default AppointmentCustomService;