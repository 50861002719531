import { ConfigurationServiceSourceType, IConfigurationService,
     ICustomService } from "@lcs/frontend";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";
import AppointmentCustomService from "./appointment.custom.service";
import AppointmentConfigurationService from "./appointment.configuration.service";

class CustomerService {
    
    readonly axios: AxiosInstance;
    readonly customerId: number;
    readonly configurationService : IConfigurationService;
    readonly customService: ICustomService;

    constructor(axios: AxiosInstance, customerId: number) {
        this.axios = axios;
        this.customerId = customerId;
        this.configurationService = new AppointmentConfigurationService({
            entityDefinitionSource: ConfigurationServiceSourceType.axios,
            axios: this.axios,
            entityDefinitionJsonFilePath: null,
            entityRoleJsonFilePath: null
          });
        this.customService = new AppointmentCustomService(this.axios);
    }

    getData(): Promise<any> {
        let config: AxiosRequestConfig = {
        };
        return this.axios
        .get((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/cliente/" + this.customerId, config)
        .then((result: AxiosResponse<any>) => {
            let response = [
                    result.data.data
                ];
            return response;
        });
    }
}

export default CustomerService;