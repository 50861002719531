import { TokenService, User } from "@lcs/frontend";
import React from "react";
import PanelEmployees from "../components/PanelEmployees";

function PageEmployees() {

  const getUserLocal = (): User | null => {
    var currentUser: User | null = {
      id: 0,
      name: "",
      surname: "",
      language: "",
      roles: []
    }
    try {
      currentUser = TokenService.getUser()
    } catch (error) {

    }
    return currentUser;
  }
  let currentUser: User | null = getUserLocal();

  return (
    <>
      <PanelEmployees culture={currentUser ? currentUser.language : "en-US"} />
    </>
      
  );
}

export default PageEmployees;