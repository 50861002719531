import {
  APPOINTMENT_STATE,
  APPOINTMENT_WF_STATE,
} from "../services/appointment.service";
import { colorEvent } from "./Constants";

export const calculateAppointmentEndDate = (
  start: Date,
  durataStimataInMezzore: string | undefined
): Date => {
  let eventEnd: Date = new Date(start);
  const hours: string | undefined = durataStimataInMezzore?.substring(0, 2);
  const minutes: string | undefined = durataStimataInMezzore?.substring(3, 5);

  let nMinutes: number = 0;
  if (hours && minutes) {
    const nHours: number = parseInt(hours);
    nMinutes = parseInt(minutes) + nHours * 60;
  }
  eventEnd.setMinutes(start.getMinutes() + nMinutes);
  return eventEnd;
};

export const getDateAndHour = (stringDate: string, culture: string) => {
  const date = new Date(stringDate);
  if (date.getTime() === 0) return "";
  const dateCulture = date.toLocaleDateString(culture, {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
  let hour: any = date.getHours();
  let minute: any = date.getMinutes();
  if (hour < 10) hour = `0${hour}`;
  if (minute < 10) minute = `0${minute}`;

  return `${dateCulture} - ${hour}:${minute}`;
};

export const convertWfStateInState = (
  wfState: APPOINTMENT_WF_STATE | undefined
): APPOINTMENT_STATE | undefined => {
  switch (wfState) {
    case APPOINTMENT_WF_STATE.CHIUSO_SUCCESSO:
      return APPOINTMENT_STATE.CHIUSO_SUCCESSO;
    case APPOINTMENT_WF_STATE.PIANIFICATO:
    case APPOINTMENT_WF_STATE.CONFERMATO:
      return APPOINTMENT_STATE.PIANIFICATO;
    case APPOINTMENT_WF_STATE.CHIUSO_INTERLOCUTORIO:
      return APPOINTMENT_STATE.CHIUSO_INTERLOCUTORIO;
    case APPOINTMENT_WF_STATE.CHIUSO_FALLIMENTO:
      return APPOINTMENT_STATE.CHIUSO_FALLIMENTO;
    default:
      return undefined;
  }
};

export const getColorForEventState = (
  state: APPOINTMENT_STATE | undefined,
  isConfirmed: boolean
): string => {
  if (state) {
    let eventColor: string = "gray";
    switch (state) {
      case APPOINTMENT_STATE.PIANIFICATO:
        eventColor = colorEvent.pianificato;
        if (isConfirmed) eventColor = colorEvent.confermato;
        break;
      case APPOINTMENT_STATE.CHIUSO_INTERLOCUTORIO:
        eventColor = colorEvent.chiusoInterlocutorio;
        break;
      case APPOINTMENT_STATE.CHIUSO_SUCCESSO:
        eventColor = colorEvent.chiusoSuccesso;
        break;
      case APPOINTMENT_STATE.CHIUSO_FALLIMENTO:
        eventColor = colorEvent.chiusoFallimento;
        break;
      default:
        eventColor = "gray";
    }
    return eventColor;
  } else return "DarkGray";
};
export const rangeOneMonth = (endDate: Date) => {
  endDate.setHours(23, 59, 0, 0);

  //data di inizio 1 mese prima da oggi
  let startDate: Date = new Date(endDate);
  startDate.setDate(endDate.getDate() - 31);
  startDate.setHours(0, 1, 0, 0);

  return [startDate, endDate];
};
