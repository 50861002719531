import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import i18next from "i18next";
import { defaultLanguage } from "./i18n";
import {
  MenuType,
  ItemMenu,
  Header,
  TokenService,
  IAuthService,
  CUSTOM_PRJ_TRANSLATION_FILE,
  setComponentsCulture,
} from "@lcs/frontend";
import { ROUTE } from "./utils/Constants";
import "./App.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import PageGeneralData from "./pages/PageGeneralData";
import PageLogin from "./pages/PageLogin";
import PageHome from "./pages/PageHome";
import SmartHelperAuthService, { SHAppointmentUser } from "./services/smarthelper.auth.service";
import api from "./api";
import { shAppendInterceptors } from "./services/api";
import PageWorkPlan from "./pages/PageWorkPlan";
import PageCustomers from "./pages/PageCustomers";
import PageSingleCustomer from "./pages/PageSingleCustomer";
import PageEmployees from "./pages/PageEmployees";
import PageSingleEmployee from "./pages/PageSingleEmployee";
import PageAppointments from "./pages/PageAppointments";
import PageReports from "./pages/PageReports";
import PageRegister from "./pages/PageRegister";

import { locale, addLocale } from "primereact/api";
import locales from "./it.json";
import SessionExpired from "./pages/SessionExpired";
import PageActionManager from "./pages/PageActionManager";

function App() {
  const authService: IAuthService = new SmartHelperAuthService(api);
  const navigate = useNavigate();
  const location = useLocation();
  let menuList: { [id: string]: number } = {};

  shAppendInterceptors(api);

  const getUserLocal = (): SHAppointmentUser | null => {
    var currentUser: SHAppointmentUser | null = {
      id: 0,
      name: "",
      surname: "",
      language: "",
      roles: [],
      mon: false,
      gc: false,
    };
    try {
      currentUser = TokenService.getUser();
    } catch (error) {
      localStorage.setItem("user", JSON.stringify(currentUser));
    }
    return currentUser;
  };

  let currentUser: SHAppointmentUser | null = getUserLocal();

  if (currentUser) {
    i18next.changeLanguage((currentUser as any).language);
    i18next.changeLanguage((currentUser as any).language);
    setComponentsCulture((currentUser as any).language);
  }

  useEffect(() => {
    addLocale("it", locales["it"]);

    let loc: string | undefined = getUserLocal()?.language;

    if (loc === "it-IT") {
      locale("it");
    } else {
      locale("en");
    }

    const user = getUserLocal();
    !user ?? navigate(ROUTE.LOGIN);
  }, [navigate]);

  let onSuccessfulLogout = () => {
    try {
      TokenService.removeUser();
    } catch (error) {
      localStorage.removeItem("user");
    }
    i18next.changeLanguage(defaultLanguage);
    i18next.changeLanguage(defaultLanguage);
    setComponentsCulture((currentUser as any).language);
    navigate(ROUTE.LOGIN);
  };

  const getGeneralDataItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("GeneralData", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.GENERAL);
      },
      style: { borderRadius: "3px", marginRight: "10px" },
    };
    return mi;
  };

  const getCustomersDataItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("CLIENTI", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.CUSTOMERS);
      },
      style: { borderRadius: "3px", marginRight: "10px" },
    };
    return mi;
  };

  const getEmployeesDataItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("DIPENDENTI", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.EMPLOYEES);
      },
      style: { borderRadius: "3px", marginRight: "10px" },
    };
    return mi;
  };

  const getAppointmentsItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("Appointments", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.APPOINTMENTS);
      },
      style: { borderRadius: "3px", marginRight: "10px" },
    };
    return mi;
  };

  const getWorkPlanDataItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("Workplan", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.AGENDA);
      },
      style: { borderRadius: "3px", marginRight: "10px" },
    };
    return mi;
  };

  const getReportItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("Reports", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.REPORTS);
      },
      style: { borderRadius: "3px", marginRight: "10px" },
    };
    return mi;
  };

  const getActionsItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("Appenders", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.ACTIONS);
      },
      style: { borderRadius: "3px", marginRight: "10px" },
    };
    return mi;
  };

  const getMenu = (): ItemMenu[] => {
    if (!currentUser) return [];

    let menu: ItemMenu[] = [];
    menu.push(getWorkPlanDataItemMenu());
    menuList["agenda"] = menu.length - 1;
    menu.push(getAppointmentsItemMenu());
    menuList["appointments"] = menu.length - 1;
    menu.push(getEmployeesDataItemMenu());
    menuList["employees"] = menu.length - 1;
    menu.push(getCustomersDataItemMenu());
    menuList["customers"] = menu.length - 1;
    menu.push(getGeneralDataItemMenu());
    menuList["general"] = menu.length - 1;
    menu.push(getReportItemMenu());
    menuList["reports"] = menu.length - 1;

    if (currentUser && currentUser.mon) {
      menu.push(getActionsItemMenu());
      menuList["actions"] = menu.length - 1;
    }

    // if (currentUser?.roles.includes(ROLE_ADMIN)){
    //   menu.push(getAdministrationMenuItem());
    //   menuList['administration']=menu.length-1;
    // }

    return menu;
  };

  const getPage = () => {
    const pathName = location.pathname;
    let activeItem: number = 0;

    pathName === "/general"
      ? (activeItem = menuList["general"])
      : pathName === "/appointments"
      ? (activeItem = menuList["appointments"])
      : pathName.startsWith("/customers")
      ? (activeItem = menuList["customers"])
      : pathName.startsWith("/employees")
      ? (activeItem = menuList["employees"])
      : pathName === "/agenda"
      ? (activeItem = menuList["agenda"])
      : pathName === "/reports"
      ? (activeItem = menuList["reports"])
      : pathName === "/actions"
      ? (activeItem = menuList["actions"])
      : (activeItem = 0);

    return activeItem;
  };

  const clickLogo = () => {
    navigate(ROUTE.AGENDA);
  };

  const pjson = require("../package.json");
  const version = pjson.version;

  return (
    <div className="App">
      {currentUser !== null && currentUser.accessToken !== null && currentUser.accessToken !== "" ? (
        <Header
          hideLogo={currentUser ? false : true}
          menuItems={getMenu()}
          authService={authService}
          hideMenuUserBar={currentUser ? false : true}
          user={currentUser ? currentUser.name + " " + currentUser.surname : ""}
          menu={MenuType.Home}
          onSuccessfulLogout={onSuccessfulLogout}
          onSuccessfullChangePassword={onSuccessfulLogout}
          activeItem={getPage()}
          productName={"Smart Helper Appointment"}
          productVersion={version}
          userManualUrl={process.env.PUBLIC_URL + "/documents/smart_helper_manuale_utente.pdf"}
          culture={currentUser ? currentUser.language : "en-US"}
          notificationIconVisible={true}
          onClickLogo={clickLogo}
        />
      ) : (
        ""
      )}
      <div
        style={
          currentUser !== null && currentUser.accessToken !== null && currentUser.accessToken !== ""
            ? { margin: "1rem" }
            : {}
        }
      >
        <Routes>
          <Route path="" element={<PageHome />} />
          <Route path={ROUTE.LOGIN} element={<PageLogin />} />
          <Route path={ROUTE.GENERAL} element={<PageGeneralData />} />
          <Route path={ROUTE.CUSTOMERS} element={<PageCustomers />} />
          <Route
            path={ROUTE.CUSTOMERS + "/:id"}
            element={<PageSingleCustomer culture={currentUser ? currentUser.language : "en-US"} />}
          />
          <Route path={ROUTE.EMPLOYEES} element={<PageEmployees />} />
          <Route
            path={ROUTE.EMPLOYEES + "/:id"}
            element={<PageSingleEmployee culture={currentUser ? currentUser.language : "en-US"} />}
          />
          <Route
            path={ROUTE.APPOINTMENTS}
            element={
              <PageAppointments
                culture={currentUser ? currentUser.language : "en-US"}
                monIntegration={currentUser ? currentUser.mon ?? false : false}
              />
            }
          />
          <Route
            path={ROUTE.AGENDA}
            element={
              <PageWorkPlan
                culture={currentUser ? currentUser.language : "en-US"}
                monIntegration={currentUser ? currentUser.mon ?? false : false}
              />
            }
          />
          <Route
            path={ROUTE.REPORTS}
            element={
              <PageReports
                culture={currentUser ? currentUser.language : "en-US"}
                monIntegration={currentUser ? currentUser.mon ?? false : false}
              />
            }
          />

          {currentUser && currentUser.mon ? (
            <Route
              path={ROUTE.ACTIONS}
              element={
                <PageActionManager
                  culture={currentUser ? currentUser.language : "en-US"}
                  monIntegration={currentUser ? currentUser.mon ?? false : false}
                />
              }
            />
          ) : (
            ""
          )}
          {currentUser && currentUser.mon ? (
            <Route
              path={ROUTE.ACTIONS}
              element={
                <PageActionManager
                  culture={currentUser ? currentUser.language : "en-US"}
                  monIntegration={currentUser ? currentUser.mon ?? false : false}
                />
              }
            />
          ) : (
            ""
          )}
          <Route path={ROUTE.REGISTER} element={<PageRegister />} />
          <Route path={ROUTE.SESSION_EXPIRED} element={<SessionExpired />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
