import { TokenService, User } from "@lcs/frontend";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROLE_ADMIN, ROUTE } from "../utils/Constants";

function PageHome () {
    const navigate = useNavigate();

    const getUserLocal = () : User | null => {
        var currentUser: User | null = {
          id: 0,
          name: "",
          surname: "",
          language: "",
          roles: []
        }
        try {
          currentUser = TokenService.getUser()
        } catch (error) {
          
        }
        return currentUser;
      }
  
    let redirect = () => {
        const user = getUserLocal();

        if (user) {
            if (user.roles.includes(ROLE_ADMIN))
                navigate(ROUTE.ADMINISTRATION);
            else
                navigate(ROUTE.AGENDA);
        } else
            navigate(ROUTE.LOGIN);
     };

     useEffect(() => {
        redirect();
    });
    
   
    return (
        <div></div>
    );
}

export default PageHome;