import {
  ConfigurationPanelContent,
  EditableDataService,
  Entity,
  EntityField,
  EntityFieldAndValue,
} from "@lcs/frontend";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { UseFormGetValues, UseFormWatch } from "react-hook-form";
import api from "../api";
import DialogEventDetails from "./DialogEventDetails";
import FileUploadComponent from "./FileUploadComponent";
import React, { Fragment, useEffect, useState } from "react";
import style from "./PanelAppointments.module.css";
import PanelAppointmentsSpecEscl from "./PanelAppointmentsSpecEscl";
import NotClosedAppointmentService from "../services/appointment.not.closed.service";

function PanelAppointments(props: {
  needUpdate: boolean;
  updateCompleted: () => void;
  culture: string;
  monIntegration: boolean;
}) {
  const [eventDetailsVisible, setEventDetailsVisible] = useState<boolean>(false);
  const [specsVisible, setSpecsVisible] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<number | null>(null);
  const [selectedEventName, setSelectedEventName] = useState<string>("");
  const [selectedEventStatus, setSelectedEventStatus] = useState<string>("");
  const [headerName, setHeaderName] = useState<any>();
  const [dataService, setDataService] = useState<EditableDataService>(
    new NotClosedAppointmentService(api, manipulateEntityFields)
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  // const [actioConfigFormMaximized, setActioConfigFormMaximized] = useState<boolean>(false);
  const currentEntity: Entity = {
    id: 0,
    apiPath: "appuntamento",
    editable: true,
    label: "APPUNTAMENTI",
  };

  useEffect(() => {}, [refresh]);
  function createCustomFormContent(
    entityFieldAndValues: EntityFieldAndValue[],
    getValues: UseFormGetValues<any>,
    watch: UseFormWatch<any>,

    createConfigurationField: (efv: EntityFieldAndValue) => JSX.Element
  ) {
    let eStato = entityFieldAndValues.find((efv) => efv.name === "stato");
    let eDenominazione = entityFieldAndValues.find((efv) => efv.name === "denominazione");
    let eCliente = entityFieldAndValues.find((efv) => efv.name === "idCliente");
    let eSpecializzazione = entityFieldAndValues.find((efv) => efv.name === "idSpecializzazione");
    let eDurata = entityFieldAndValues.find((efv) => efv.name === "durataStimataInMezzore");
    let eLuogo = entityFieldAndValues.find((efv) => efv.name === "luogoAppuntamento");

    let eIndirizzo = entityFieldAndValues.find((efv) => efv.name === "indirizzo");
    let eCitta = entityFieldAndValues.find((efv) => efv.name === "citta");
    let eProvincia = entityFieldAndValues.find((efv) => efv.name === "provincia");

    let eDipendentePreferito = entityFieldAndValues.find((efv) => efv.name === "idDipendentePreferito");
    let eOrarioPreferito = entityFieldAndValues.find((efv) => efv.name === "orarioPreferito");
    let eDipendenteEscluso = entityFieldAndValues.find((efv) => efv.name === "idDipendenteEscluso");

    let eDipendentePianificato = entityFieldAndValues.find((efv) => efv.name === "idDipendentePianificato");
    let eOrarioPianificato = entityFieldAndValues.find((efv) => efv.name === "orarioPianificato");
    let eIndirizzoPianificato = entityFieldAndValues.find((efv) => efv.name === "indirizzoPianificato");
    let eCittaPianificata = entityFieldAndValues.find((efv) => efv.name === "cittaPianificata");
    let eProvinciaPianificata = entityFieldAndValues.find((efv) => efv.name === "provinciaPianificata");

    let eDipendenteStabilito = entityFieldAndValues.find((efv) => efv.name === "idDipendenteStabilito");
    let eOrarioStabilito = entityFieldAndValues.find((efv) => efv.name === "orarioStabilito");

    let eNote = entityFieldAndValues.find((efv) => efv.name === "note");

    let eDescrizione = entityFieldAndValues.find((efv) => efv.name === "descrizione");

    let wLuogo = watch("luogoAppuntamento");
    let wStato = watch("stato");
    let wDipendenteStabilito = watch("idDipendenteStabilito");

    if (wLuogo === "ALTRA_SEDE") {
      if (eIndirizzo && eCitta && eProvincia) {
        eIndirizzo.mandatory = true;
        eCitta.mandatory = true;
        eProvincia.mandatory = true;
        eIndirizzo.readonly = false;
        eCitta.readonly = false;
        eProvincia.readonly = false;
      }
    } else if (wLuogo === "REMOTO" || wLuogo === "PRESSO_DIPENDENTE" || !wLuogo) {
      eIndirizzo = undefined;
      eCitta = undefined;
      eProvincia = undefined;
    } else if (wLuogo === "PRESSO_CLIENTE") {
      if (eIndirizzo && eCitta && eProvincia) {
        eIndirizzo.readonly = true;
        eCitta.readonly = true;
        eProvincia.readonly = true;
      }
    }

    return (
      <div className="formgrid grid">
        <div className="field col-12 md:col-6">{eDenominazione && createConfigurationField(eDenominazione)}</div>
        <div className="field col-12 md:col-6">{eCliente && createConfigurationField(eCliente)}</div>
        {/* <div className="field col-12 md:col-4">{eSpecializzazione && createConfigurationField(eSpecializzazione)}</div> */}

        <div className="field col-12 md:col-6">{eDurata && createConfigurationField(eDurata)}</div>

        <div className="field col-12 md:col-6">{eLuogo && createConfigurationField(eLuogo)}</div>

        <div className="field col-12 md:col-12">
          <Accordion>
            {eIndirizzo && createConfigurationField(eIndirizzo) ? (
              <AccordionTab header="Info Luogo">
                <div className="formgrid grid">
                  <div className="field col-12 md:col-4">
                    {eIndirizzoPianificato && createConfigurationField(eIndirizzoPianificato)}
                  </div>
                  <div className="field col-12 md:col-4">
                    {eCittaPianificata && createConfigurationField(eCittaPianificata)}
                  </div>
                  <div className="field col-12 md:col-4">
                    {eProvinciaPianificata && createConfigurationField(eProvinciaPianificata)}
                  </div>
                </div>
              </AccordionTab>
            ) : null}
            <AccordionTab header="Vincoli di pianificazione">
              <div className="formgrid grid">
                <div className="field col-12 md:col-6">
                  {eDipendentePreferito && createConfigurationField(eDipendentePreferito)}
                </div>
                <div className="field col-12 md:col-6">
                  {eOrarioPreferito && createConfigurationField(eOrarioPreferito)}
                  {/* {eDipendenteEscluso && createConfigurationField(eDipendenteEscluso)} */}
                </div>
              </div>
            </AccordionTab>

            <AccordionTab header="Descrizione e note">
              <div className="formgrid grid">
                <div className="field col-12 md:col-6">{eDescrizione && createConfigurationField(eDescrizione)}</div>
                <div className="field col-12 md:col-6">{eNote && createConfigurationField(eNote)}</div>
              </div>
            </AccordionTab>

            {wStato === "PIANIFICATO" || wStato === "CHIUSO_SUCCESSO" ? (
              <AccordionTab header="Risultato della pianificazione">
                <div className="formgrid grid">
                  <div className="field col-12 md:col-6">
                    {eDipendentePianificato && createConfigurationField(eDipendentePianificato)}
                  </div>
                  <div className="field col-12 md:col-6">
                    {eOrarioPianificato && createConfigurationField(eOrarioPianificato)}
                  </div>
                  <div className="field col-12 md:col-4">
                    {eIndirizzoPianificato && createConfigurationField(eIndirizzoPianificato)}
                  </div>
                  <div className="field col-12 md:col-4">
                    {eCittaPianificata && createConfigurationField(eCittaPianificata)}
                  </div>
                  <div className="field col-12 md:col-4">
                    {eProvinciaPianificata && createConfigurationField(eProvinciaPianificata)}
                  </div>
                </div>
              </AccordionTab>
            ) : null}

            {(wStato === "PIANIFICATO" && wDipendenteStabilito) || wStato === "CHIUSO_SUCCESSO" ? (
              <AccordionTab header="Dati di conferma">
                <div className="formgrid grid">
                  <div className="field col-12 md:col-6">
                    {eDipendenteStabilito && createConfigurationField(eDipendenteStabilito)}
                  </div>
                  <div className="field col-12 md:col-6">
                    {eOrarioStabilito && createConfigurationField(eOrarioStabilito)}
                  </div>
                </div>
              </AccordionTab>
            ) : null}
          </Accordion>
        </div>
      </div>
    );
  }

  function manipulateEntityFields(efArray: EntityField[]) {
    efArray.forEach((ef: EntityField) => {
      if (ef.name === "wfStatus") {
        ef.readonly = true;
      } else if (ef.name === "indirizzo") {
        ef.hidden = true;
      } else if (ef.name === "citta") {
        ef.hidden = true;
      } else if (ef.name === "provincia") {
        ef.hidden = true;
      } else if (ef.name === "stato") {
        ef.hidden = true;
      } else if (ef.name === "note") {
        ef.hidden = true;
      } else if (ef.name === "idDipendentePreferito") {
        ef.hidden = true;
      } else if (ef.name === "orarioPreferito") {
        ef.hidden = true;
      } else if (ef.name === "idDipendenteEscluso") {
        ef.hidden = true;
      } else if (ef.name === "idDipendentePianificato") {
        ef.hidden = true;
      } else if (ef.name === "orarioPianificato") {
        ef.hidden = true;
      } else if (ef.name === "idDipendenteStabilito") {
        ef.hidden = true;
      } else if (ef.name === "orarioStabilito") {
        ef.hidden = true;
      } else if (ef.name === "indirizzoPianificato") {
        ef.hidden = true;
      } else if (ef.name === "cittaPianificata") {
        ef.hidden = true;
      } else if (ef.name === "provinciaPianificata") {
        ef.hidden = true;
      } else if (ef.name === "descrizione") {
        ef.hidden = true;
      }
    });
  }

  function updateStateClick(event: any) {
    setSelectedEventStatus(event.wfStatus);
    setSelectedEvent(event.id);
    setSelectedEventName(event.denominazione);
    setEventDetailsVisible(true);
    setHeaderName("Modifica Appuntamento");
  }

  function getUpdateStateButton() {
    return {
      name: "ChangeState",
      label: "ChangeState",
      icon: "pi pi-play",
      onClick: updateStateClick,
    };
  }

  function getSpecListButton() {
    return {
      name: "AppointmentSpecs",
      label: "AppointmentSpecializations",
      icon: "pi pi-list",
      onClick: (event: any) => {
        setSelectedEventStatus(event.wfStatus);
        setSelectedEvent(event.id);
        setSelectedEventName(event.denominazione);
        setSpecsVisible(true);
      },
    };
  }

  function ondialogAction(): void {
    setEventDetailsVisible(false);
    setDataService(new NotClosedAppointmentService(api, manipulateEntityFields));
    props.updateCompleted();
  }

  const showSpecs = async (arg: any) => {
    setSelectedEvent(arg.id);
    setSelectedEventName(arg.denominazione);
    setSpecsVisible(true);
  };

  useEffect(() => {
    if (props.needUpdate) {
      // workaround per scatenare un ricaricamento dei dati
      setDataService(new NotClosedAppointmentService(api, manipulateEntityFields));
      props.updateCompleted();
    }
  }, [props.needUpdate]);

  return (
    <Fragment>
      <ConfigurationPanelContent
        customHeaderName={"Nuovo appuntamento"}
        label={currentEntity.label}
        editable={true}
        // editColumnHidden={false}
        // cancelColumnHidden={false}
        culture={props.culture}
        dataService={dataService}
        colorHeaderPdf="#b40d0d"
        filterHeader=""
        columnButtons={[getSpecListButton(), getUpdateStateButton()]}
        createCustomFormContent={createCustomFormContent}
        onPostCreateClick={showSpecs}
        onPostUpdateClick={showSpecs}
        headerCustomComponent={<FileUploadComponent page={"appuntamenti"} refresh={refresh} setRefresh={setRefresh} />}
      />
      <DialogEventDetails
        eventDetailsVisible={eventDetailsVisible}
        setEventDetailsVisible={setEventDetailsVisible}
        onAction={ondialogAction}
        appointmentId={selectedEvent}
        culture={props.culture}
        monIntegration={props.monIntegration}
      />
      <Dialog
        header={selectedEventName}
        visible={specsVisible}
        onHide={() => {
          setSpecsVisible(false);
        }}
        style={{ width: "80vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <PanelAppointmentsSpecEscl appointmentId={selectedEvent} />
      </Dialog>
    </Fragment>
  );
}

export default PanelAppointments;
