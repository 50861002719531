import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export function useInterval(callback: ()=>void, delay: number)  {
  const savedCallback: MutableRefObject<(()=>void) | undefined> = useRef();
  const intervalIdRef = useRef<NodeJS.Timeout>();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current)
        savedCallback.current();
    }
    if (delay !== null) {
      if (intervalIdRef.current)
        clearInterval(intervalIdRef.current);
      intervalIdRef.current = setInterval(tick, delay);      
    }

    const id = intervalIdRef.current;
    return () => {
      clearInterval(intervalIdRef.current);
    }
  }, [delay]);

  // handle unmount
  useEffect(() => {
    //const id = intervalIdRef.current;
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  // reset interval
  const resetInterval = useCallback(() => {
    function tick() {
      if (savedCallback.current)
        savedCallback.current();
    }

    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(tick, delay)
  }, [delay]);

  return resetInterval;
}