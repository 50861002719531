export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_USER = "ROLE_USER";
export const ROLE_CUSTOMER = "ROLE_CUSTOMER";
export const ROLE_SUPERVISOR = "ROLE_SUPERVISOR";
export const DEFAULT_TABLE_PAGE_ROWS = 10

export enum ROUTE {
  LOGIN = "/login",
  ADMINISTRATION = "/administration",
  GENERAL = "/general",
  CUSTOMERS = "/customers",
  EMPLOYEES = "/employees",
  SETTINGS = "/settings",
  AGENDA = "/agenda",
  SUPERVISOR = "/supervisor",
  APPOINTMENTS = "/appointments",
  REPORTS = "/reports",
  ACTIONS = "/actions",
  REGISTER = "/register",
  SESSION_EXPIRED = "/sessionexpired",
}

export enum BE_PREFIX {
  APPOINTMENT = "/appointment",
  AUTHENTICATION = "/authentication",
  MONITORING = "/monitoring",
  PLANNING = "/planning",
  IDP = "/idp",
}
export const colorEvent = {
  chiusoFallimento: "#D26064",
  chiusoInterlocutorio: "#FBE86B",
  chiusoSuccesso: "#6AB57F",
  confermato: "#7182D4",
  pianificato: "#55D2FF",
};
