import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import EmployeeService from "../services/employee.service";
import AppointmentService from "../services/appointment.service";
import api from "../api";
import { ProgressSpinner } from 'primereact/progressspinner';

export interface employeeAppointment{
  dipendente: string;
  appuntamento: string;
}

export interface appointmentsSovrapposti{
  dipendente: string;
  appuntamento: string;
  appuntamento2: string;
}

function PanelAppointmentsDetails(props: {vis: boolean, setVis: (arg1: boolean) => void}){
  const thTd = {padding: "4px", border: "1px solid #dddddd"}
  const [empAppList, setEmpAppList] = useState<employeeAppointment[]>([])
  const [appSovrapposti, setAppSovrapposti] = useState<appointmentsSovrapposti[]>([])
  const [tot, setTot] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const appointmentService: AppointmentService = new AppointmentService(api);
  const employeeService = new EmployeeService(api);    

  const mostRecentDetails = () => {
    setEmpAppList([])
    setAppSovrapposti([])
    appointmentService.getMostrecentdetails().then(async data => {
      if (data && data.data){
        setTot(data.data.score.total);
        await decodeRuleMap(data.data.score);
      }
    })
  }

  useEffect(() => {      
      if(props.vis){
        setLoading(true);
        mostRecentDetails()          
      }
  }, [props.vis])

  const getDetailsForIdoneita = async (par: any): Promise<employeeAppointment[]> => {
    let arrayEmp: employeeAppointment[] = [];

    for(let i = 0; i < par.length; i++){
      const empRes = await employeeService.getById(String(par[i][0].value));    
      const emp = empRes.data;    
      const appRes = await appointmentService.getById(par[i][1].value.toString());
      const app = appRes.data;
      let empApp: employeeAppointment = {
        dipendente: emp?.denominazione, 
        appuntamento: app?.denominazione??"-"
      };
      arrayEmp.push(empApp);                 
    }
    return arrayEmp;
  }

  const getIdoneitaDetails = async (par: any) => {
    const arrayImpiegatiIdoneita = await getDetailsForIdoneita(par);
    setEmpAppList(arrayImpiegatiIdoneita);
  }

  const getDetailsForSovrapposizioni = async (par: any) : Promise<appointmentsSovrapposti[]> => {
    let arrayEmp: appointmentsSovrapposti[] = []
    for(let i = 0; i < par.length; i++){
      const appointment = await appointmentService.getById(par[i][0].value.toString());      
      const app = appointment.data
      const appointment2 = await appointmentService.getById(par[i][1].value.toString())        
      const app2 = appointment2.data;
      const appSov: appointmentsSovrapposti = {
        dipendente: par[i][2].value.toString(), 
        appuntamento: app.denominazione??"-", 
        appuntamento2: app2.denominazione??"-"}
        arrayEmp.push(appSov);                      
    }
    return arrayEmp;
  }

  const getSovrapposizioniDetails = async (par: any) => {
    const arrayImpiegatiSovrapposizioni = await getDetailsForSovrapposizioni(par);
    setAppSovrapposti(arrayImpiegatiSovrapposizioni);
  }

  const decodeRuleMap = async (riep: any) => {
    for(let i = 0; i < riep.contributions.length; i++){
      const cont = riep.contributions[i];
      switch(cont.rule){
        case "Sovrapposizioni":
          await getSovrapposizioniDetails(cont.messages.map((e: any) => e.params));
          break;
        case "Idoneita":
          await getIdoneitaDetails(cont.messages.map((e: any) => e.params));
        default:
          continue;
      }
    };
    setLoading(false);
  }
    
  return (
    <>
      <Dialog header={i18next.t('RiepilogoPianificazione', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
              visible={props.vis} style={{ width: '50vw' }} 
              onHide={() => props.setVis(false)}>
      <div style={{marginBottom: "2rem"}}>
        <div style={{fontWeight:"bold", marginBottom:"0.5rem"}}>{i18next.t('Punteggio', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</div>
        <p>{tot}</p>

        {loading? <ProgressSpinner /> : <></>}

        {/* Sovrapposizioni */}
        { appSovrapposti.length !== 0 ? 
            <div style={{marginBottom:"0.5rem"}}>
              <div style={{display:"flex", flexDirection:"row", columnGap: "0.5rem", marginBottom: "0.5rem"}}>
                <div style={{fontWeight:"bold"}}>
                  {i18next.t('Sovrapposizione', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                </div>
                {i18next.t('SovrapossizioniApp', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
              </div>
              <table style={{borderCollapse: "collapse", textAlign: "left", width: "100%"}}>
                <tr>
                  <th style={thTd}>{i18next.t('Cliente', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</th>
                  <th style={thTd}>{i18next.t('APPUNTAMENTO', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</th>
                  <th style={thTd}>{i18next.t('APPUNTAMENTO', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</th>
                </tr>
                {appSovrapposti.map((e: appointmentsSovrapposti) => 
                  <tr>
                    <td style={thTd}>{e.dipendente}</td>
                    <td style={thTd}>{e.appuntamento}</td>
                    <td style={thTd}>{e.appuntamento2}</td>
                  </tr>
                  )
                }
              </table>
            </div>
          : null
        }

        {/* Idoneità */}
        { empAppList.length !== 0 ? 
          <>
            <div style={{display:"flex", flexDirection:"row", columnGap: "0.5rem", marginBottom: "0.5rem"}}>
              <div style={{fontWeight:"bold"}}>
                {i18next.t('Idoneita', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
              </div>
              {i18next.t('NonIdonei', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
            </div>  
            <table style={{borderCollapse: "collapse", textAlign: "left", width: "100%"}}>
              <tr>
                <th style={thTd}>{i18next.t('Dipendente', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</th>
                <th style={thTd}>{i18next.t('APPUNTAMENTO', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</th>
              </tr>
              { empAppList.map((e: employeeAppointment) => 
                  <tr>
                    <td style={thTd}>{e.dipendente}</td>
                    <td style={thTd}>{e.appuntamento}</td>
                  </tr>
                )
              }
            </table>
          </>
          : null
        } 
      </div>
      </Dialog>
    </>
    )
}

export default PanelAppointmentsDetails;