import { EditableDataService, EditableDataServiceResponse, Entity, EntityField, IConfigurationService, ICustomService, IDataServiceResponse } from "@lcs/frontend";

class StdEditableDataService implements EditableDataService {
    readonly entity: Entity;
    readonly customService: ICustomService;
    readonly configurationService: IConfigurationService;

    constructor(entity: Entity, customService: ICustomService, cs: IConfigurationService) {
        this.entity = entity;
        this.customService = customService;
        this.configurationService = cs;
    }

    getDocument(documentCode: string): Promise<string> {
        // To be implemented in custom services, retriving document by the documentCode
        // in the choosen file storage system.
        throw new Error("Method not implemented.");
    }

    getData = () : Promise<IDataServiceResponse> => {
        return this.customService.getEntityData(this.entity.apiPath)
        .then ((result: object[]) => {
            let totals = {};
            return {data : result, totals: totals};
        });
    }

    getReferenceData = (path: string) : Promise<object[]> => {
        return this.customService.getEntityData(path);
    }

    addObj = (newObj: any) : Promise<EditableDataServiceResponse> => {
        return this.customService.addEntity(this.entity.apiPath, newObj);
    }

    updateObj = (obj: any) : Promise<EditableDataServiceResponse> => {
        return this.customService.updateEntity(this.entity.apiPath, obj);
    }

    deleteObj = (obj: any) : Promise<EditableDataServiceResponse> => {
        return this.customService.deleteEntity(this.entity.apiPath, obj);
    }

    getConfiguration = () : Promise<EntityField[]> => {
        return this.configurationService.getDefinitions(this.entity.apiPath)
        //.then(result => { 
            // TEST hidden property
            // let f : EntityField | undefined = result.data.find (d => d.name == "IMAGE"); 
            // if (f)
            //   f.hidden = true;
            // TEST readonly property
        //     let f : EntityField | undefined = result.find (d => d.name == "TYPE"); 
        //     if (f)
        //        f.readonly = true;            
        //     return result;
        // })
        ;
    }

}

export default StdEditableDataService;