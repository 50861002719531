import React, { useState, useRef } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { Button } from 'primereact/button'
import 'primeicons/primeicons.css'
import { InputText } from 'primereact/inputtext'
import i18next from "i18next"
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend"
import axios from "axios"
import { Toast } from 'primereact/toast'
import { useNavigate } from 'react-router-dom'
import { BE_PREFIX } from "../utils/Constants"

const DialogRegister = (props: {username: any, change: any}) => {
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<emailCode>();
    const onSubmit: SubmitHandler<emailCode> = data => console.log(data);
    const [authorizationCode, setCode] = useState<any>()
    
    type emailCode = {
        code: String,
    };

    const sendCode = async () => {
        const username = props.username
        const data = await axios.post((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.IDP + '/api/v1/registration/confirm-registration', {username, authorizationCode} )
        const check = data.data

        if(check.success){
            props.change()
            toast.current?.show({
                severity: 'success',
                detail: i18next.t('RegistrazioneSuccesso', { ns: CUSTOM_PRJ_TRANSLATION_FILE })
            });
            navigate("/login")
        }
    }

    const takeCode = (e: any) => {
        setCode(e.target.value)
    }

    return(
        <>
        <Toast ref={toast} />
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>{i18next.t('CodiceMail', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</label><br/>
                <InputText {...register("code", { required: true })} onChange={takeCode} style={{width: "100%"}}/><br/>
                {errors.code?.type === 'required' && <p role="alert" style={{color: "red", textAlign: "center", marginTop: "0px", marginBottom: "0px"}}>{i18next.t("IS_REQUIRED")}</p>}

                <div style={{marginTop: "20px", marginLeft: "33vh"}}>
                    <Button type="submit"  label={i18next.t('Verifica', { ns: CUSTOM_PRJ_TRANSLATION_FILE })} style={{width: "30vh", fontWeight: "bold"}} onClick={sendCode} />
                </div>
            </form>
        </div>
        </>
    )
}

export default DialogRegister