import {
  ConfigurationPanelContent,
  ConfigurationServiceSourceType,
  Entity,
  EntityFieldAndValue,
  IConfigurationService,
  ICustomService,
} from "@lcs/frontend";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import StdEditableDataService from "../services/std.edit.data.service";
import AppointmentCustomService from "../services/appointment.custom.service";
import AppointmentConfigurationService from "../services/appointment.configuration.service";
import FileUploadComponent from "./FileUploadComponent";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import { UseFormGetValues, UseFormWatch } from "react-hook-form";

export interface PanelCustomersProps {
  culture: string;
}

function PanelCustomers(props: PanelCustomersProps) {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {}, [refresh]);

  const currentEntity: Entity = {
    id: 0,
    apiPath: "cliente",
    editable: true,
    label: "CLIENTI",
  };
  const configurationService: IConfigurationService = new AppointmentConfigurationService({
    entityDefinitionSource: ConfigurationServiceSourceType.axios,
    axios: api,
    entityDefinitionJsonFilePath: null,
    entityRoleJsonFilePath: null,
  });
  const customService: ICustomService = new AppointmentCustomService(api);
  const dataService = new StdEditableDataService(currentEntity, customService, configurationService);
  function createCustomFormContent(
    entityFieldAndValues: EntityFieldAndValue[],
    getValues: UseFormGetValues<any>,
    watch: UseFormWatch<any>,
    createConfigurationField: (efv: EntityFieldAndValue) => JSX.Element
  ) {
    // Definiamo i campi che ci interessano
    const fields = [
      "nomeReferente",
      "cognomeReferente",
      "denominazione",
      "email",
      "emailSecondaria",
      "indirizzo",
      "citta",
      "provincia",
      "regione",
      "idTipoCliente",
      "classificazioneCliente",
      "idDipendenteRiferimento",
      "numeroIncontriAnnuali",
      "numeroIncontriInPresenza",
      "note",
    ];

    // Usiamo reduce per creare un oggetto che contenga i campi rilevanti
    const entityFields = entityFieldAndValues.reduce((acc, efv) => {
      console.log(efv);
      if (fields.includes(efv.name)) {
        acc[efv.name] = efv;
      }
      return acc;
    }, {} as Record<string, EntityFieldAndValue | undefined>);

    // Controlliamo e modifichiamo eDenominazione se i campi nome e cognome sono presenti
    if (entityFields.denominazione && entityFields.nome && entityFields.cognome) {
      const wNome = watch("nome")?.toString() || "";
      const wCognome = watch("cognome")?.toString() || "";
      //rendiamo denominazione readonly e il suo valore nome cognome (controllare il servizio)
      entityFields.denominazione.readonly = true;
      entityFields.denominazione.value = `${wNome} ${wCognome}`;
      entityFields.denominazione.mandatory = false;
    }

    // array con la configurazione per ogni campo
    const fieldConfigs = [
      { field: "denominazione", size: "col-12 md:col-3" },
      { field: "nomeReferente", size: "col-12 md:col-3" },
      { field: "cognomeReferente", size: "col-12 md:col-3" },
      { field: "idDipendenteRiferimento", size: "col-12 md:col-3" },
      { field: "email", size: "col-12 md:col-6" },
      { field: "emailSecondaria", size: "col-12 md:col-6" },
      { field: "indirizzo", size: "col-12 md:col-6" },
      { field: "citta", size: "col-12 md:col-2" },
      { field: "provincia", size: "col-12 md:col-2" },
      { field: "regione", size: "col-12 md:col-2" },
      { field: "idTipoCliente", size: "col-12 md:col-3" },
      { field: "classificazioneCliente", size: "col-12 md:col-3" },

      { field: "numeroIncontriAnnuali", size: "col-12 md:col-3" },
      { field: "numeroIncontriInPresenza", size: "col-12 md:col-3" },
      { field: "note", size: "col-12 md:col-12" },
    ];

    // Renderizziamo il contenuto in modo dinamico
    return (
      <>
        <div className="formgrid grid">
          {fieldConfigs.map(({ field, size }) => (
            <div key={field} className={`field ${size}`}>
              {entityFields[field] && createConfigurationField(entityFields[field] as EntityFieldAndValue)}
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <div style={{}}>
      <ConfigurationPanelContent
        createCustomFormContent={createCustomFormContent}
        label={currentEntity.label}
        editable={currentEntity.editable ? currentEntity.editable : false}
        dataService={dataService}
        colorHeaderPdf="#b40d0d"
        culture={props.culture}
        filterHeader=""
        columnButtons={[
          {
            name: i18next.t("CustomerDetails", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
            label: i18next.t("CustomerDetails", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),

            icon: "pi pi-list",
            onClick: (event) => {
              navigate(`/customers/${event.id}`, {
                state: {
                  id: event.id,
                  name: event.name,
                },
              });
            },
          },
        ]}
        headerCustomComponent={<FileUploadComponent page={"clienti"} refresh={refresh} setRefresh={setRefresh} />}
      />
    </div>
  );
}

export default PanelCustomers;
