import React from "react";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";

export interface AppointmentPropertyProps {
  label: string;
  content?: any;
}

const AppointmentProperty = (props: AppointmentPropertyProps) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", columnGap: "10px" }}>
      <label className="labelAppointment" style={{ fontWeight: "bold" }}>
        {i18next.t(props.label, { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
      </label>
      <label style={{ marginTop: "2px", marginBottom: "7px" }} className="labelAppointmentContent" aria-multiline>
        {props.content}
      </label>
    </div>
  );
};

export default AppointmentProperty;
