import React from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
        
function OverlayPanelWorkPlan(props: {visible: boolean}){

    return(
        <>
        {props.visible ? 
        <div className="op-spinner">
                <ProgressSpinner />
        </div>
        : null}
        </>
    )
}

export default OverlayPanelWorkPlan;