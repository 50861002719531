import {
  CUSTOM_PRJ_TRANSLATION_FILE,
  ConfigurationServiceSourceType,
  EditableDataService,
  EditableDataServiceResponse,
  Entity,
  EntityField,
  IConfigurationService,
  ICustomService,
  ICustomServiceResponse,
  IDataServiceResponse,
} from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";
import { LinkedResource } from "./common.types";
import StdEditableDataService from "./std.edit.data.service";
import i18next from "i18next";
import { BE_PREFIX } from "../utils/Constants";
import AppointmentCustomService from "./appointment.custom.service";
import AppointmentConfigurationService from "./appointment.configuration.service";

export interface EmployeeOut {
  id: number;
  cognome: string;
  nome: string;
  denominazione: string;
  idAzienda?: LinkedResource;
  idProblema?: LinkedResource;
  idSede?: LinkedResource;
  idOrarioGiornaliero?: LinkedResource;
}

export interface EmployeeServiceResponse extends ICustomServiceResponse {
  data: EmployeeOut[];
}

export interface SingleEmployeeServiceResponse extends ICustomServiceResponse {
  data: EmployeeOut;
}

const currentEntity: Entity = {
  id: 0,
  apiPath: "dipendente",
  editable: true, // TODO: leggerlo da getEntitiesForRole?
  label: "DIPENDENTI",
};

class EmployeeService implements EditableDataService {
  readonly axios: AxiosInstance;
  readonly configurationService: IConfigurationService;
  readonly customService: ICustomService;
  readonly dataService: EditableDataService;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.configurationService = new AppointmentConfigurationService({
      entityDefinitionSource: ConfigurationServiceSourceType.axios,
      axios: this.axios,
      entityDefinitionJsonFilePath: null,
      entityRoleJsonFilePath: null,
    });
    this.customService = new AppointmentCustomService(this.axios);
    this.dataService = new StdEditableDataService(currentEntity, this.customService, this.configurationService);
  }

  getData(): Promise<IDataServiceResponse> {
    return this.dataService.getData();
  }
  getReferenceData(path: string): Promise<object[]> {
    return this.dataService.getReferenceData(path);
  }
  addObj(obj: object): Promise<EditableDataServiceResponse> {
    return this.dataService.addObj(obj);
  }
  updateObj(obj: object): Promise<EditableDataServiceResponse> {
    return this.dataService.updateObj(obj);
  }
  deleteObj(obj: object): Promise<EditableDataServiceResponse> {
    return this.dataService.deleteObj(obj);
  }
  getConfiguration(): Promise<EntityField[]> {
    return this.dataService.getConfiguration().then((result: EntityField[]) => {
      result.forEach((ef: EntityField) => {
        if (ef.name === "idCalendario") {
          ef.customTooltip = i18next.t("CalendarTooltip", { ns: [CUSTOM_PRJ_TRANSLATION_FILE] });
        }

        // if (ef.name === "denominazione"){
        //     ef.customTooltip = i18next.t("DenominazioneTooltip", { ns: [CUSTOM_PRJ_TRANSLATION_FILE]});
        // }
      });
      return result;
    });
  }
  getDocument(documentCode: string): Promise<string> {
    return this.dataService.getDocument(documentCode);
  }

  getAll = (): Promise<EmployeeServiceResponse> => {
    return this.axios
      .get((process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api/data/dipendente/")
      .then((result: AxiosResponse<EmployeeServiceResponse>) => result.data);
  };

  getById = (employeeId: string): Promise<SingleEmployeeServiceResponse> => {
    return this.axios
      .get((process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api/data/dipendente/" + employeeId)
      .then((result: AxiosResponse<SingleEmployeeServiceResponse>) => result.data);
  };

  getAllMyCollegues = (): Promise<EmployeeServiceResponse> => {
    return this.axios
      .get((process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api/data/dipendente/listAllMyCollegues")
      .then((result: AxiosResponse<EmployeeServiceResponse>) => result.data);
  };
}

export default EmployeeService;
