import {
  EditableDataService,
  EditableDataServiceResponse,
  EntityField,
  ICustomServiceResponse,
  IDataServiceResponse,
} from "@lcs/frontend";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import CustomerFilterService from "./customer.filter.service";
import EmployeeFilterService from "./employee.filter.service";
import SpecializationFilterService from "./specialization.filter.service";
import { BE_PREFIX } from "../utils/Constants";
import { APPOINTMENT_WF_STATE } from "./appointment.service";

class NotClosedAppointmentService implements EditableDataService {
  readonly axios: AxiosInstance;
  readonly customerFilterService: CustomerFilterService;
  readonly employeeFilterService: EmployeeFilterService;
  readonly specializationFilterService: SpecializationFilterService;
  readonly manipulateEntityFields?: (efArray: any) => void;
  readonly AppointmentBasePath: string;

  constructor(axios: AxiosInstance, manipulateEntityFields?: (efArray: any) => void) {
    this.axios = axios;
    this.customerFilterService = new CustomerFilterService(axios);
    this.employeeFilterService = new EmployeeFilterService(axios);
    this.specializationFilterService = new SpecializationFilterService(axios);
    this.manipulateEntityFields = manipulateEntityFields;
    this.AppointmentBasePath = (process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api";
  }
  async getData(): Promise<IDataServiceResponse> {
    let config: AxiosRequestConfig = {
      params: {
        status: `${APPOINTMENT_WF_STATE.PIANIFICATO},
                  ${APPOINTMENT_WF_STATE.INIIZIALE},
                  ${APPOINTMENT_WF_STATE.CONFERMATO},
                  ${APPOINTMENT_WF_STATE.INIIZIALE}`,
      },
    };
    const result = await this.axios.get(
      (process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api/data/appuntamento/filterByStato",
      config
    );
    return result.data;
  }
  getReferenceData(path: string): Promise<object[]> {
    switch (path) {
      case "cliente":
        return this.customerFilterService.getData();
      case "specializzazione":
        return this.specializationFilterService.getData();
      case "dipendente":
        return this.employeeFilterService.getData();
      default:
        return Promise.resolve([]);
    }
  }
  addObj(obj: object): Promise<EditableDataServiceResponse> {
    return this.axios.post(this.AppointmentBasePath + "/data/appuntamento", obj).then((result: AxiosResponse<any>) => {
      return result.data;
    });
  }
  updateObj(obj: any): Promise<EditableDataServiceResponse> {
    const params = new URLSearchParams([["itemId", obj.id]]);
    return this.axios
      .put(this.AppointmentBasePath + "/data/appuntamento", obj, { params })
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  }
  deleteObj(obj: any): Promise<EditableDataServiceResponse> {
    const params = new URLSearchParams([["itemId", obj.id]]);
    return this.axios
      .delete(this.AppointmentBasePath + "/data/appuntamento", { params })
      .then((result: AxiosResponse<ICustomServiceResponse>) => result.data);
  }
  getConfiguration(): Promise<EntityField[]> {
    return this.axios
      .get<EntityField[]>(this.AppointmentBasePath + "/configuration/entityField/appuntamento")
      .then((result) => {
        let r = result.data;
        if (this.manipulateEntityFields) this.manipulateEntityFields(r);
        return r;
      });
  }
  getDocument(documentCode: string): Promise<string> {
    throw new Error("Method not implemented.");
  }
}

export default NotClosedAppointmentService;
