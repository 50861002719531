
import React, { Fragment, useEffect, useState } from "react";
import api from "../api";
import CustomerService from "../services/customer.service";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";

export interface PanelSingleCustomerProps {
    customerId: number
}

function PanelSingleCustomer(props: PanelSingleCustomerProps) {

    const [customer, setCustomer] = useState <any> ();
    let customerId = props.customerId;

    useEffect(() => {
        const customerService = new CustomerService(api, customerId);
        customerService.getData()
        .then(data => {
            setCustomer(data[0]);
      });
 
    }, []);

    return <Fragment>
        <strong>{i18next.t("Cliente", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}: {customer?customer.denominazione:""}</strong>
    </Fragment>
}

export default PanelSingleCustomer;