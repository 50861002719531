import _locales_en_common from "./locales/en/common.json";
import _locales_it_common from "./locales/it/common.json";

const locales_en_common = _locales_en_common;
const locales_it_common = _locales_it_common;

export { default as Configuration } from "./configuration";
export {
  default as ConfigurationMenu,
  ConfigurationMenuProps,
} from "./configuration/ConfigurationMenu";
export {
  default as ConfigurationPanelContent,
  ConfigurationPanelContentProps,
} from "./configuration/configuration-panel/configuration-panel-content/ConfigurationPanelContent";
export {
  default as ConfigurationFieldFloatLabel,
  ConfigurationFieldProps,
} from "./configuration/configuration-panel/configuration-field/ConfigurationField";
export {
  default as ConfigurationForm,
  ConfigurationFormProps,
} from "./configuration/configuration-panel/configuration-form/ConfigurationForm";
export {
  default as ConfigurationPanleContentInputDialog,
  ConfigurationPanelContentInputDialogProps,
} from "./configuration/configuration-panel/configuration-panel-content-input-dialog/ConfigurationPanelContentInputDialog";
export { renderFunction } from "./configuration/configuration-rendering/ConfigurationRendering";
export { default as TokenService, User } from "./services";
export { default as Login } from "./components/Login/Login";
export { setComponentsCulture } from "./utils/Common";
export { default as Footer } from "./Footer";
export {
  default as Header,
  HeaderProps,
  ItemMenu,
} from "./components/Header/Header";
export {
  default as ConfigurationService,
  IConfigurationService,
  ConfigurationServiceProps,
  ConfigurationServiceSourceType,
} from "./services/configuration.service";
export {
  default as CustomService,
  ICustomService,
  ICustomServiceResponse,
} from "./services/custom.service";
export {
  default as AuthService,
  IAuthService,
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  LogoutResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
} from "./services/auth.service";
export { default as PageTemplate, PageTemplateProps } from "./PageTemplate";
export {
  default as ReportTemplate,
  ReportTemplateProps,
} from "./configuration/report-template/ReportTemplate";
export { default as IDataService } from "./services/i.data.service";
export { ICalendar } from "./services/i.calendar.service";
export { CalendarServiceResponse } from "./services/i.calendar.service";
export { REASON_NO_CALENDAR } from "./services/i.calendar.service";
export { default as ICalendarService } from "./services/i.calendar.service";
export { default as Viewer } from "./configuration/viewer/Viewer";
export {
  EditableDataServiceResponse,
  EditableDataService
} from "./services/editable.data.service";
export {
  UploadDataServiceResponse,
  UploadDataService
} from "./services/upload.data.service";
export { default as StdUploadDataService } from "./services/std.upload.data.service";
export {
  AIDataServiceResponse,
  AIDataService
} from "./services/ai.data.service";
export { default as StdAIDataService } from "./services/std.ai.data.service";
export { IDataServiceResponse } from "./services/IDataServiceResponse";
export { appendInterceptors } from "./services/api";
export { MenuType } from "./components/HeaderUserComp/HeaderUserComp";
export {
  Entity,
  EntityField,
  EntityForRole,
  EntityFieldAndValue,
} from "./configuration/ConfigurationTypes";
export {
  CUSTOM_PRJ_TRANSLATION_FILE,
  COMMON_TRANSLATION_FILE,
} from "./utils/Constants";
export { correctDate, serializeDate } from "./utils/Common";
export { locales_en_common, locales_it_common };
export { default as Modale } from "./components/Modale";
export { default as TextEditor } from "./components/TextEditor/TextEditor";
export { default as MailEditor } from "./components/MailEditor/MailEditor";
export { default as FileUploadComponent } from "./components/FileUploadComponent/FileUploadComponent";
export { default as InputTextTemplate } from "./components/InputTextTemplate/InputTextTemplate";
export { default as InputPasswordTemplate } from "./components/InputPasswordTemplate/InputPasswordTemplate";
export { default as ButtonTemplate } from "./components/ButtonTemplate/ButtonTemplate";
export { default as MailEditorFloatLabel } from "./components/MailEditorFloatLabel/MailEditorFloatLabel";
export { default as LoginFloatLabel } from "./components/LoginFloatLabel/LoginFloatLabel";
export { default as SpeechToTextTA} from "./components/SpeechToTextTA/SpeechToTextTA";