import React, { useEffect, useRef, useState } from "react";
import api from "../api";
import SHFullCalendar, { BusinessHours, CalendarEvent, CalendarResource } from "../components/SHFullCalendar";
import ProblemService, {
  DefinedProblemResponse,
  LastSubmittedRequest,
  LastSubmittedResponse,
} from "../services/problem.service";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE, IDataServiceResponse } from "@lcs/frontend";
import AppointmentService, {
  AppointmentOut,
  AppointmentServiceResponse,
  APPOINTMENT_STATE,
  APPOINTMENT_WF_STATE,
  SimpleAppointmentRequest,
} from "../services/appointment.service";
import { Toast } from "primereact/toast";
import EmployeeService, { EmployeeOut, EmployeeServiceResponse } from "../services/employee.service";
import AbsenceService, { AbsenceOut, AbsenceServiceResponse } from "../services/absence.service";
import { calculateAppointmentEndDate, getColorForEventState } from "../utils/Common";
import WorkingTimeService, { WorkingTimeServiceResponse } from "../services/workingTime.service";
import DialogEventDetails from "../components/DialogEventDetails";
import OverlayPanelWorkPlan from "../components/OverlayPanelWorkPlan";
import { LinkedResource } from "../services/common.types";
import AppointmentSpecsService from "../services/appointment.specs.service";
import DialogMultiConfirm from "../components/DialogMultiConfirm";

export interface RisultatoChecks {
  idoneitaViolata: boolean;
  idoneitaListaSpecs: { idSpecializzazione: string; denominazione: string }[];
  sovrapposizioneViolata: boolean;
  sovrapposizioneListaEventi: {
    idAppuntamento: string;
    denominazione: string;
    idCliente: string;
    denominazioneCliente: string;
    inizio: string;
    durata: number;
  }[];
}

export interface EventExtendedProps {
  id?: number;
  luogoAppuntamento?: string;
  indirizzo?: string;
  citta?: string;
  provincia?: string;
  cliente?: string;
  tipoCliente?: string;
  durata?: string;
  specializzazioni?: string[];
  idDipendente?: number;
  inizio?: Date;
  idAppuntamento?: string;
  wfStatus?: APPOINTMENT_WF_STATE;
  denominazione?: string;
  isindispDipendente?: boolean;
  indirizzoPianificato?: string;
  cittaPianificata?: string;
  provinciaPianificata?: string;
  note?: string;
  descrizione?: string;
  risultatoChecks?: RisultatoChecks;
}

export interface PageWorkPlanProps {
  culture: string;
  monIntegration: boolean;
}

function PageWorkPlan(props: PageWorkPlanProps) {
  const myToast = useRef<any>();
  console.log(props);

  const [resources, setResources] = useState<CalendarResource[]>([]);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [eventDetailsVisible, setEventDetailsVisible] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<number | null>(null);
  const [eventDetails, setEventDetails] = useState<EventExtendedProps>({});
  const [problemService] = useState<ProblemService>(new ProblemService(api));
  const [appointmentService] = useState<AppointmentService>(new AppointmentService(api));
  const [employeeService] = useState<EmployeeService>(new EmployeeService(api));
  const [workingTimeService] = useState<WorkingTimeService>(new WorkingTimeService(api));
  const [absenceService] = useState<AbsenceService>(new AbsenceService(api));
  const [appoinmentList, setAppointmentList] = useState<AppointmentOut[]>([]);
  const [spinnerVisible, setSpinnerVisible] = useState<boolean>(false);
  const [newEvents, setNewEvents] = useState<CalendarEvent[]>([]);

  const [visChangeStatus, setVisChangeStatus] = useState<boolean>(false);
  const [visReasonsStatus, setVisReasonsStatus] = useState<boolean>(false);
  const [dataReasons, setDataReasons] = useState<any>();

  useEffect(() => {
    redirect();
    loadProblemDefinition().then((windowEndDate: Date) => {
      loadData(windowEndDate);
    });
  }, []);

  let redirect = () => {
    // Workaroud to prevent showing multiple events on calendar between before dropping
  };

  const checkOverlappedAppointments = (eventDetails: EventExtendedProps) => {
    if (eventDetails.risultatoChecks?.sovrapposizioneListaEventi.length === 0) return false;
    let appDifferentFromThis = 0;
    const overlappedAppList = eventDetails.risultatoChecks?.sovrapposizioneListaEventi ?? [];
    for (let i = 0; i < overlappedAppList.length; i++) {
      if (eventDetails.idAppuntamento && eventDetails.idAppuntamento != overlappedAppList[i].idAppuntamento)
        appDifferentFromThis++;
    }
    return appDifferentFromThis > 0;
  };

  const completeDraggedEventDetails = async (
    calEvent: CalendarEvent,
    appuntamento: AppointmentOut
  ): Promise<CalendarEvent> => {
    const appSpecService = new AppointmentSpecsService(api, appuntamento.id);
    let specializzazioni: LinkedResource[] = [];
    let appSpecRes: IDataServiceResponse = await appSpecService.getData();
    appSpecRes.data.forEach((spec: any, k: any) => {
      specializzazioni.push(spec.idSpecializzazione);
    });
    const extProps: EventExtendedProps = {
      luogoAppuntamento: appuntamento.luogoAppuntamento?.toString(),
      indirizzo: appuntamento.indirizzo ?? "",
      citta: appuntamento.citta ?? "",
      provincia: appuntamento.provincia ?? "",
      cliente: appuntamento?.idCliente?.value,
      tipoCliente: appuntamento.idCliente?.value,
      durata: appuntamento.durataPianificata,
      specializzazioni: specializzazioni?.map((spec) => spec.value),
      idDipendente: appuntamento.idDipendentePianificato?.id,
      inizio: appuntamento.orarioPianificato ? new Date(appuntamento.orarioPianificato) : undefined,
      idAppuntamento: appuntamento.id.toString(),
      wfStatus: appuntamento.wfStatus,
      denominazione: appuntamento.denominazione,
      isindispDipendente: false,
      indirizzoPianificato: appuntamento.indirizzoPianificato,
      cittaPianificata: appuntamento.cittaPianificata ?? "",
      provinciaPianificata: appuntamento.provinciaPianificata ?? "",
      note: appuntamento.note,
      risultatoChecks: appuntamento.risultatoCheck,
    };
    let eventStart: Date = appuntamento.orarioPianificato ? new Date(appuntamento.orarioPianificato) : new Date();
    let eventEnd: Date = calculateAppointmentEndDate(eventStart, appuntamento.durataPianificata);

    let newEvent = { ...calEvent };
    newEvent = {
      id: appuntamento.id.toString(),
      title: appuntamento.denominazione ?? "",
      start: eventStart,
      end: eventEnd,
      resourceId: appuntamento.idDipendentePianificato?.id.toString() ?? "",
      extendedProps: extProps,
      color: getColorForEventState(appuntamento.stato, appuntamento.idDipendenteStabilito ? true : false),
    };
    return newEvent;
  };

  const addEvent = /* async */ (
    events: CalendarEvent[],
    appuntamento: AppointmentOut,
    minDate: Date,
    isInPlanningPeriod: boolean
  ) => {
    if (appuntamento === null || typeof appuntamento === "undefined") {
      return minDate;
    }
    // const appSpecService = new AppointmentSpecsService(api, appuntamento.id);
    //let specializzazioni: LinkedResource[] = [];
    /*         let appSpecRes: IDataServiceResponse = await appSpecService.getData();
        appSpecRes.data.forEach((spec: any) => {
            specializzazioni.push(spec.idSpecializzazione);
        });    */
    const extProps: EventExtendedProps = {
      luogoAppuntamento: appuntamento.luogoAppuntamento?.toString(),
      indirizzo: appuntamento.indirizzo ?? "",
      citta: appuntamento.citta ?? "",
      provincia: appuntamento.provincia ?? "",
      cliente: appuntamento?.idCliente?.value,
      tipoCliente: appuntamento.idCliente?.value,
      durata: appuntamento.durataPianificata,
      specializzazioni: [], //specializzazioni?.map(spec => spec.value),
      idDipendente: appuntamento.idDipendentePianificato?.id,
      inizio: appuntamento.orarioPianificato ? new Date(appuntamento.orarioPianificato) : undefined,
      idAppuntamento: appuntamento.id.toString(),
      wfStatus: appuntamento.wfStatus,
      denominazione: appuntamento.denominazione,
      isindispDipendente: false,
      indirizzoPianificato: appuntamento.indirizzoPianificato,
      cittaPianificata: appuntamento.cittaPianificata ?? "",
      provinciaPianificata: appuntamento.provinciaPianificata ?? "",
      note: appuntamento.note,
      risultatoChecks: appuntamento.risultatoCheck,
    };
    let eventStart: Date = appuntamento.orarioPianificato ? new Date(appuntamento.orarioPianificato) : new Date();
    if (eventStart < minDate) {
      minDate = eventStart;
    }

    let eventEnd: Date = calculateAppointmentEndDate(eventStart, appuntamento.durataPianificata);

    const calEvent: CalendarEvent = {
      id: appuntamento.id.toString(),
      title: appuntamento.denominazione ?? "",
      start: eventStart,
      end: eventEnd,
      resourceId: appuntamento.idDipendentePianificato?.id.toString() ?? "",
      extendedProps: extProps,
      color: getColorForEventState(appuntamento.stato, appuntamento.idDipendenteStabilito ? true : false),
    };
    events.push(calEvent);
    return minDate;
  };

  const createAbsenceEvent = (absence: AbsenceOut) => {
    const extProps: EventExtendedProps = {
      idDipendente: absence.idDipendente.id,
      inizio: absence.da,
      idAppuntamento: absence.id.toString(),
      denominazione: absence.denominazione,
      isindispDipendente: true,
    };
    const calEvent: CalendarEvent = {
      id: absence.id.toString(),
      title: absence.denominazione ?? "",
      start: absence.da,
      end: absence.a,
      resourceId: absence.idDipendente?.value ?? "",
      extendedProps: extProps,
      color: "grey",
    };
    return calEvent;
  };

  //const colorForOutOfPeriod: string = "LightGray";

  const addEntryToEvents = (entry: CalendarEvent) => {
    setEvents((old) => [...old, entry]);
  };

  const addEntryToResources = (entry: CalendarResource) => {
    setResources((old) => (checkOld(old, entry) ? [...old] : [...old, entry]));
  };

  //controllo duplicati risorse
  const checkOld = (old: CalendarResource[], entry: CalendarResource) => {
    if (old.find((e) => e.id === entry.id)) {
      return true;
    } else return false;
  };

  const loadProblemDefinition = (): Promise<Date> => {
    let probStartDate: Date = new Date();
    return new Promise<Date>((resolve, reject) => {
      problemService.getDefinedProblem().then((probResp: DefinedProblemResponse) => {
        probStartDate = new Date(probResp.data[0].startDate);
        probStartDate.setDate(probStartDate.getDate() + probResp.data[0].planningDays);
        setEndDate(probStartDate);
        resolve(probStartDate);
      });
    });
  };

  const loadData = async (windowEndDate: Date) => {
    setSpinnerVisible(true);
    setEvents([]);

    const lsubReq: LastSubmittedRequest = {
      problemName: "Problema Unico",
    };
    problemService.getLastSubmittedProblem(lsubReq).then((lsubRes: LastSubmittedResponse) => {
      if (!lsubRes.success) {
        setSpinnerVisible(false);
        myToast.current.show({
          severity: "error",
          summary: i18next.t("ErrorPlanningGeneric", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
          detail: lsubRes.reason,
        });
        return;
      }
    }); // GetLastSubmittedPloblem end

    ///////////////////////////////////////////////////
    // Loading appointments to create events for calendar
    let resDelRes: AppointmentServiceResponse = await appointmentService.getAll();
    if (!resDelRes.success) {
      setSpinnerVisible(false);
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: resDelRes.reason,
      });
      return;
    }

    let minDate: Date = new Date();
    let _tempEvents: CalendarEvent[] = [];
    const _appointmentList: AppointmentOut[] = [...resDelRes.data];
    let _newEvents: CalendarEvent[] = [];
    for (let index = 0; index < _appointmentList.length; index++) {
      const appuntamento: AppointmentOut = _appointmentList[index];
      let eventStart: Date = appuntamento.orarioPianificato ? new Date(appuntamento.orarioPianificato) : new Date();
      let eventEnd: Date = calculateAppointmentEndDate(eventStart, appuntamento.durataPianificata);
      //if (eventEnd <= windowEndDate)
      minDate = await addEvent(_tempEvents, appuntamento, minDate, eventEnd <= windowEndDate);

      if (
        appuntamento.wfStatus === "INIZIALE" &&
        _newEvents.findIndex((e) => e.id === appuntamento.id.toString()) === -1
      ) {
        const calEvent: CalendarEvent = {
          id: appuntamento.id.toString(),
          title: appuntamento.denominazione ?? "",
          duration: appuntamento.durataStimataInMezzore ?? "01:00",
          start: new Date(), // Not used by calendar
          resourceId: appuntamento.idDipendentePianificato?.id.toString() ?? "",
        };
        _newEvents.push(calEvent);
      }
    }

    ///////////////////////////////////////////////////
    // Loading employees to create resources for calendar
    let _resourcesList: CalendarResource[] = [];
    const resEmployeeList: EmployeeServiceResponse = await employeeService.getAllMyCollegues();
    if (!resEmployeeList.success) {
      setSpinnerVisible(false);
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: resEmployeeList.reason,
      });
      return;
    }
    let errorOnTimeEmployeeDataRead: boolean = false;
    const _employeeList: EmployeeOut[] = [...resEmployeeList.data];
    for (let index = 0; index < _employeeList.length; index++) {
      const employee: EmployeeOut = _employeeList[index];
      // Getting working time for each resource (employee)
      const wtResponse: WorkingTimeServiceResponse = await workingTimeService.getById(
        employee.idOrarioGiornaliero ? employee.idOrarioGiornaliero.id : 0
      );
      let bh: BusinessHours = {
        startTime: "00:00",
        endTime: "23:59",
      };
      if (wtResponse.success) {
        bh.startTime = wtResponse.data.inizioTurno;
        bh.endTime = wtResponse.data.fineTurno;
      } else {
        errorOnTimeEmployeeDataRead = false;
        // Go on adding resource anyway with default business hours
      }
      _resourcesList.push({
        id: employee.id.toString(),
        title: employee.denominazione,
        eventColor: "green",
        businessHours: bh,
      });

      // Getting absence list for each resource (employee) ==> create new events for calendar
      const restAbsenceList: AbsenceServiceResponse = await absenceService.getByEmployeeId(employee.id);
      if (!restAbsenceList.success) {
        errorOnTimeEmployeeDataRead = false;
      } else {
        const _employeeAbsenceList: AbsenceOut[] = [...restAbsenceList.data];
        for (let index = 0; index < _employeeAbsenceList.length; index++) {
          const abs = _employeeAbsenceList[index];
          let absenceEvents: CalendarEvent = createAbsenceEvent(abs);
          _tempEvents.push(absenceEvents);
        }
      }
    }

    setAppointmentList(_appointmentList);
    setResources(_resourcesList);
    setStartDate(minDate);
    setEvents(_tempEvents);
    setNewEvents(_newEvents);
    setSpinnerVisible(false);

    if (errorOnTimeEmployeeDataRead) {
      myToast.current.show({
        severity: "warning",
        summary: i18next.t("Warning", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: i18next.t("ErrorOnTimeEmployeeDataReadMsg", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        }),
      });
      return;
    }
  };

  const reloadDataAfterChangeStatus = async (windowEndDate: Date) => {
    setSpinnerVisible(true);
    ///////////////////////////////////////////////////
    // Loading appointments to create events for calendar
    let resDelRes: AppointmentServiceResponse = await appointmentService.getAll();
    if (!resDelRes.success) {
      setSpinnerVisible(false);
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: resDelRes.reason,
      });
      return;
    }

    let minDate: Date = new Date();
    let _tempEvents: CalendarEvent[] = [];
    const _appointmentList: AppointmentOut[] = [...resDelRes.data];
    let _newEvents: CalendarEvent[] = [];
    for (let index = 0; index < _appointmentList.length; index++) {
      const appuntamento: AppointmentOut = _appointmentList[index];
      let eventStart: Date = appuntamento.orarioPianificato ? new Date(appuntamento.orarioPianificato) : new Date();
      let eventEnd: Date = calculateAppointmentEndDate(eventStart, appuntamento.durataPianificata);
      minDate = addEvent(_tempEvents, appuntamento, minDate, eventEnd <= windowEndDate);

      if (
        appuntamento.wfStatus === "INIZIALE" &&
        _newEvents.findIndex((e) => e.id === appuntamento.id.toString()) === -1
      ) {
        const calEvent: CalendarEvent = {
          id: appuntamento.id.toString(),
          title: appuntamento.denominazione ?? "",
          duration: appuntamento.durataStimataInMezzore ?? "01:00",
          start: new Date(), // Not used by calendar
          resourceId: appuntamento.idDipendentePianificato?.id.toString() ?? "",
        };
        _newEvents.push(calEvent);
      }
    }

    setAppointmentList(_appointmentList);
    setStartDate(minDate);
    setEvents(_tempEvents);
    setNewEvents(_newEvents);
    setSpinnerVisible(false);
  };

  const eventClick = (arg: any) => {
    if (!arg.event.extendedProps.isindispDipendente) {
      setSelectedEvent(arg.event.id);
      setEventDetails(arg.event.extendedProps);
      setEventDetailsVisible(true);
    }
  };

  const updateAppointment = async (
    id: string,
    date: string,
    resourceId: number,
    unplan?: boolean
  ): Promise<boolean> => {
    if (unplan) {
      return await unplanAppointment(id);
    } else {
      return await planAppointment(id, date, resourceId);
    }
  };

  const planAppointment = async (id: string, date: string, resourceId: number): Promise<boolean> => {
    setSpinnerVisible(true);
    let appIndex: number = appoinmentList.findIndex((a: AppointmentOut) => a.id.toString() === id);
    if (appIndex === -1) {
      // console.log("planAppointment error: cannot find appointment with id: " + id);
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: i18next.t("ErrorPlanMsg", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      });
      setSpinnerVisible(false);
      return false;
    }
    let app: AppointmentOut = appoinmentList[appIndex];

    app.orarioPianificato = date;

    if (app.idDipendentePianificato) {
      app.idDipendentePianificato.id = resourceId;
    }

    if (app.idDipendentePianificato === null && app.wfStatus === APPOINTMENT_WF_STATE.INIIZIALE) {
      const dipendente: LinkedResource = {
        id: resourceId,
        value: "",
      };
      app.idDipendentePianificato = dipendente; //id e value serve
      app.wfStatus = APPOINTMENT_WF_STATE.PIANIFICATO;
      app.stato = APPOINTMENT_STATE.PIANIFICATO;
      app.durataPianificata = app.durataStimataInMezzore;
    }

    const res = await appointmentService.updateObj(app);

    if (!res.success) {
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: i18next.t(res.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      });
      setSpinnerVisible(false);
      return false;
    }

    const appCheck = await appointmentService.getById(id);
    if (!appCheck.success) {
      setSpinnerVisible(false);
      return true;
    }
    const _events = [...events];
    const eventFoundIndex = _events.findIndex((element) => element.id === id);
    if (eventFoundIndex === -1) {
      setSpinnerVisible(false);
      return true;
    }
    const eventToBeUpdated: CalendarEvent = _events[eventFoundIndex];
    let updatedEvent: CalendarEvent = await completeDraggedEventDetails(eventToBeUpdated, appCheck.data);
    setEvents((old) => [...old, updatedEvent]);

    setSpinnerVisible(false);
    return true;
  };

  const unplanAppointment = async (id: string): Promise<boolean> => {
    setSpinnerVisible(true);
    let appIndex: number = appoinmentList.findIndex((a: AppointmentOut) => a.id.toString() === id);
    if (appIndex === -1) {
      // console.log("unplanAppointment error: cannot find appointment with id: " + id);
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: i18next.t("ErrorUplanMsg", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      });
      setSpinnerVisible(false);
      return false;
    }

    let unplanReq: SimpleAppointmentRequest = {
      appointmentId: Number(id),
    };
    const res = await appointmentService.unplan(unplanReq);
    if (!res.success) {
      setSpinnerVisible(false);
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: i18next.t(res.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      });
      return false;
    }

    // Get updated violations info
    const appCheck = await appointmentService.getById(id);
    if (!appCheck.success) {
      setSpinnerVisible(false);
      return true;
    }
    const _events = [...events];
    const eventFoundIndex = _events.findIndex((element) => element.id === id);
    if (eventFoundIndex === -1) {
      setSpinnerVisible(false);
      return true;
    }
    const eventToBeUpdated: CalendarEvent = _events[eventFoundIndex];
    let updatedEvent: CalendarEvent = await completeDraggedEventDetails(eventToBeUpdated, appCheck.data);
    setEvents((old) => [...old, updatedEvent]);
    setSpinnerVisible(false);
    return true;
  };

  function ondialogAction(): void {
    reloadDataAfterChangeStatus(endDate);
    setEventDetailsVisible(false);
  }

  return (
    <>
      <Toast ref={myToast} />
      <SHFullCalendar
        setVis={setVisChangeStatus}
        type="resourceTimeline"
        resources={resources}
        disableBeforeDate={startDate}
        events={events}
        eventClick={eventClick}
        culture={props.culture}
        updateEvent={updateAppointment}
        newEvents={newEvents}
        setNewEvents={setNewEvents}
        colorLegendItems={[
          {
            color: getColorForEventState(APPOINTMENT_STATE.PIANIFICATO, false),
            label: i18next.t(APPOINTMENT_STATE.PIANIFICATO, {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
          },
          {
            color: getColorForEventState(APPOINTMENT_STATE.PIANIFICATO, true),
            label: i18next.t("CONFERMATO", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          },
          {
            color: getColorForEventState(APPOINTMENT_STATE.CHIUSO_SUCCESSO, false),
            label: i18next.t(APPOINTMENT_STATE.CHIUSO_SUCCESSO, {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
          },
          {
            color: getColorForEventState(APPOINTMENT_STATE.CHIUSO_INTERLOCUTORIO, false),
            label: i18next.t(APPOINTMENT_STATE.CHIUSO_INTERLOCUTORIO, {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
          },
          {
            color: getColorForEventState(APPOINTMENT_STATE.CHIUSO_FALLIMENTO, false),
            label: i18next.t(APPOINTMENT_STATE.CHIUSO_FALLIMENTO, {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
          },
          // Automatic planning disabled,
          /*                     {
                        color: colorForOutOfPeriod,
                        label: i18next.t("FUORI_DA_PERIODO_DI_PIANIFICAZIONE",{ ns: CUSTOM_PRJ_TRANSLATION_FILE })
                    } */
        ]}
        setEventClassNames={(arg: any) => {
          let classNamesList: string[] = [];
          if (
            arg.event.extendedProps.risultatoChecks &&
            arg.event.extendedProps.wfStatus === APPOINTMENT_WF_STATE.PIANIFICATO
          ) {
            const risCheck: RisultatoChecks = arg.event.extendedProps.risultatoChecks;
            if (risCheck.idoneitaViolata) {
              classNamesList.push("shfc-violation-idon");
            }
            if (risCheck.sovrapposizioneViolata && checkOverlappedAppointments(arg.event.extendedProps)) {
              classNamesList.push("shfc-violation-sovr");
            }
            /* if (risCheck.esclusioneViolata){
                        classNamesList.push("shfc-violation-escl");
                    } */
          }
          return classNamesList;
        }}
        setEventDetailsVisible={setEventDetailsVisible}
        setEventDetails={setEventDetails}
        setSelectedEvent={setSelectedEvent}
      />
      <DialogEventDetails
        eventDetailsVisible={eventDetailsVisible}
        setEventDetailsVisible={setEventDetailsVisible}
        onAction={ondialogAction}
        eventDetails={eventDetails}
        appointmentId={selectedEvent}
        culture={props.culture ?? "it-IT"}
        monIntegration={props.monIntegration}
      />
      <OverlayPanelWorkPlan visible={spinnerVisible} />
      <div>
        <DialogMultiConfirm
          vis={visChangeStatus}
          setVis={setVisChangeStatus}
          setNeedUpdate={() => {}}
          agendaAction={ondialogAction}
          addDialogComponent={true}
        />
      </div>
      <div></div>
    </>
  );
}

export default PageWorkPlan;
