import React from "react";
import api from "../api";
import { ConfigurationPanelContent } from "@lcs/frontend";
import EmployeeSpecService from "../services/employee.spec.service";

export interface PanelEmployeeSpecProps {
    culture: string;
    employeeId: number;
}

function PanelEmployeSpec(props: PanelEmployeeSpecProps) {

    const dataService = new EmployeeSpecService(api, props.employeeId);

    return <div>
        <ConfigurationPanelContent
            label="Employee Spec"
            editable={true}
            culture={props.culture}
            dataService={dataService}
            colorHeaderPdf="blue"
            filterHeader=""
            refreshButton={false} />
    </div>;
}

export default PanelEmployeSpec;