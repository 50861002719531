import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";

class EmployeeFilterService {
    readonly axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
      this.axios = axios;
    }
  
    getData = (): Promise<object[]> => {
      var config: AxiosRequestConfig = {
      };
      return this.axios
        .get((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/dipendente", config)
        .then((result: AxiosResponse<any>) => {
            let a : any[] = result.data.data; 
            return a.map (c => {
                return {
                    id: c.id,
                    denominazione: c.denominazione
                }
            })
        });
    };
  
}

export default EmployeeFilterService;