import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  IAuthService,
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  LogoutResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
  TokenService,
  User,
} from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";

export interface SmartHelperLoginResponse {
  success: boolean;
  reason: string;
  accessToken: string;
  refreshToken: string;
}

export interface SmartHelperLoginResponseNew {
  success: boolean;
  reason: string;
  accessToken: string;
  refreshToken: string;
}

export interface SmartHelperLoginRequest {
  username: string;
  password: string;
  timeZoneId?: string;
}

export interface SmartHelperRefreshTokenRequest {
  refreshToken: string;
  timeZoneId?: string;
}

export interface SHAppointmentUser extends User {
  gc?: boolean; // se l'azienda è integrata con Google Calendar
  mon?: boolean; // se l'azienda è integrata con SH Monitoring
}

class SmartHelperAuthService implements IAuthService {
  readonly axios: AxiosInstance;

  LOGIN: string;
  LOGOUT: string;
  GETUSERINFO: string;
  CHANGE_PASSWORD: string;
  REFRESH_TOKEN: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    let IDP_BASE_PATH =
      (process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.IDP + "/api/v1/auth";
    this.LOGOUT = IDP_BASE_PATH + "/logout";
    this.LOGIN = IDP_BASE_PATH + "/login";
    this.GETUSERINFO = IDP_BASE_PATH + "/user-info";
    this.CHANGE_PASSWORD = IDP_BASE_PATH + "/change-password";
    this.REFRESH_TOKEN = IDP_BASE_PATH + "/refresh-token";
  }

  login = (request: LoginRequest): Promise<any> => {
    let shLoginReq: SmartHelperLoginRequest = request;
    shLoginReq.timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.axios
      .post(this.LOGIN, shLoginReq)
      .then((response: AxiosResponse<SmartHelperLoginResponse>) => {
        if (
          (response && response.data.success) ||
          (response &&
            !response.data.success &&
            response.data.reason === "ALREADY LOGGED IN")
        ) {
          var user: SHAppointmentUser = {
            id: 0,
            name: "",
            surname: "",
            language: "",
            roles: [],
          };
          TokenService.setUser(user);
          TokenService.updateLocalAccessToken(response.data.accessToken);
          return this.getuserinfoCall().then((userInfo: LoginResponse) => {
            if (userInfo.success) {
              userInfo.accessToken = response.data.accessToken;
              userInfo.refreshToken = response.data.refreshToken;
            } else {
              userInfo.accessToken = "";
              userInfo.refreshToken = "";
              TokenService.updateLocalAccessToken("");
            }
            return userInfo;
          });
        } else return this.getEmptyLoginResponse();
      });
  };

  getEmptyLoginResponse = (): LoginResponse => {
    return {
      success: false,
      reason: "",
      accessToken: "",
      id: 0,
      refreshToken: "",
      email: "",
      tokenType: "Bearer",
      username: "",
      name: "",
      surname: "",
      language: "",
      roles: [],
    };
  };

  getuserinfoCall = async () => {
    let loginUserInfo: LoginResponse = this.getEmptyLoginResponse();
    return this.axios
      .get(this.GETUSERINFO)
      .then((resUserInfo: AxiosResponse<any>): LoginResponse => {
        if (resUserInfo && resUserInfo.data) {
          loginUserInfo = {
            success: true,
            reason: "OK",
            accessToken: "",
            id: 0,
            refreshToken: "",
            email: "",
            tokenType: "Bearer",
            username: "",
            name: resUserInfo.data.name,
            surname: resUserInfo.data.surname,
            language: resUserInfo.data.language,
            roles: [
              resUserInfo.data.modeltypelist.filter(
                (x: any) => x.modeltypename === "APPOINTMENT_MANAGEMENT"
              ).usergroup,
            ],
          };
        } else {
          if (resUserInfo && !resUserInfo.data) {
            console.log("user-info failed");
          }
        }
        return loginUserInfo;
      });
  };

  logout = (request: LogoutRequest): Promise<LogoutResponse> => {
    return this.axios
      .post(this.LOGOUT, request)
      .then((result: AxiosResponse<LogoutResponse>) => result.data);
  };

  updatePassword(
    request: ChangePasswordRequest
  ): Promise<ChangePasswordResponse> {
    return this.axios
      .post(this.CHANGE_PASSWORD, request)
      .then((result: AxiosResponse<ChangePasswordResponse>) => result.data);
  }

  refreshToken = (
    request: RefreshTokenRequest
  ): Promise<RefreshTokenResponse> => {
    let shRTReq: SmartHelperRefreshTokenRequest = request;
    shRTReq.timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.axios
      .post(this.REFRESH_TOKEN, request)
      .then((result: AxiosResponse<RefreshTokenResponse>) => result.data);
  };
}

export default SmartHelperAuthService;
