import React from "react";
import { Chart } from "primereact/chart";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";

function ChartDashboard(props: { dati: any; opzioni: any }) {
  const colorAttivitaTot = "#8294C4";
  const colorAttivitaChiuse = "#6AB57F";

  let dipendentiTotali: any[] = [];
  const renderDipendenti = () => {
    const dipendenti = props.dati.axis;
    if (Array.isArray(dipendenti)) {
      dipendenti.map((dip: any) => {
        dipendentiTotali.push(dip.denominazione);
      });
    }
    return dipendentiTotali;
  };

  const chart = {
    labels: renderDipendenti(),
    datasets: [
      {
        label: i18next.t("AppuntamentiTot", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        }),
        backgroundColor: colorAttivitaTot,
        borderColor: colorAttivitaTot,
        borderRadius: 8,
        data: props.dati.data1,
      },
      {
        label: i18next.t("AppuntmentiChiusi", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        }),
        backgroundColor: colorAttivitaChiuse,
        borderRadius: 8,
        borderColor: colorAttivitaChiuse,
        data: props.dati.data2,
      },
    ],
  };

  return (
    <div className="card">
      <Chart type="bar" data={chart} options={props.opzioni} />
    </div>
  );
}

export default ChartDashboard;
