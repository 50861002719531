import React, { LegacyRef, useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import engbLocale from "@fullcalendar/core/locales/en-gb";
import itLocale from "@fullcalendar/core/locales/it";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import i18next from "i18next";
// Premium license required
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import ColorLegend, { ColorLegendItem } from "./ColorLegend/ColorLegend";
import "primeicons/primeicons.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Paginator } from "primereact/paginator";
import DialogResourceInfo from "./DialogResourceInfo";
import AppointmentService from "../services/appointment.service";
import api from "../api"; // TODO removes
import { Button } from "primereact/button";
import DialogMultiResource from "./DialogMultiResource";
import { Toast } from "primereact/toast";

export interface BusinessHours {
  startTime: string;
  endTime: string;
}

export interface CalendarResource {
  id: string;
  title: string;
  eventColor?: string;
  businessHours: BusinessHours;
}

export interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end?: Date;
  duration?: string;
  resourceId: string;
  extendedProps?: any;
  color?: string;
  display?: string;
}

export interface SHFullCalendarProps {
  type: string;
  switchView?: () => void;
  resources?: CalendarResource[]; // ResourceSourceInput
  disableBeforeDate?: any; // DateInput
  events?: CalendarEvent[]; // EventSourceInput
  dateClick?: (arg: any) => void;
  eventClick?: (e: any) => void;
  culture?: string;
  colorLegendItems?: ColorLegendItem[];
  setEventClassNames?: (arg: any) => string[];
  updateEvent: (
    id: any,
    data: any,
    informazioni: any,
    unplan?: boolean
  ) => Promise<any>;
  newEvents: CalendarEvent[];
  setNewEvents: (c: CalendarEvent[]) => void;
  setVis: (e: any) => void;
  setEventDetailsVisible: (e: any) => void;
  setEventDetails: (e: any) => void;
  setSelectedEvent: (e: any) => void;
}

function SHFullCalendar(props: SHFullCalendarProps) {
  const [currentResourceView, setCurrentResourceView] =
    useState<string>("resourceTimeline");
  const calendarRef: LegacyRef<FullCalendar> = React.createRef();
  const [filter, setFiter] = useState(false);
  const [selectedResource, setSelectedResource] = useState<any>();
  const [resource, setResource] = useState<any[]>([]);
  const [resourcePage, setResourcePage] = useState<CalendarResource[]>([]);
  const filterN = 12;
  const [visSource, setVisSource] = useState<boolean>(false);
  const [nameRes, setNameRes] = useState<any>();
  const [idRes, setIdRes] = useState<any>("aaa");
  const [chartData, setChartData] = useState<any>([]);
  const [datatRes, setDatatRes] = useState<any>();
  const [vis, setVis] = useState<boolean>(false);
  const [datatResFilter, setDatatResFilter] = useState<any>();
  const myToast = useRef<any>();
  const [draggableEvents, setDraggableEvents] = useState<CalendarEvent[]>([]);

  const containerEl = useRef<any>(); // componenente grafico che contiene gli eventi nuovi che saranno draggabili
  const draggableZone = useRef<any>();
  let draggable: Draggable;

  useEffect(() => {
    setDraggableEvents(props.newEvents);
  }, [props.newEvents]);

  useEffect(() => {
    if (draggable) {
      return;
    }
    // Rendiamo "draggabili" le consegne nuove contenute nella lista
    // eslint-disable-next-line react-hooks/exhaustive-deps
    draggable = new Draggable(containerEl.current, {
      itemSelector: ".event",
      eventData: (eventEl) => {
        const divEle = document.getElementById(eventEl.id);
        const durata = divEle?.getAttribute("data-duration");
        return {
          title: eventEl.innerText,
          duration: durata,
        };
      },
    });
  }, []);
  useEffect(() => {
    setResourcePage(props.resources ? filterRes(props.resources) : []);
    setTotalRecords(props.resources ? props.resources.length : 0);
    // props.resources?.sort()
    setResource(
      props.resources
        ? props.resources?.map((res: CalendarResource) => {
            return { name: res.title, code: res.id };
          })
        : []
    );
  }, [props.resources]);

  const switchResourceButtonIcon = () => {
    return currentResourceView === "resourceTimeline"
      ? "bi bi-grip-vertical"
      : "bi bi-grip-horizontal";
  };

  const filterEvButtonIcon = () => {
    return filter ? "bi bi-calendar3-week" : "bi bi-calendar3";
  };

  const areaHeaderContent = () => {
    return !filter ? (
      <div style={{ color: "#172667" }}>
        {i18next.t("Employees", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
      </div>
    ) : (
      <div style={{ color: "#259747" }}>
        {i18next.t("DipendentiFilterActive", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        })}
      </div>
    );
  };

  const filterRes = (risorse: CalendarResource[]) => {
    const size = risorse.length;

    if (size > filterN) {
      return risorse.slice(0, filterN);
    } else {
      return risorse;
    }
  };

  const search = () => {
    if (selectedResource) {
      const filteredData: any = props.resources?.filter((resource) => {
        return (
          selectedResource.filter(
            (selRes: any) => selRes.code.toString() === resource.id
          ).length > 0
        );
      });
      setResourcePage(filteredData);
    } else if (
      selectedResource === undefined ||
      selectedResource.length === 0
    ) {
      setSelectedResource(props.resources);
    } else {
      // setResourcePage(props.resources ? filterRes(props.resources) : []);
      setResourcePage(props.resources ? props.resources : []);
    }
  };

  const feel = () => {
    setResourcePage(props.resources!);
  };

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(filterN);
  const [totalRecords, setTotalRecords] = useState<number>();

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(filterN);
    setResourcePage(
      props.resources
        ? props.resources.slice(event.first, filterN + event.first)
        : []
    );
  };

  const eventDrop = async (informazioni: any) => {
    let id = informazioni._def.publicId;
    if (id === "") {
      alert("Error empty id");
      //informazioni.revert();
      return;
    }
    let newStart = informazioni.start;
    let newDate = new Date(newStart).toISOString();
    await props.updateEvent(id, newDate, informazioni._def.resourceIds[0]);
  };

  const eventDragStop = async (e: any) => {
    let id = e.event._def.publicId;
    if (id === "") {
      alert("Error empty id");
      return;
    }
    let x1 = draggableZone.current.offsetLeft;
    let x2 =
      draggableZone.current.offsetLeft + draggableZone.current.offsetWidth;
    let y1 = draggableZone.current.offsetTop;
    let y2 =
      draggableZone.current.offsetTop + draggableZone.current.offsetHeight;

    if (
      e.jsEvent.pageX >= x1 &&
      e.jsEvent.pageX <= x2 &&
      e.jsEvent.pageY >= y1 &&
      e.jsEvent.pageY <= y2
    ) {
      const okRemoveEvent: boolean = await props.updateEvent(id, "", 0, true);
      if (okRemoveEvent) {
        const calEvent: CalendarEvent = {
          id: id.toString(),
          title: e.event._def.title,
          start: new Date(),
          resourceId: "",
          duration: e.event.extendedProps.durata
        };
        let _draggableEvents: CalendarEvent[] = [...draggableEvents];
        _draggableEvents.push(calEvent);
        setDraggableEvents(_draggableEvents);
        props.setNewEvents(_draggableEvents);
      }
    }
  };

  const dropNewEvent = async (informazioni: any) => {
    let id = informazioni.draggedEl.id;
    if (id === "") {
      alert("Error empty id");
      //informazioni.revert();
      return;
    }
    let newDate = informazioni.event.startStr;
    const okRemoveEvent: boolean = await props.updateEvent(
      id,
      newDate,
      informazioni.event._def.resourceIds[0]
    );
    if (!okRemoveEvent) return;

    let _draggableEvents: CalendarEvent[] = [...draggableEvents];
    const index: number = _draggableEvents.findIndex(
      (e: CalendarEvent) => e.id === id
    );
    if (index >= 0) {
      _draggableEvents.splice(index, 1);
      setDraggableEvents(_draggableEvents);
      props.setNewEvents(_draggableEvents);
    }
    //informazioni.draggedEl?.parentNode?.removeChild(informazioni.draggedEl);
  };

  const sendData = (inf: any) => {
    setIdRes(inf.id);
    setNameRes(inf.title);

    let today: any = new Date().toISOString();

    let oneYearAgo: any = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    let oneYear: any = oneYearAgo.toISOString();

    const data = {
      idAzienda: null,
      idDipendente: parseInt(inf.id),
      from: oneYear,
      to: today,
      threshold: 80.0,
      sampleNumber: 20,
    };
    const appointmentService: AppointmentService = new AppointmentService(api);
    appointmentService.getAppointementKpi(data).then((result: any) => {
      if (result.success) {
        if (result.data.kpi.length !== 0) {
          setDatatRes(result);
          setVisSource(true);
        }
      } else {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("ErroreGenerico", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
        });
      }
    });
  };

  const resourceFilter = () => {
    let today: any = new Date().toISOString();

    let oneYearAgo: any = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    let oneYear: any = oneYearAgo.toISOString();

    const data = {
      idDipendente: null,
      from: oneYear,
      to: today,
      threshold: 80.0,
      sampleNumber: 20,
    };

    const appointmentService: AppointmentService = new AppointmentService(api);
    appointmentService.getAppointementKpi(data).then((result: any) => {
      if (result.success) {
        if (result.data.kpi.length !== 0) {
          setDatatResFilter(result);
          setVis(true);
        }
      } else {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("ErroreGenerico", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
        });
      }
    });
  };

  const dialogDetails = (event: any) => {
    props.setSelectedEvent(event.id);
    props.setEventDetails(event);
    props.setEventDetailsVisible(true);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Toast ref={myToast} />

      <>
        <div className="newEventsPanel" ref={draggableZone}>
          <p className="fixedHeader">
            {i18next.t("NewEvents", { ns: [CUSTOM_PRJ_TRANSLATION_FILE] })}
          </p>
          <div className="containerEvent" ref={containerEl}>
            {props.newEvents.map((event, index) => {
              return (
                <button type="button" 
                  className="event"
                  id={event.id}
                  onClick={() => dialogDetails(event)}
                  key={"key_" + index}
                  data-duration={event.duration}
                >{event.title}</button>
            )})}
          </div>
        </div>
      </>

      <div style={{ width: "100%" }}>
        <FullCalendar
          schedulerLicenseKey="0845597762-fcs-1706818388"
          themeSystem="bootstrap5"
          nowIndicator={true}
          slotMinTime="00:00"
          stickyFooterScrollbar={true}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            resourceTimelinePlugin,
            resourceTimeGridPlugin,
            scrollGridPlugin,
          ]}
          locales={[itLocale, engbLocale]}
          locale={props.culture && props.culture === "it-IT" ? "it" : "en-gb"} //Per ora supportiamo solo inglese e italiano
          selectable={true}
          customButtons={
            props.type === "resourceTimeline" &&
            currentResourceView.startsWith("resourceTime")
              ? {
                  switchResourceButton: {
                    hint: i18next.t("SwitchResourceView", {
                      ns: CUSTOM_PRJ_TRANSLATION_FILE,
                    }),
                    icon: switchResourceButtonIcon(),
                    click: function () {
                      if (currentResourceView === "resourceTimeline") {
                        setCurrentResourceView("resourceTimeGrid");
                        const currentView: string =
                          calendarRef.current?.getApi().view.type ??
                          "resourceTimeline";
                        const rangeType: string = currentView.substring(
                          "resourceTimeline".length
                        );
                        if (rangeType === "Month")
                          calendarRef.current
                            ?.getApi()
                            .changeView("resourceTimeGridWeek");
                        else
                          calendarRef.current
                            ?.getApi()
                            .changeView("resourceTimeGrid" + rangeType);
                      } else {
                        setCurrentResourceView("resourceTimeline");
                        const currentView: string =
                          calendarRef.current?.getApi().view.type ??
                          "resourceTimeGrid";
                        const rangeType: string = currentView.substring(
                          "resourceTimeGrid".length
                        );
                        calendarRef.current
                          ?.getApi()
                          .changeView("resourceTimeline" + rangeType);
                      }
                    },
                  },

                  filterEvButton: {
                    hint: i18next.t("FiltraEventi", {
                      ns: CUSTOM_PRJ_TRANSLATION_FILE,
                    }),
                    icon: filterEvButtonIcon(),
                    click: () => {
                      setFiter(!filter);
                    },
                  },

                  changeStatus: {
                    hint: i18next.t("StatoConsegne", {
                      ns: CUSTOM_PRJ_TRANSLATION_FILE,
                    }),
                    icon: "bi bi-check2",
                    click: () => props.setVis(true),
                  },
                }
              : undefined
          }
          headerToolbar={
            props.switchView
              ? {
                  left: "prev,next,today, SwitchView",
                  center: "title",
                  right:
                    props.type === "Calendar"
                      ? "dayGridMonth,timeGridWeek,timeGridDay"
                      : currentResourceView +
                        "Day," +
                        currentResourceView +
                        "Week," +
                        currentResourceView +
                        "Month",
                }
              : {
                  left:
                    currentResourceView === "resourceTimeline" ||
                    currentResourceView === "resourceTimeGrid"
                      ? "today switchResourceButton filterEvButton changeStatus"
                      : "today",
                  center: "title",
                  right:
                    props.type === "Calendar"
                      ? "dayGridMonth,timeGridWeek,timeGridDay,prev,next"
                      : currentResourceView === "resourceTimeline"
                      ? currentResourceView +
                        "Day," +
                        currentResourceView +
                        "Week," +
                        currentResourceView +
                        "Month,prev,next"
                      : currentResourceView +
                        "Day," +
                        currentResourceView +
                        "Week,prev,next",
                }
          }
          resourceLabelDidMount={function (info) {
            const res = info.resource._resource;
            let button = document.createElement("BUTTON");
            button.className = "agenda-resource-button";
            let icon = document.createElement("span");
            icon.className = "pi pi-chart-line";
            button.appendChild(icon);
            button.onclick = () => sendData(res);
            button.style.cursor = "pointer";
            info.el
              .querySelector(".fc-datagrid-cell-main")
              ?.appendChild(button);
          }}
          businessHours={true}
          initialDate={props.disableBeforeDate}
          initialView={
            props.type === "resourceTimeline"
              ? currentResourceView + "Day"
              : undefined
          }
          height={585}
          resourceOrder={"title"}
          resources={resourcePage}
          events={props.events}
          validRange={{
            start: props.disableBeforeDate,
          }}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: false,
          }}
          slotDuration="00:30:00"
          scrollTime="08:00:00"
          resourceAreaWidth="15%"
          dateClick={props.dateClick}
          eventClick={props.eventClick}
          ref={calendarRef}
          dayMinWidth={150}
          editable={true}
          droppable={true}
          filterResourcesWithEvents={filter}
          eventClassNames={props.setEventClassNames}
          eventDrop={(info) => {
            //alert("eventDrop");
            eventDrop(info.event);
          }}
          eventDragStop={eventDragStop}
          eventReceive={(info) => {
            //alert("eventReceive");
            dropNewEvent(info);
          }}
          resourceAreaHeaderContent={areaHeaderContent}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <div>
            {props.colorLegendItems ? (
              <ColorLegend items={props.colorLegendItems} />
            ) : null}
          </div>

          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
          />

          <Button
            onClick={() => resourceFilter()}
            icon="pi bi-funnel"
            tooltipOptions={{ position: "left" }}
            tooltip={i18next.t("Filtra", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            style={{ marginRight: "20px" }}
          />
        </div>
      </div>

      <div>
        <DialogResourceInfo
          vis={visSource}
          setVis={setVisSource}
          id={idRes}
          name={nameRes}
          chartData={chartData}
          datatRes={datatRes}
        />
      </div>

      <div>
        <DialogMultiResource
          vis={vis}
          setVis={setVis}
          selectedResource={selectedResource}
          setSelectedResource={setSelectedResource}
          search={search}
          resource={resource}
          datatResFilter={datatResFilter}
          feel={feel}
        />
      </div>
    </div>
  );
}

export default SHFullCalendar;
