import React from "react";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { ROUTE } from "../utils/Constants";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";

function SessionExpired () {    
    const navigate = useNavigate();

    const navigaLogin = () => {
        navigate(ROUTE.LOGIN)
    }
   
    return (
        <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/images/LCSSfondo.png"})`, width: "100%", height: "99vh"}}>        
            <hr style={{marginTop:"1rem"}}/>
            <h2 style={{marginLeft:"1rem"}}>{i18next.t("SessionExpired", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</h2>
            <hr/>
            <p style={{marginLeft:"1rem"}}>{i18next.t("SessionExpired_Click", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}            
            <a style={{cursor:"pointer", color:"blue"}} onClick={navigaLogin} >{i18next.t("SessionExpired_GoToLoginLink", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</a>  
            {i18next.t("SessionExpired_ToLoginAgain", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
            </p>     
            <hr/>  
        </div>
    );
}

export default SessionExpired;