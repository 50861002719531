import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { APPOINTMENT_WF_STATE } from "../services/appointment.service";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { calculateAppointmentEndDate, getDateAndHour } from "../utils/Common";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { COMMON_TRANSLATION_FILE, CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import { DataTable, DataTableExpandedRows, DataTableValueArray } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { EventExtendedProps } from "../pages/PageWorkPlan";
import api from "../api";
import AppenderActivitiesService, { AppenderActivity } from "../services/appender.activities.service";
import AppointmentProperty from "./AppointmentProperty";
import i18next from "i18next";
import PanelAppenderDeclarations, { DETAILS_POSITION } from "./PanelAppenderDeclarations";

export interface AppointmentDetailProps {
  eventDetails: EventExtendedProps;
  culture: string;
  monIntegration: boolean;
}

const AppointmentDetail = (props: AppointmentDetailProps) => {
  const [appenderList, setAppenderList] = useState<AppenderActivity[]>([]);
  const [appenderExpandedRows, setAppenderExpandedRows] = useState<
    DataTableExpandedRows | DataTableValueArray | undefined
  >(undefined);
  const [actionConfigFormVisible, setActionConfigFormVisible] = useState<boolean>(false);
  const [actioConfigFormMaximized, setActioConfigFormMaximized] = useState<boolean>(false);

  useEffect(() => {
    if (props.eventDetails?.idAppuntamento) {
      const appenderActivitiesService: AppenderActivitiesService = new AppenderActivitiesService(
        api,
        Number(props.eventDetails.idAppuntamento)
      );
      appenderActivitiesService.getAppenderActivities().then((result) => {
        if (result.success) {
          result.data.map((a: AppenderActivity) => (a.timestamp = new Date(a.timestamp)));
          setAppenderList(result.data);
        }
      });
    }
  }, [props.eventDetails]);

  const allowExpansion = (rowData: any) => {
    return true;
  };

  const getContent = (data: AppenderActivity) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: /* sanitizeHtml( */ data.appointmentOutputJson.content /* ) */ }}></div>
    );
  };

  const rowExpansionTemplate = (data: AppenderActivity) => {
    return (
      <div>
        <p>
          <b>{i18next.t("Appender_To", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}:</b>
          {data.appointmentOutputJson.to.map((x) => " " + x)}
        </p>
        {data.appointmentOutputJson.cc && data.appointmentOutputJson.cc.length > 0 ? (
          <p>
            <b>Cc:</b>
            {data.appointmentOutputJson.cc.map((x) => " " + x)}
          </p>
        ) : (
          ""
        )}
        {data.appointmentOutputJson.bcc && data.appointmentOutputJson.bcc.length > 0 ? (
          <p>
            <b>Bcc:</b>
            {data.appointmentOutputJson.bcc.map((x) => " " + x)}
          </p>
        ) : (
          ""
        )}
        <p>
          <b>
            {i18next.t("Appender_Subject", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}: {""}
          </b>
          {data.appointmentOutputJson.subject}
        </p>
        <p>
          <b>{i18next.t("Appender_Content", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}:</b>
          {getContent(data)}
        </p>
      </div>
    );
  };

  const checkOverlappedDeliveries = () => {
    if (props.eventDetails.risultatoChecks?.sovrapposizioneListaEventi.length === 0) return false;
    let appDifferentFromThis = 0;
    const overlappedAppList = props.eventDetails.risultatoChecks?.sovrapposizioneListaEventi ?? [];
    for (let i = 0; i < overlappedAppList.length; i++) {
      if (props.eventDetails.idAppuntamento && props.eventDetails.idAppuntamento != overlappedAppList[i].idAppuntamento)
        appDifferentFromThis++;
    }
    return appDifferentFromThis > 0;
  };

  const showOverlappedDeliveries = () => {
    return (
      <table style={{ width: "100%", color: "grey", border: "1px solid" }}>
        <thead>
          <tr>
            <th>{i18next.t("APPUNTAMENTO", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</th>
            <th>{i18next.t("Inizio", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</th>
            <th>{i18next.t("Fine", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</th>
          </tr>
        </thead>
        <tbody>
          {props.eventDetails.risultatoChecks?.sovrapposizioneListaEventi.map((s) => {
            return (
              <tr>
                <td>{s.denominazione}</td>
                <td>{getDateAndHour(s.inizio, props.culture)}</td>
                <td>
                  {getDateAndHour(
                    calculateAppointmentEndDate(new Date(s.inizio), s.durata.toString()).toISOString(),
                    props.culture
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const showMissingSpecs = () => {
    return (
      <table style={{ width: "100%", color: "grey", marginLeft: "1rem" }}>
        <tbody>
          {props.eventDetails.risultatoChecks?.idoneitaListaSpecs?.map((s) => {
            return (
              <tr>
                <td>{s.denominazione ?? "[" + s.idSpecializzazione + "]"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const ActionsConfigurationFormDefinition = () => {
    return (
      <Dialog
        className="actions-config-dialog"
        header={i18next.t("Appenders", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
        visible={actionConfigFormVisible}
        onHide={() => setActionConfigFormVisible(false)}
        maximizable
        maximized={actioConfigFormMaximized}
        onMaximize={() => setActioConfigFormMaximized(!actioConfigFormMaximized)}
        footer={
          <div>
            <Button
              label={i18next.t("Back", {
                ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
              })}
              onClick={() => setActionConfigFormVisible(false)}
            />
          </div>
        }
      >
        <PanelAppenderDeclarations
          culture={props.culture}
          monIntegration={props.monIntegration}
          detailsPosition={actioConfigFormMaximized ? DETAILS_POSITION.RIGHT : DETAILS_POSITION.DOWN}
        />
      </Dialog>
    );
  };
  // Azioni
  const getAccordionHeader = (labelCode: string, badgeCounterValue: number) => {
    if (labelCode === "Appender_ExecutedList") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "10px",
              alignItems: "center",
            }}
          >
            {i18next.t(labelCode, { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
            {
              <i className="bi bi-megaphone-fill p-overlay-badge" style={{ fontSize: "1.5rem" }}>
                <Badge value={badgeCounterValue} size="normal"></Badge>
              </i>
            }
          </div>
          <Button
            icon="pi pi-cog"
            tooltip={i18next.t("Appender_Settings", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            onClick={() => setActionConfigFormVisible(true) /* navigate(ROUTE.ACTIONS) */}
          ></Button>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "10px",
          alignItems: "center",
        }}
      >
        {i18next.t(labelCode, { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
        {
          <i className="bi bi-ban-fill p-overlay-badge" style={{ fontSize: "1.5rem" }}>
            <Badge value={badgeCounterValue} size="normal"></Badge>
          </i>
        }
      </div>
    );
  };

  const getViolationCounter = () => {
    let counter: number = 0;
    if (props.eventDetails.risultatoChecks?.idoneitaViolata) counter = counter + 1;
    if (
      props.eventDetails.risultatoChecks?.sovrapposizioneListaEventi &&
      props.eventDetails.risultatoChecks?.sovrapposizioneListaEventi.length > 0
    )
      counter = counter + 1;
    return counter;
  };

  const getAppenderType = (rowData: AppenderActivity): string => {
    if (rowData.appointmentOutputJson.to.length > 0)
      return i18next.t("Appender_Type_Email", {
        ns: CUSTOM_PRJ_TRANSLATION_FILE,
      });
    else
      return i18next.t("Appender_Type_Other", {
        ns: CUSTOM_PRJ_TRANSLATION_FILE,
      });
  };

  return (
    <div className="event-detail-container">
      {ActionsConfigurationFormDefinition()}
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <Card>
              <td>
                <AppointmentProperty label="Cliente" content={props.eventDetails.cliente} />
              </td>
              <td>
                <AppointmentProperty label="Inizio" content={props.eventDetails.inizio?.toLocaleString()} />
                {props.eventDetails.luogoAppuntamento ? (
                  <AppointmentProperty
                    label="LuogoAppuntamento"
                    content={i18next.t(props.eventDetails.luogoAppuntamento ?? "", {
                      ns: CUSTOM_PRJ_TRANSLATION_FILE,
                    })}
                  />
                ) : (
                  ""
                )}
              </td>
              <td>
                <AppointmentProperty label="Durata" content={props.eventDetails.durata} />

                {props.eventDetails.luogoAppuntamento !== "REMOTO" &&
                props.eventDetails.indirizzoPianificato !== null ? (
                  <AppointmentProperty
                    label="Indirizzo"
                    content={`${props.eventDetails.indirizzoPianificato} ${props.eventDetails.cittaPianificata} ${props.eventDetails.provinciaPianificata} `}
                  />
                ) : null}
              </td>
            </Card>
          </tr>
        </tbody>
      </table>

      <Accordion activeIndex={0} className="event-detail-accordion">
        <AccordionTab header={i18next.t("Info", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}>
          {props.eventDetails.specializzazioni && props.eventDetails.specializzazioni.length > 0 ? (
            <AppointmentProperty
              label={i18next.t("SpecializzazioniRichieste", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
              content={props.eventDetails.specializzazioni.map((s) => {
                return <Chip label={s} icon={<i className="bi bi-star-fill" style={{ color: "gold" }}></i>} />;
              })}
            />
          ) : null}

          {props.eventDetails.descrizione !== null && props.eventDetails.descrizione !== "" ? (
            <AppointmentProperty label="Descrizione" content={props.eventDetails.descrizione} />
          ) : null}

          {props.eventDetails.note !== null && props.eventDetails.note !== "" ? (
            <AppointmentProperty label="Note" content={props.eventDetails.note} />
          ) : null}
        </AccordionTab>
        <AccordionTab header={getAccordionHeader("Violazioni", getViolationCounter())}>
          {props.eventDetails.risultatoChecks?.idoneitaViolata ? (
            <div style={{ marginBottom: "1rem" }}>
              <AppointmentProperty
                label="Idoneita"
                content={i18next.t("IdoneitaViolata", {
                  ns: CUSTOM_PRJ_TRANSLATION_FILE,
                })}
              />
              {showMissingSpecs()}
            </div>
          ) : (
            ""
          )}
          {props.eventDetails.risultatoChecks?.sovrapposizioneViolata && checkOverlappedDeliveries() ? (
            <div style={{ marginBottom: "1rem" }}>
              <AppointmentProperty
                label="Sovrapposizione"
                content={
                  i18next.t("SovrapposizioneViolata", {
                    ns: CUSTOM_PRJ_TRANSLATION_FILE,
                  }) + props.eventDetails.cliente
                }
              />
              {showOverlappedDeliveries()}
            </div>
          ) : (
            ""
          )}
          {!props.eventDetails.risultatoChecks?.idoneitaViolata &&
          (!props.eventDetails.risultatoChecks?.sovrapposizioneViolata || !checkOverlappedDeliveries()) ? (
            <label className="labelAppointment">
              {i18next.t("NessunaViolazione", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              })}
            </label>
          ) : (
            ""
          )}
        </AccordionTab>
        {props.monIntegration ? (
          <AccordionTab header={getAccordionHeader("Appender_ExecutedList", appenderList.length)}>
            <DataTable
              value={appenderList}
              scrollable
              expandedRows={appenderExpandedRows}
              onRowToggle={(e) => setAppenderExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="id"
            >
              <Column expander={allowExpansion} style={{ width: "5rem" }} />
              <Column
                field="appenderId"
                sortable
                body={(rowData: AppenderActivity) => {
                  return getAppenderType(rowData);
                }}
                header={i18next.t("Appender_Type", {
                  ns: CUSTOM_PRJ_TRANSLATION_FILE,
                })}
              ></Column>
              <Column
                field="reason"
                sortable
                header={i18next.t("Appender_Reason", {
                  ns: CUSTOM_PRJ_TRANSLATION_FILE,
                })}
              ></Column>
              <Column
                field="severity"
                sortable
                header={i18next.t("Appender_Severity", {
                  ns: CUSTOM_PRJ_TRANSLATION_FILE,
                })}
              ></Column>
              <Column
                field="timestamp"
                sortable
                dataType="Date"
                body={(rowData: AppenderActivity) => {
                  return rowData.timestamp.toLocaleString();
                }}
                header={i18next.t("Appender_Data", {
                  ns: CUSTOM_PRJ_TRANSLATION_FILE,
                })}
              ></Column>
            </DataTable>
          </AccordionTab>
        ) : (
          ""
        )}
      </Accordion>
    </div>
  );
};

export default AppointmentDetail;
