import { ConfigurationServiceSourceType, EditableDataService,
    EditableDataServiceResponse, EntityField, IConfigurationService, ICustomService,
    IDataServiceResponse } from "@lcs/frontend";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";
import AppointmentCustomService from "./appointment.custom.service";
import AppointmentConfigurationService from "./appointment.configuration.service";


class EmployeeAbsenceService implements EditableDataService {

    readonly axios: AxiosInstance;
    readonly employeeId: number;
    readonly configurationService : IConfigurationService;
    readonly customService: ICustomService;

    
    constructor(axios: AxiosInstance, employeeId: number) {
        this.axios = axios;
        this.employeeId = employeeId;
        this.configurationService = new AppointmentConfigurationService({
            entityDefinitionSource: ConfigurationServiceSourceType.axios,
            axios: this.axios,
            entityDefinitionJsonFilePath: null,
            entityRoleJsonFilePath: null
          });
        this.customService = new AppointmentCustomService(this.axios);
    }

    getData(): Promise<IDataServiceResponse> {
        let config: AxiosRequestConfig = {
            params: {
                idDipendente: this.employeeId
            }
        };
        return this.axios
        .get((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/assenza/filterByDipendente", config)
        .then((result: AxiosResponse<any>) => {
            let response = {
                data: result.data.data,
                totals: {}
            }
            return response;
        });
    }
    getReferenceData(path: string): Promise<object[]> {
        let config: AxiosRequestConfig = {
        };
        return this.axios
        .get((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/dipendente/" + this.employeeId, config)
        .then((result: AxiosResponse<any>) => {
            let response = [
                    result.data.data
                ];
            return response;
        });
    }

    addObj(obj: object): Promise<EditableDataServiceResponse> {
        return this.axios
          .post((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/assenza", obj)
          .then((result : AxiosResponse<any>) => {
            return result.data;
        });
    }
    updateObj(obj: any): Promise<EditableDataServiceResponse> {
        const params = new URLSearchParams([['itemId', obj.id]]);
        return this.axios
          .put((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/assenza", obj, { params })
          .then((result : AxiosResponse<any>) => result.data);
    }
    deleteObj(obj: any): Promise<EditableDataServiceResponse> {
        const params = new URLSearchParams([['itemId', obj.id]]);
        return this.axios
            .delete((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/assenza", {params})
            .then((result : AxiosResponse<any>) => result.data);
    }
    getConfiguration(): Promise<EntityField[]> {
        return this.configurationService.getDefinitions("assenza")
        .then( (result: EntityField[]) => {
            result.forEach((ef: EntityField) => {
                if (ef.name === "idDipendente") {
                    ef.hidden = true;
                    ef.readonly = true;
                    ef.defaultValue = this.employeeId.toString();
                }
            })
            return result;
        });
    }
    getDocument(documentCode: string): Promise<string> {
        throw new Error("Method not implemented.");
    }
    
}

export default EmployeeAbsenceService;