import { ICustomServiceResponse } from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";
import { LinkedResource } from "./common.types";
import { BE_PREFIX } from "../utils/Constants";


export interface WorkingTime{     
    id: number;       
    denominazione: string;
    inizioTurno: string;
    inizioPranzo: string;
    finePranzo: string;
    fineTurno: string;          
}

export interface WorkingTimeServiceResponse extends ICustomServiceResponse {
    data: WorkingTime;
}

class WorkingTimeService {
    readonly axios : AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    getAll = () : Promise<WorkingTimeServiceResponse> => {
        return this.axios
            .get((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/orarioGiornaliero/")
            .then((result : AxiosResponse<WorkingTimeServiceResponse>) => result.data);
    }

    getById = (workingTimeId: number) : Promise<WorkingTimeServiceResponse> => {
        return this.axios
            .get((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + "/api/data/orarioGiornaliero/" + workingTimeId)
            .then((result : AxiosResponse<WorkingTimeServiceResponse>) => result.data);
    }    

}

export default WorkingTimeService;