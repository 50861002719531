import { AxiosInstance, AxiosResponse } from "axios";
import { BE_PREFIX } from "../utils/Constants";
import {
  EditableDataService,
  EditableDataServiceResponse,
  EntityField,
  ICustomServiceResponse,
  IDataServiceResponse,
} from "@lcs/frontend";
import { log } from "console";

export enum adSeverity {
  ERROR = "ERROR",
  WARNING = "WARN",
  INFO = "INFO",
  ALARM = "ALARM",
}

export enum adActivationType {
  EVENT = "EVENT",
  TIME = "TIME", //,
  //BOTH = "BOTH"
}

export enum adAType {
  EMAIL = "EMAIL",
  LOG = "LOG",
}

export enum adWhatStatus {
  CLOSED = "CLOSED",
  PLANNED = "PLANNED",
}

export enum adAppenderConditionId {
  CHECK_RESEND = "checkReSend",
  CHECK_CUSTOMER = "checkCustomer",
  ALWAYS_TRUE = "alwaysTrue",
  CHECK_QUESTION = "checkQuestion"
}

export enum adAppenderConditionParam {
  TIMEOUT = "timeout",
  CUSTOMER_NAME = "customerName",
  HOW_MANY = "howMany",
  QUESTION = "question"
}

export interface AppenderDeclaration {
  appenderId: string;
  //workflowName: string, lo mette automaticamente il servizio, relativo all'azienda
  activationType: adActivationType;
  type: adAType;
  status: adWhatStatus;
  severities: { name: adSeverity; value: boolean }[];
  conditions: {
    id: adAppenderConditionId;
    params: { name: adAppenderConditionParam; value: number|string}[];
  }[];
}

export interface EmailAppenderDeclaration extends AppenderDeclaration {
  to: string;
  cc?: string;
  bcc?: string;
  subject: string;
  content: string;
}

export interface LogAppenderDeclaration extends AppenderDeclaration {
  text: string;
  severity: adSeverity;
}

export interface AppenderDeclarationsServiceResponse
  extends ICustomServiceResponse {
  data: AppenderDeclaration[];
}

class AppenderDeclarationsService implements EditableDataService {
  readonly axios: AxiosInstance;
  APPOINTMENT_BASE_PATH: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios; // con base path sull'AppointmentService
    this.APPOINTMENT_BASE_PATH =
      (process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT;
  }
  getData(): Promise<IDataServiceResponse> {
    return this.getAppenderDeclarations().then(
      (resp: AppenderDeclarationsServiceResponse) => {
        if (resp.success) {
          let response = {
            data: resp.data,
            totals: {},
          };
          return response;
        } else {
          let response = {
            data: [],
            totals: {},
          };
          return response;
        }
      }
    );
  }

  getReferenceData(path: string): Promise<object[]> {
    throw new Error("Method not implemented.");
  }

  convertAppenderDeclForAddObj(appender: any): object {
    var toList: string[] = appender.to.split(", ");
    toList = toList.map((m) => "'" + m + "'");
    if (appender.cc && appender.cc.length) {
      var ccList: string[] = appender.cc?.split(", ");
      ccList = ccList?.map((m) => "'" + m + "'");
    } else {
      ccList = [];
    }
    if (appender.bcc && appender.bcc.length) {
      var bccList: string[] = appender.bcc?.split(", ");
      bccList = bccList?.map((m) => "'" + m + "'");
    } else {
      bccList = [];
    }
    if (appender.type === adAType.EMAIL) {
      return {
        //from: "noreply@lc-sw.com",
        appenderId: appender.appenderId,
        activationType: appender.activationType,
        toList: toList,
        ccList: ccList,
        bccList: bccList,
        subject: appender.subject,
        text: appender.content,
        whatName: appender.status,
        severities: appender.severities,
        conditions: appender.conditions,
      };
    }
    return {};
  }

  addObj(obj: object): Promise<EditableDataServiceResponse> {
    const body = this.convertAppenderDeclForAddObj(obj);
    return this.axios
      .post<EditableDataServiceResponse>(
        this.APPOINTMENT_BASE_PATH + "/api/data/appender/appendMailConfig",
        body
      )
      .then((result) => result.data);
  }
  updateObj(obj: object): Promise<EditableDataServiceResponse> {
    throw new Error("Method not implemented.");
  }

  deleteObj(obj: object): Promise<EditableDataServiceResponse> {
    throw new Error("Method not implemented.");
  }

  getConfiguration(): Promise<EntityField[]> {
    return this.axios
      .get<EntityField[]>(
        this.APPOINTMENT_BASE_PATH + "/api/configuration/entityField/appender"
      )
      .then((result) => result.data);
  }
  getDocument(documentCode: string): Promise<string> {
    throw new Error("Method not implemented.");
  }

  getAppenderDeclarations =
    (): Promise<AppenderDeclarationsServiceResponse> => {
      return this.axios
        .get(this.APPOINTMENT_BASE_PATH + "/api/data/appender/")
        .then(
          (result: AxiosResponse<AppenderDeclarationsServiceResponse>) =>
            result.data
        );
    };
  removeAppender = (obj: object): Promise<any> => {
    return this.axios
      .delete(this.APPOINTMENT_BASE_PATH + "/api/common/appender/", {
        data: obj,
      })
      .then((result) => {
        return result.data;
      });
  };
}

export default AppenderDeclarationsService;
