import React, { useRef, useState } from "react";
import PanelAppointments from "../components/PanelAppointments";
import ButtonPlanning from "../components/ButtonPlanning";
import ButtonPlanningSettings from "../components/ButtonPlanningSettings";
import PanelAppointmentsDetails from "../components/PanelAppointmentsDetails";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import i18next from "i18next";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import AppointmentService from "../services/appointment.service";
import api from "../api";
import { Toast } from "primereact/toast";
import OverlayPanelWorkPlan from "../components/OverlayPanelWorkPlan";
import DialogMultiConfirm from "../components/DialogMultiConfirm";
import { Dialog } from "primereact/dialog";
import PanelAppointmentClosed from "../components/PanelAppointmentClosed";

export interface PageAppointmentsProps {
  culture: string;
  monIntegration: boolean;
}

function PageAppointments(props: PageAppointmentsProps) {
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);
  const [checked, setChecked] = useState<any>(false);
  const [visRiepilogo, setVisRiepilogo] = useState<boolean>(false);
  const appointmentService = new AppointmentService(api);

  const myToast = useRef<any>();
  const [spinnerVisible, setSpinnerVisible] = useState<boolean>(false);
  const [visChangeStatus, setVisChangeStatus] = useState<boolean>(false);
  const [processedAppVisible, setProcessedAppVisible] = useState<boolean>(false);
  const [formMaximized, setFormMaximized] = useState<boolean>(false);
  const [processedOrdersMaximized, setProcessedOrdersMaximized] = useState<boolean>(false);
  // const [visReasonsStatus, setVisReasonsStatus] = useState<boolean>(false);
  // const [dataReasons, setDataReasons] = useState<any>();

  const updateDataAfterPlanning = () => {
    setNeedUpdate(true);
  };

  const resetRegole = () => {
    setSpinnerVisible(true);
    appointmentService.resetRegole().then((clearResult) => {
      setSpinnerVisible(false);
      if (clearResult.success) {
        myToast.current.show({
          severity: "warning",
          summary: i18next.t("ResetRegoleSucc", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
          detail: clearResult.reason,
        });
      } else {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("ResetRegoleFail", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
          detail: clearResult.reason,
        });
      }
    });
  };

  const regoleMenu = useRef<any>(null);
  const regoleMenuItems = [
    {
      label: i18next.t("RegoleDefault", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      command: () => {
        setChecked(!checked);
      },
      icon: checked ? "pi pi-check" : null,
    },
    {
      separator: true,
    },
    {
      label: i18next.t("ResetRegole", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      command: () => {
        resetRegole();
      },
    },
  ];

  return (
    <div>
      <Toast ref={myToast} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ margin: "5px" }}>
          <ButtonPlanning check={checked} updateData={updateDataAfterPlanning} setRiepilogo={setVisRiepilogo} />
        </div>
        <div>
          <Button
            style={{ margin: "5px" }}
            icon="pi pi-history"
            tooltip={i18next.t("RiepilogoPianificazione", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            onClick={() => setVisRiepilogo(true)}
          />
          <PanelAppointmentsDetails vis={visRiepilogo} setVis={setVisRiepilogo} />
        </div>
        <div style={{ margin: "5px" }}>
          <ButtonPlanningSettings culture={props.culture} />
        </div>
        <div>
          <TieredMenu ref={regoleMenu} popup model={regoleMenuItems} breakpoint="767px" />
          <Button
            style={{ margin: "5px" }}
            icon="pi pi-book"
            tooltip={i18next.t("REGOLE PIANIFICAZIONE", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            onClick={(e) => regoleMenu?.current?.toggle(e)}
          />
        </div>
        <div>
          <Button
            style={{ margin: "5px" }}
            icon="pi pi-check"
            tooltip={i18next.t("StatoAppuntamenti", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            onClick={() => setVisChangeStatus(true)}
          />
          <DialogMultiConfirm vis={visChangeStatus} setVis={setVisChangeStatus} setNeedUpdate={setNeedUpdate} />
        </div>
        <div style={{ margin: "5px" }}>
          <Button
            icon="pi pi-history"
            tooltip={i18next.t("PROCESSED_ORDERS_VIEW", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            onClick={() => setProcessedAppVisible(true)}
            label={i18next.t("PROCESSED_ORDERS_VIEW", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
          />

          <Dialog
            header={i18next.t("PROCESSED_ORDERS_VIEW", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            visible={processedAppVisible}
            onHide={() => setProcessedAppVisible(false)}
            resizable={true}
            maximizable
            maximized={formMaximized}
            onMaximize={() => setFormMaximized(!formMaximized)}
            style={{ width: "80vw" }}
            baseZIndex={1}
          >
            <PanelAppointmentClosed culture={props.culture} />
          </Dialog>
        </div>
        <div></div>
      </div>
      <div style={{ margin: "5px" }}>
        <PanelAppointments
          needUpdate={needUpdate}
          updateCompleted={() => setNeedUpdate(false)}
          culture={props.culture}
          monIntegration={props.monIntegration}
        />
      </div>
      <OverlayPanelWorkPlan visible={spinnerVisible} />
    </div>
  );
}

export default PageAppointments;
