import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { Fragment, useRef, useState } from "react";
import AppointmentClosure from "./AppointmentClosure";
import i18next from "i18next";
import { COMMON_TRANSLATION_FILE, CUSTOM_PRJ_TRANSLATION_FILE, ICustomServiceResponse } from "@lcs/frontend";
import AppointmentService, { APPOINTMENT_STATE, AppointmentServiceSingleResponse, CloseAppointmentRequest } from "../services/appointment.service";
import api from "../api";
import { EventExtendedProps } from "../pages/PageWorkPlan";

export interface DialogAppointmentClosureProps {
    eventDetails: EventExtendedProps;
    onAction: (refresh: boolean) => void;
    visible: boolean;
}

function DialogAppointmentClosure (props: DialogAppointmentClosureProps) {

    const myToast = useRef<any>();
    const [closingAppointment, setClosingAppointment] = useState<boolean>(false);
    const [appointmentClosureData, setAppointmentClosureData] = useState<{ state: string, note: string, descrizione:string }>({ state: "", note: "", descrizione:"" })

    const appointmentService = new AppointmentService(api);


    const closeAppointment = () => {
        setClosingAppointment(true)
        if (!props.eventDetails || !props.eventDetails.idAppuntamento) {
            myToast.current.show({
                severity: 'error',
                summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                detail: i18next.t("EmptyAppointment", { ns: CUSTOM_PRJ_TRANSLATION_FILE })
            });
            setClosingAppointment(false);
            return;
        }
        appointmentService.getById(props.eventDetails.idAppuntamento).then((res: AppointmentServiceSingleResponse) => {
            if (!res.success) {
                myToast.current.show({
                    severity: 'error',
                    summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                    detail: i18next.t(res.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                });
                setClosingAppointment(false);
                return;
            }
            let appCReq: CloseAppointmentRequest = {
                appointmentId: res.data.id,
                status: APPOINTMENT_STATE[appointmentClosureData.state as keyof typeof APPOINTMENT_STATE],
                note: appointmentClosureData.note, 
                descrizione:appointmentClosureData.descrizione
            }
            appointmentService.close(appCReq).then((result: ICustomServiceResponse) => {
                if (result.success) {
                    myToast.current.show({
                        severity: 'success',
                        summary: i18next.t('Success', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                        detail: i18next.t(result.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                    });
                    props.onAction(true);
                    setAppointmentClosureData({ state: "", note: "", descrizione:"" });
                    setClosingAppointment(false);
                } else {
                    myToast.current.show({
                        severity: 'error',
                        summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                        detail: i18next.t(result.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                    });
                    setClosingAppointment(false);
                }
            });
        });

    }

    const updateAppointmentClosure = (obj:any) => {
        let _appointmentClosureData = {...appointmentClosureData, ...obj};
        setAppointmentClosureData(_appointmentClosureData);
    }
    return (
        <Fragment>
        <Toast ref={myToast} />
        <Dialog
            header={i18next.t("AppointmentClosure", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
            visible={props.visible}
            onHide={() => props.onAction(false)}
            footer={<div>
                <Button
                    label={i18next.t("Cancel", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })}
                    onClick={() => props.onAction(false)} className="panel-cancel-custom-button"
                />
                <Button
                    label={i18next.t("OK", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })}
                    onClick={closeAppointment} 
                    loading={closingAppointment}
                />
            </div>
            }
            className="AppointmentClosureDialog"
        >
            <AppointmentClosure eventDetails={props.eventDetails}
                setNote={(newNote: string) => updateAppointmentClosure({ note: newNote})}
                setState={(newState: string) => updateAppointmentClosure({ state: newState})}
                setDescrizione={(newDescrizione: string) => updateAppointmentClosure({ descrizione: newDescrizione})}
            />
        </Dialog>
        </Fragment>

    );

}

export default DialogAppointmentClosure;