import React, { useState, useEffect } from "react";
import PerformanceEmployees from "../components/PerformanceEmployees/PerformanceEmployees";
import PanelActionDashboard from "../components/PanelActionDashboard/PanelActionDashboard";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import "animate.css"; // Importa animate.css

export interface PageReportsProps {
  culture: string;
  monIntegration: boolean;
}

function PageReports(props: PageReportsProps) {
  const [stateView, setStateView] = useState<string>(
    props.monIntegration ? "orderOpen" : "employees"
  );
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
  const [isContentVisible, setIsContentVisible] = useState<boolean>(false);

  useEffect(() => {
    const sidebarTimer = setTimeout(() => {
      setIsSidebarVisible(true);
    }, 30); // Ritardo di 1 secondo

    const contentTimer = setTimeout(() => {
      setIsContentVisible(true);
    }, 30); // Ritardo di 1.5 secondi

    return () => {
      clearTimeout(sidebarTimer);
      clearTimeout(contentTimer);
    };
  }, []);

  const itemsMenu = props.monIntegration
    ? [
        {
          key: "orderOpen",
          label: i18next.t("ActivityDashboard", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
          icon: "pi pi-desktop",
          command: () => {
            setStateView("orderOpen");
          },
        },
        {
          key: "orderClosed",
          label: i18next.t("ActivityHistory", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
          icon: "pi pi-chart-line",
          command: () => {
            setStateView("orderClosed");
          },
        },
        {
          key: "employees",
          label: i18next.t("Performance", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
          icon: "pi pi-user",
          command: () => {
            setStateView("employees");
          },
        },
      ]
    : [
        {
          key: "employees",
          label: i18next.t("Performance", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
          icon: "pi pi-user",
          command: () => {
            setStateView("employees");
          },
        },
      ];

  return (
    <>
      <div className="container_dashboard">
        <div
          className={`sidebar_dashboard ${
            isSidebarVisible
              ? "visible animate__animated animate__fadeInLeftBig"
              : ""
          }`}
        >
          <ul className="menu_sidebar">
            {itemsMenu.map((item) => (
              <li
                key={item.key}
                className={`menu_item ${
                  stateView === item.key ? "active" : ""
                }`}
                onClick={item.command}
              >
                <i className={item.icon}></i>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
        <div
          className={`scrollable_content_dashboard ${
            isContentVisible
              ? "visible animate__animated animate__fadeInRightBig"
              : ""
          }`}
        >
          {stateView === "employees" ? (
            <PerformanceEmployees />
          ) : stateView === "orderOpen" ? (
            <PanelActionDashboard
              tab={false}
              culture={props.culture}
              monIntegration={props.monIntegration}
            />
          ) : stateView === "orderClosed" ? (
            <div>
              <PanelActionDashboard
                stateView={"orderClosed"}
                culture={props.culture}
                monIntegration={props.monIntegration}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default PageReports;
