import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Chart } from "primereact/chart";
import { Card } from "primereact/card";
import "primeicons/primeicons.css";
import i18next from "i18next";
import {
  COMMON_TRANSLATION_FILE,
  CUSTOM_PRJ_TRANSLATION_FILE,
} from "@lcs/frontend";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { colorEvent } from "../utils/Constants";

const DialogResourceInfo = (props: {
  vis: boolean;
  setVis: (arg1: boolean) => void;
  id: any;
  name: any;
  chartData: any;
  datatRes?: any;
}) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [andamento, setAndamento] = useState<any>(0);
  const [value, setValue] = useState<any>([]);
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);
  const eventColors = [
    colorEvent.chiusoInterlocutorio,
    colorEvent.chiusoFallimento,
    colorEvent.chiusoSuccesso,
    colorEvent.confermato,
  ];

  useEffect(() => {
    setDialogVisibility(props.vis);
  }, [props.vis]);

  useEffect(() => {
    if (props.datatRes) {
      if (value.length === 0) {
        props.datatRes.data.kpi.map((out: any) =>
          value.push(
            out.chiusiInterlocutorio.percentuale,
            out.chiusiFallimento.percentuale,
            out.chiusiSuccesso.percentuale,
            out.confermati.percentuale
          )
        );
      }

      props.datatRes.data.kpi.map((out: any) => setAndamento(out.andamento));

      props.datatRes.data.kpi.map((out: any) => setAndamento(out.andamento));

      const documentStyle = getComputedStyle(document.documentElement);
      const data = {
        labels: [
          "Chiusi interlocutorio",
          "Chiusi fallimento",
          "Chiusi successo",
          "Confermati",
        ],
        datasets: [
          {
            data: value,
            backgroundColor: eventColors,
            hoverBackgroundColor: eventColors,
          },
        ],
      };
      const options = {
        cutout: "60%",
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
        },
      };

      setChartData(data);
      setChartOptions(options);
    }
  }, [props.datatRes]);

  const hide = () => {
    props.setVis(false);

    if (value.length > 0) {
      setValue([]);
    }
  };

  const getDialogHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "0.5rem",
          alignItems: "center",
        }}
      >
        {i18next.t("Andamento", {
          ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
        }) +
          " - " +
          props.name}
        <div className="andamento-body">
          {andamento === 1 ? (
            <i
              className="bi bi-arrow-up-square-fill"
              style={{
                color: "#259747",
                fontSize: "1.5rem",
                display: "flex",
                justifyContent: "center",
              }}
            ></i>
          ) : andamento === 0 ? (
            <i
              className="pi pi-minus"
              style={{ color: "gray", fontSize: "1.5rem" }}
            ></i>
          ) : (
            <i
              className="bi bi-arrow-down-square-fill"
              style={{
                color: "#B40D0D",
                fontSize: "1.5rem",
                display: "flex",
                justifyContent: "center",
              }}
            ></i>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Dialog
        resizable={false}
        header={getDialogHeader}
        visible={dialogVisibility}
        style={{ minHeight: "25rem", minWidth: "40rem" }}
        onHide={() => hide()}
      >
        <div className="dialog-resource-info">
          {value[0] === 0 &&
          value[1] === 0 &&
          value[2] === 0 &&
          value[3] === 0 ? (
            <div style={{ width: "11vw" }}></div>
          ) : (
            <Chart
              type="doughnut"
              data={chartData}
              options={chartOptions}
              className="chart-w-full"
            />
          )}

          <div className="dialog-resource-spec">
            <Card
              title={i18next.t("Specializzazioni", {
                ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
              })}
            >
              <div className="spec-body">
                {props.datatRes ? (
                  <div>
                    <Tooltip target=".badge-punteggio" />
                    <div className="spec-body-column">
                      {props.datatRes.data.kpi.map((out: any) =>
                        out.specializzazioni.length === 0 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <p>
                              <i
                                className="pi pi-file-excel"
                                style={{ fontSize: "2rem" }}
                              ></i>
                            </p>
                            <p style={{}}>
                              {i18next.t("NessunDato", {
                                ns: [
                                  CUSTOM_PRJ_TRANSLATION_FILE,
                                  COMMON_TRANSLATION_FILE,
                                ],
                              })}
                            </p>
                          </div>
                        ) : (
                          out.specializzazioni.map((spec: any, key: any) => (
                            <div className="spec-body-row">
                              <label>{spec.nome}</label>
                              <i
                                className="badge-punteggio"
                                data-pr-tooltip={i18next.t("Punteggio", {
                                  ns: [
                                    CUSTOM_PRJ_TRANSLATION_FILE,
                                    COMMON_TRANSLATION_FILE,
                                  ],
                                })}
                                data-pr-position="right"
                                style={{
                                  fontSize: "1rem",
                                  cursor: "pointer",
                                  marginLeft: "1vw",
                                }}
                              >
                                <Badge
                                  value={spec.valore}
                                  severity="info"
                                ></Badge>
                              </i>
                            </div>
                          ))
                        )
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </Card>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DialogResourceInfo;
