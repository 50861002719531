import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import i18next from "i18next";
import { COMMON_TRANSLATION_FILE, CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AppointmentService, {
  ConfirmAppointmentListResponse,
  ConfirmAppointmentRequest,
  ConfirmAppointmentResponse,
  APPOINTMENT_WF_STATE,
  AppointmentOut,
  AppointmentServiceResponse,
} from "../services/appointment.service";
import api from "../api";
import { Toast } from "primereact/toast";
import { getDateAndHour, rangeOneMonth } from "../utils/Common";
import { Tag } from "primereact/tag";
import { DEFAULT_TABLE_PAGE_ROWS } from "../utils/Constants";

import { Dropdown } from "primereact/dropdown";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

export interface DialogMultiConfirmProps {
  vis: boolean;
  setVis: (arg1: boolean) => void;
  setNeedUpdate: (arg1: boolean) => void;
  agendaAction?: () => void;
  initialDateRange?: (Date | null)[];
  addDialogComponent?: boolean;
}

enum DIALOG_MODE {
  SEARCH,
  RESULT
}

enum ACTION {
  CONFIRM,
  UNCONFIRM
}

interface SingleResult extends AppointmentOut{
  success: boolean
}

const DialogMultiConfirm = (props: DialogMultiConfirmProps) => {
  
  const appointmentService = new AppointmentService(api);
  const myToast = useRef<any>();

  const [mode, setMode] = useState<DIALOG_MODE>(DIALOG_MODE.SEARCH);
  const [dateRange, setDateRange] = useState<(Date|null)[]>(props.initialDateRange??rangeOneMonth(new Date()));
  const [action, setAction] = useState<ACTION>(ACTION.CONFIRM);
  const actionOptions = [
    {
      value: ACTION.CONFIRM,
      label: i18next.t("Pianificati", { ns: CUSTOM_PRJ_TRANSLATION_FILE})
    }, 
    {
      value: ACTION.UNCONFIRM,
      label: i18next.t("Confermati", { ns: CUSTOM_PRJ_TRANSLATION_FILE})
    }];
  const [completeList, setCompleteList] = useState<AppointmentOut[]>([]);
  const [filteredList, setFilteredList] = useState<AppointmentOut[]>([]);
  const [selectedList, setSelectedList] = useState<AppointmentOut[]>([]);
  const [resultList, setResultList] = useState<SingleResult[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
    
  useEffect(() => {
    if (!props.vis){
      setFilteredList([]);
      setCompleteList([]);
      setSelectedList([]);
      setResultList([]);
      setMode(DIALOG_MODE.SEARCH);
      return;
    }
    setLoading(true);
    appointmentService.getAll()
    .then((res: AppointmentServiceResponse) => {
      if (res.success){
        setCompleteList(res.data);
        getfilteredEntities(res.data);
        setSelectedList([]);
      } else {
        setFilteredList([]);
        setCompleteList([]);
        setSelectedList([]);
      }
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  },[props.vis, action]);

  // TODO BE method to filter deliveries by time period
  const getfilteredEntities = (toBeFilteredList?:AppointmentOut[]) => {      
    if (dateRange[0] === null || dateRange[0] === undefined){
      setFilteredList([]);
      return;
    }
    let _completeList: AppointmentOut[] = [];
    if (toBeFilteredList) {
      _completeList = toBeFilteredList;
    } else {
      _completeList = completeList;
    }
    let sDate: Date = dateRange[0];
    sDate.setHours(0, 0, 0, 0);
    let eDate: Date = (dateRange[1] && dateRange[1] != null)?
      dateRange[1]:
      new Date(dateRange[0]);
    eDate.setHours(23, 59, 0, 0);

    let _filteredList: AppointmentOut[] = [];
    for (let index = 0; index < _completeList.length; index++) {
      const entity = _completeList[index];
      if (
        entity.orarioPianificato && (
        sDate.toISOString() >= entity.orarioPianificato ||
        eDate.toISOString() <= entity.orarioPianificato)
      ) {
        continue;
      }
      switch(action){
        case ACTION.CONFIRM:
          if (entity.wfStatus === APPOINTMENT_WF_STATE.PIANIFICATO)
            _filteredList.push(entity);
          break;
        case ACTION.UNCONFIRM:
          if (entity.wfStatus === APPOINTMENT_WF_STATE.CONFERMATO)
            _filteredList.push(entity);
          break;
      }      
    }
    setFilteredList(_filteredList);
  };

  const accept = () => {
    if (action === ACTION.CONFIRM) {      
      doConfirmAll(filteredList);
    } else {
      doUnconfirmAll(filteredList);
    } 
  };

  const execute = () => {
    setSaving(true);
    if (selectedList.length === 0){
      confirmDialog({
        message: i18next.t("MultiConfirmAcceptMsg", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        }),
        header: i18next.t("MultiConfirmAcceptHeader", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        }),
        icon: "pi pi-exclamation-triangle",
        accept: accept,
        reject: () => setSaving(false)        
      });
      return;
    }

    if (action === ACTION.CONFIRM) {      
      doConfirmAll(selectedList);
    } else {
      doUnconfirmAll(selectedList);
    } 
  };

  const doConfirmAll = (inputList: AppointmentOut[]) => {
    const toBeConfirmedList: ConfirmAppointmentRequest[] = 
      inputList.map((e: AppointmentOut) => {
        const toBeConfirmed: ConfirmAppointmentRequest = {
          appointmentId: e.id,
          idDipendenteStabilito: e.idDipendentePianificato?.id??0,
          orarioStabilito: e.orarioPianificato??""
        }
        return toBeConfirmed;
      });
    appointmentService.confirmAppointmentList(toBeConfirmedList)
      .then((res: ConfirmAppointmentListResponse) => {
      setMode(DIALOG_MODE.RESULT);
      if (res.success) {
          let _results: SingleResult[] = inputList
          .map((entity: AppointmentOut) => {            
            let singleRes: SingleResult = {...entity, 
              success: true}
            return singleRes;
          });
          setResultList(_results);          
          props.setNeedUpdate(true);
          if (props.agendaAction)
            props.agendaAction();
          myToast.current.show({
            severity: "success",
            detail: i18next.t("ConfermaAppuntamenti", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          });
          setSaving(false);
          return;
      }

      if (res.cappWithErrorsResponseList && res.cappWithErrorsResponseList.length > 0) {
        let _results: SingleResult[] = inputList
          .map((entity: AppointmentOut) => {
            const index = res.cappWithErrorsResponseList
              .findIndex((r: ConfirmAppointmentResponse) => r.appointmentId === entity.id)
            let singleRes: SingleResult = {...entity, 
              success: index === -1}
            return singleRes;
          });
        setResultList(_results);
        props.setNeedUpdate(true);
        if (props.agendaAction)
          props.agendaAction();
        setSaving(false);
        return;
      }

      let _results: SingleResult[] = inputList
      .map((entity: AppointmentOut) => {
        let singleRes: SingleResult = {...entity, 
          success: false}
        return singleRes;
      });
      setResultList(_results);
      myToast.current.show({
        severity: "danger",
        detail: i18next.t("ConfermaConsegneFail", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        }),
      });
      setSaving(false);
    })
    .catch(() => {
      myToast.current.show({
        severity: "error",
        summary: i18next.t("ErroreGenerico", { ns: CUSTOM_PRJ_TRANSLATION_FILE })
      });
      setSaving(false);
    });
  };

  const doUnconfirmAll = (inputList: AppointmentOut[]) => {
    const toBeUnconfirmedList: ConfirmAppointmentRequest[] = 
    inputList.map((e: AppointmentOut) => {
      const toBeConfirmed: ConfirmAppointmentRequest = {
        appointmentId: e.id,
        idDipendenteStabilito: e.idDipendenteStabilito?.id??0,
        orarioStabilito: e.orarioStabilito??""
      }
      return toBeConfirmed;
    });
    appointmentService.unconfirmAppointmentList(toBeUnconfirmedList).then((res: any) => {
      setMode(DIALOG_MODE.RESULT);
      if (res.success) {
        let _results: SingleResult[] = inputList
          .map((entity: AppointmentOut) => {            
            let singleRes: SingleResult = {...entity, 
              success: true}
            return singleRes;
          });
        setResultList(_results);
        props.setNeedUpdate(true);
        if (props.agendaAction)
          props.agendaAction();
        myToast.current.show({
          severity: "success",
          detail: i18next.t("UnconfirmConsegne", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
        });
        setSaving(false);
        return;
      }

      if (res.cdelWithErrorsResponseList && res.cdelWithErrorsResponseList.length > 0) {
        let _results: SingleResult[] = inputList
          .map((entity: AppointmentOut) => {
            const index = res.cdelWithErrorsResponseList
              .findIndex((r: ConfirmAppointmentResponse) => r.appointmentId === entity.id)
            let singleRes: SingleResult = {...entity, 
              success: index === -1}
            return singleRes;
          });
          setResultList(_results);
          props.setNeedUpdate(true);
          if (props.agendaAction)
            props.agendaAction();
          setSaving(false);
          return;
      }

      let _results: SingleResult[] = inputList
      .map((entity: AppointmentOut) => {
        let singleRes: SingleResult = {...entity, 
          success: false}
        return singleRes;
      });
      setResultList(_results);
      props.setNeedUpdate(true);
      if (props.agendaAction)
        props.agendaAction();
      myToast.current.show({
        severity: "error",
        detail: i18next.t("UnconfirmConsegneFail", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      });
      setSaving(false);
    })
    .catch(() => {
      myToast.current.show({
        severity: "error",
        summary: i18next.t("ErroreGenerico", { ns: CUSTOM_PRJ_TRANSLATION_FILE })
      });
      setSaving(false);
    });
  };

  const getDialogFooter = () => {
    return (
      <div className="panel-form-buttons">
      <Button
        icon="pi pi-times"
        className="panel-cancel-custom-button p-button p-component"
        label={(mode === DIALOG_MODE.SEARCH)?
          i18next.t("Annulla", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })
          :
          i18next.t("Close", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })
        }
        onClick={() => props.setVis(false)}
        style={{ marginRight: "5px" }}
      />
      {mode === DIALOG_MODE.SEARCH?
      <Button
        icon="pi pi-check"
        label={(action === ACTION.CONFIRM)?
          i18next.t("Conferma", { ns: CUSTOM_PRJ_TRANSLATION_FILE}) 
          : i18next.t("RimuoviConferma", { ns: CUSTOM_PRJ_TRANSLATION_FILE})
        }
        style={{marginRight: "0px !important"}}
        onClick={execute}
        disabled={filteredList.length < 1}
        type="button"   
        loading={saving}     
      />
      : ""}
    </div>
    );
  }  

  const getActionChoicer = () => {
    return (<div className="panel-table-header-container">
      <label>{i18next.t("Appuntamenti da ricercare: ", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</label>
      <Dropdown value={action} className="panel-table-header-dropdown"
        onChange={(e) => setAction(e.value)} options={actionOptions} />
    </div>);
  }

  return (
    <>
      <Toast ref={myToast} />
      {props.addDialogComponent?
       <ConfirmDialog /> 
       : "" //There is already one in parent components
      }
      <Dialog
        header={i18next.t("StatoAppuntamenti", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
        visible={props.vis}
        style={{ width: "70vw", height: "50vw" }}
        onHide={() => props.setVis(false)}
        footer={getDialogFooter()}
        maximizable
      >
        {mode === DIALOG_MODE.SEARCH?
        <>                    
          <div className="panel-table-header">
            {getActionChoicer()}
            <div className="panel-table-header-container">
              <label>{i18next.t("Intervallo", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</label>
              <div>
              <Calendar inputId="inserisci_periodo" value={dateRange}            
                onChange={(e) => setDateRange(e.value??[null,null])}
                selectionMode="range" readOnlyInput            
                dateFormat="dd/mm/yy" style={{minWidth:"100px"}}
              />
              <Button icon="pi pi-search"
                tooltip={i18next.t("Cerca", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                onClick={() => getfilteredEntities()} loading={loading}
              />
              </div>
            </div>
          </div>        
          <div style={{border:"1px solid lightgray"}}>
            <DataTable value={filteredList} showGridlines
            selectionMode="multiple" selection={selectedList} 
            onSelectionChange={(e) => setSelectedList(e.value)}
            dataKey="id" metaKeySelection={false} dragSelection
            paginator rows={DEFAULT_TABLE_PAGE_ROWS}
            size="small" 
            >
              <Column field="denominazione" 
                header={i18next.t("Denominazione", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}></Column>
              <Column field="orarioPianificato"
                header={i18next.t("OrarioPianificato", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                body={(r: AppointmentOut) => r.orarioPianificato?getDateAndHour(r.orarioPianificato, "it-IT"):""}
              ></Column>
              <Column field="wfStatus" 
                header={i18next.t("Stato", { ns: CUSTOM_PRJ_TRANSLATION_FILE })} 
                body={(r: AppointmentOut) => r.wfStatus?i18next.t(r.wfStatus, { ns: CUSTOM_PRJ_TRANSLATION_FILE }):""}
              ></Column>
            </DataTable>
            </div>
        </>
        :
        <>
          <label>
            {action === ACTION.CONFIRM? 
            i18next.t("ConfirmResult", { ns: CUSTOM_PRJ_TRANSLATION_FILE })
            : i18next.t("UnconfirmResult", { ns: CUSTOM_PRJ_TRANSLATION_FILE })
            }
          </label>         
          <div style={{border:"1px solid lightgray"}}>
            <DataTable value={resultList} showGridlines 
            paginator rows={DEFAULT_TABLE_PAGE_ROWS}
            size="small">
              <Column field="denominazione" 
                header={i18next.t("CodiceConsegna", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
              ></Column>
              <Column field="success" align={"center"}
                header={i18next.t("Risultato", { ns: CUSTOM_PRJ_TRANSLATION_FILE })} 
                body={(r: SingleResult) => r.success?
                  <Tag severity="success" 
                    value={i18next.t("ConfermaSuccesso", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                  ></Tag>
                  : 
                  <Tag severity="danger" 
                    value={i18next.t("ConfermaFallimento", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                  ></Tag>
                 } 
              ></Column>
            </DataTable>                         
          </div>
        </>
        }
      </Dialog>
    </>
  );
};

export default DialogMultiConfirm;
