import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import i18next from "i18next";
import {
  COMMON_TRANSLATION_FILE,
  CUSTOM_PRJ_TRANSLATION_FILE,
} from "@lcs/frontend";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { FilterMatchMode } from "primereact/api";

interface dataTable {
  code: number;
  name: String;
  competenza1?: String;
  punteggio1?: number;
  competenza2?: String;
  punteggio2?: number;
  competenza3?: String;
  punteggio3?: number;
  andamento: any;
}

const DialogMultiResource = (props: {
  vis: boolean;
  setVis: (arg1: boolean) => void;
  selectedResource: any;
  setSelectedResource: (arg1: any) => void;
  search: () => void;
  resource: any;
  datatResFilter: any;
  feel: () => void;
}) => {
  const [resourceTable, setResourceTable] = useState<dataTable[]>([]);
  const [selectedResourceFilter, setSelectedResourceFilter] = useState<any[]>(
    []
  );
  const [metaKey, setMetaKey] = useState<boolean>(false);
  const initFilters = {
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    competenza1: { value: null, matchMode: FilterMatchMode.CONTAINS },
    competenza2: { value: null, matchMode: FilterMatchMode.CONTAINS },
    competenza3: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };
  const [filters, setFilters] = useState(initFilters);

  useEffect(() => {
    if (props.datatResFilter) {
      props.datatResFilter.data.kpi.map((data: any) => {
        let dataRes = {
          code: data.idDipendentePianificato,
          name: data.nome + " " + data.cognome,
          competenza1: data.specializzazioni[0]
            ? data.specializzazioni[0].nome
            : "",
          punteggio1: data.specializzazioni[0]
            ? data.specializzazioni[0].valore
            : 0,
          competenza2: data.specializzazioni[1]
            ? data.specializzazioni[1].nome
            : "",
          punteggio2: data.specializzazioni[1]
            ? data.specializzazioni[1].valore
            : 0,
          competenza3: data.specializzazioni[2]
            ? data.specializzazioni[2].nome
            : "",
          punteggio3: data.specializzazioni[2]
            ? data.specializzazioni[2].valore
            : 0,
          andamento: data.andamento,
        };
        resourceTable.push(dataRes);
      });
    }
  }, [props.datatResFilter]);

  //COMPETENZA
  const competenzaItemTemplate = (option: any) => {
    return <span>{option.name}</span>;
  };

  const competenzaTemplate = (rowData: any) => {
    if (rowData.competenza1 !== "") {
      return (
        <div className="table-competenze">
          <i
            className="badge-punteggio"
            data-pr-tooltip={i18next.t("Punteggio", {
              ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
            })}
            data-pr-position="right"
            style={{ fontSize: "1rem", cursor: "pointer", marginLeft: "1vw" }}
          >
            <Badge value={rowData.punteggio1} severity="info"></Badge>
          </i>
          {rowData.competenza1}
        </div>
      );
    }
  };

  const competenza2Template = (rowData: any) => {
    if (rowData.competenza2 !== "") {
      return (
        <div className="table-competenze">
          <i
            className="badge-punteggio"
            data-pr-tooltip={i18next.t("Punteggio", {
              ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
            })}
            data-pr-position="right"
            style={{ fontSize: "1rem", cursor: "pointer", marginLeft: "1vw" }}
          >
            <Badge value={rowData.punteggio2} severity="info"></Badge>
          </i>
          {rowData.competenza2}
        </div>
      );
    }
  };

  const competenza3Template = (rowData: any) => {
    if (rowData.competenza3 !== "") {
      return (
        <div className="table-competenze">
          <i
            className="badge-punteggio"
            data-pr-tooltip={i18next.t("Punteggio", {
              ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
            })}
            data-pr-position="right"
            style={{ fontSize: "1rem", cursor: "pointer", marginLeft: "1vw" }}
          >
            <Badge value={rowData.punteggio3} severity="info"></Badge>
          </i>
          {rowData.competenza3}
        </div>
      );
    }
  };

  const andamentoTemplate = (rowData: any) => {
    if (rowData.andamento === 1) {
      return (
        <i
          className="bi bi-arrow-up-square-fill"
          style={{
            color: "#259747",
            fontSize: "1.5rem",
            display: "flex",
            justifyContent: "center",
          }}
        ></i>
      );
    } else if (rowData.andamento === 0) {
      return (
        <i
          className="pi pi-minus"
          style={{
            color: "gray",
            fontSize: "1.5rem",
            display: "flex",
            justifyContent: "center",
          }}
        ></i>
      );
    }
    return (
      <i
        className="bi bi-arrow-down-square-fill"
        style={{
          color: "#B40D0D",
          fontSize: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      ></i>
    );
  };

  const hide = () => {
    setFilters(initFilters);
    setResourceTable([]);
    setSelectedResourceFilter([]);
    props.setSelectedResource(undefined);
    props.feel();
    props.setVis(false);
  };

  const filterResource = () => {
    props.search();
    props.setSelectedResource(undefined);
    setResourceTable([]);
    props.setVis(false);
  };

  const resetFilter = () => {
    setFilters(initFilters);
    setSelectedResourceFilter([]);
    props.setSelectedResource([]);
    props.feel();
  };

  return (
    <>
      <Dialog
        header={i18next.t("OpzioniAvanzate", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        })}
        visible={props.vis}
        style={{ width: "70vw" }}
        onHide={() => hide()}
      >
        <div className="card">
          <Tooltip target=".badge-punteggio" />
          <DataTable
            value={resourceTable}
            tableStyle={{ minWidth: "50rem" }}
            filterDisplay="row"
            sortMode="multiple"
            scrollable
            scrollHeight="30vw"
            selection={selectedResourceFilter}
            onSelectionChange={(e) => {
              setSelectedResourceFilter(e.value);
              props.setSelectedResource(e.value);
            }}
            dataKey="code"
            metaKeySelection={metaKey}
            dragSelection
            selectionMode="multiple"
            filters={filters}
            onFilter={(e: any) => setFilters(e.filters)}
          >
            <Column
              field="name"
              header={i18next.t("Denominazione", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              })}
              showFilterMenu={false}
              sortable
              style={{ width: "25%" }}
              filter
            ></Column>
            <Column
              field="competenza1"
              header={i18next.t("Competenza1", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              })}
              body={competenzaTemplate}
              showFilterMenu={false}
              sortable
              style={{ width: "25%" }}
              filter
            ></Column>
            <Column
              field="competenza2"
              header={i18next.t("Competenza2", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              })}
              body={competenza2Template}
              showFilterMenu={false}
              sortable
              style={{ width: "25%" }}
              filter
            ></Column>
            <Column
              field="competenza3"
              header={i18next.t("Competenza3", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              })}
              body={competenza3Template}
              showFilterMenu={false}
              sortable
              style={{ width: "25%" }}
              filter
            ></Column>
            <Column
              field="andamento"
              header={i18next.t("Andamento", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              })}
              body={andamentoTemplate}
              showFilterMenu={false}
              style={{ width: "25%" }}
            ></Column>
          </DataTable>
        </div>

        <div className="button-multi-resource">
          <Button
            label={i18next.t("Confirm", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
            style={{ marginLeft: "1vw" }}
            disabled={
              selectedResourceFilter.length && resourceTable.length
                ? false
                : true
            }
            onClick={() => filterResource()}
          />
          <Button
            label={i18next.t("ResetFiltro", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })}
            disabled={selectedResourceFilter.length ? false : true}
            style={{ marginLeft: "1vw" }}
            onClick={() => resetFilter()}
          />
          <Button
            label={i18next.t("Annulla", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
            onClick={() => hide()}
            className="panel-cancel-custom-button"
          />
        </div>
      </Dialog>
    </>
  );
};

export default DialogMultiResource;
