import {
  ConfigurationServiceSourceType,
  EditableDataService,
  EditableDataServiceResponse,
  Entity,
  EntityField,
  IConfigurationService,
  ICustomService,
  ICustomServiceResponse,
  IDataServiceResponse,
} from "@lcs/frontend";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { LinkedResource } from "./common.types";
import StdEditableDataService from "./std.edit.data.service";
import { BE_PREFIX } from "../utils/Constants";
import AppointmentCustomService from "./appointment.custom.service";
import AppointmentConfigurationService from "./appointment.configuration.service";
import { APPOINTMENT_LOCATION, APPOINTMENT_STATE } from "./appointment.service";

export interface Plan {
  idDipendentePianificato?: LinkedResource;
  // orarioPianificato?: string;
  idCliente?: LinkedResource;
  denominazione?: string;
  // durataPianificata?: string;
  // durataStimataInMezzore?: string;
  idAzienda?: LinkedResource;
  // idDipendenteEscluso?: LinkedResource;
  idDipendentePreferito?: LinkedResource;
  idProblema?: LinkedResource;
  luogoAppuntamento?: APPOINTMENT_LOCATION;
  indirizzo?: string;
  // specializzazioni?: LinkedResource[];
  // idTipoCliente?: LinkedResource;
  // orarioPreferito?: string;
  stato?: APPOINTMENT_STATE;
  // wfStatus?: APPOINTMENT_WF_STATE;
  // citta?: string;
  // provincia?: string;
  // indirizzoPianificato?: string;
  // cittaPianificata?: string;
  // provinciaPianificata?: string;
  // note?: string;
  // risultatoCheck?: any; //RisultatoChecks,
  // descrizione?: string;
}

export interface OrderServiceResponse extends ICustomServiceResponse {
  data: Plan[];
}

export interface SingleOrderServiceResponse extends ICustomServiceResponse {
  data: Plan;
}

const currentEntity: Entity = {
  id: 0,
  apiPath: "appuntamento",
  editable: true, // TODO: leggerlo da getEntitiesForRole?
  label: "APPUNTAMENTI",
};

class FilterByStatusService implements EditableDataService {
  readonly axios: AxiosInstance;
  readonly configurationService: IConfigurationService;
  readonly customService: ICustomService;
  readonly dataService: EditableDataService;
  readonly dataServiceFilter: string;

  constructor(axios: AxiosInstance, filter: string) {
    this.axios = axios;
    this.configurationService = new AppointmentConfigurationService({
      entityDefinitionSource: ConfigurationServiceSourceType.axios,
      axios: this.axios,
      entityDefinitionJsonFilePath: null,
      entityRoleJsonFilePath: null,
    });
    this.customService = new AppointmentCustomService(this.axios);
    this.dataService = new StdEditableDataService(currentEntity, this.customService, this.configurationService);
    this.dataServiceFilter = filter;
  }

  async getData(): Promise<IDataServiceResponse> {
    let config: AxiosRequestConfig = {
      params: {
        status: this.dataServiceFilter,
      },
    };
    const result = await this.axios.get(
      (process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api/data/appuntamento/filterByStato",
      config
    );
    return result.data;
  }

  getReferenceData(path: string): Promise<object[]> {
    return this.dataService.getReferenceData(path);
  }
  addObj(obj: object): Promise<EditableDataServiceResponse> {
    return this.dataService.addObj(obj);
  }
  updateObj(obj: object): Promise<EditableDataServiceResponse> {
    return this.dataService.updateObj(obj);
  }
  deleteObj(obj: object): Promise<EditableDataServiceResponse> {
    return this.dataService.deleteObj(obj);
  }
  async getConfiguration(): Promise<EntityField[]> {
    const result_1 = await this.dataService.getConfiguration();
    for (let index = 0; index < result_1.length; index++) {
      const element: EntityField = result_1[index];
      if (
        element.name === "denominazione" ||
        element.name === "wfStatus" ||
        element.name === "idCliente" ||
        element.name === "orarioPianificato" ||
        element.name === "indirizzoPianificato" ||
        element.name === "orarioChiusura"
      ) {
        element.hidden = false;
      } else {
        element.hidden = true;
      }
    }
    return result_1;
  }
  getDocument(documentCode: string): Promise<string> {
    return this.dataService.getDocument(documentCode);
  }

  getAll = async (): Promise<OrderServiceResponse> => {
    const result = await this.axios.get(
      (process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api/data/appuntamemnto/"
    );
    return result.data;
  };

  getById = async (orderId: string): Promise<SingleOrderServiceResponse> => {
    const result = await this.axios.get(
      (process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api/data/appuntamento/" + orderId
    );
    return result.data;
  };
  uploadPdf = async (customer: LinkedResource, base64: any): Promise<any> => {
    const body = {
      id_cliente: customer.id,
      file: base64,
    };
    const result = await this.axios.post(
      (process.env.REACT_APP_BE_URL ?? "") + BE_PREFIX.APPOINTMENT + "/api/data/loader/pdf/order", //TODO decide path
      body
    );
    return result.data;
  };
}

export default FilterByStatusService;
