import {
  CUSTOM_PRJ_TRANSLATION_FILE,
  CalendarServiceResponse,
  ConfigurationPanelContent,
  Entity,
  EntityFieldAndValue,
} from "@lcs/frontend";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import CalendarService from "../services/calendar.service";
import EmployeeService from "../services/employee.service";
import i18next from "i18next";
import { Toast } from "primereact/toast";
import OverlayPanelWorkPlan from "./OverlayPanelWorkPlan";
import FileUploadComponent from "./FileUploadComponent";
import { UseFormGetValues, UseFormWatch } from "react-hook-form";

interface PanelEmployeesProps {
  culture: string;
}

function PanelEmployees(props: PanelEmployeesProps) {
  const currentEntity: Entity = {
    id: 0,
    apiPath: "dipendente",
    editable: true, // TODO: leggerlo da getEntitiesForRole?
    label: "DIPENDENTI",
  };

  const dataService = new EmployeeService(api);
  const calendarService = new CalendarService(api);
  const myToast = useRef<any>();

  let navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {}, [refresh]);

  const sincroCall = async () => {
    setLoading(true);
    calendarService.getSincronizzazione().then((res: CalendarServiceResponse) => {
      if (res.success) {
        myToast.current.show({
          severity: "success",
          summary: i18next.t("SincronizzazioneCompletata", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
          detail: "OK",
        });
      } else {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          detail: res.reason, // TODO: translate
        });
      }
      setLoading(false);
    });
  };
  function createCustomFormContent(
    entityFieldAndValues: EntityFieldAndValue[],
    getValues: UseFormGetValues<any>,
    watch: UseFormWatch<any>,
    createConfigurationField: (efv: EntityFieldAndValue) => JSX.Element
  ) {
    // Definiamo i campi che ci interessano
    const fields = ["nome", "cognome", "denominazione", "idSede", "idOrarioGiornaliero"];

    // Usiamo reduce per creare un oggetto che contenga i campi rilevanti
    const entityFields = entityFieldAndValues.reduce((acc, efv) => {
      if (fields.includes(efv.name)) {
        acc[efv.name] = efv;
      }
      return acc;
    }, {} as Record<string, EntityFieldAndValue | undefined>);

    // Controlliamo e modifichiamo eDenominazione se i campi nome e cognome sono presenti
    if (entityFields.denominazione && entityFields.nome && entityFields.cognome) {
      const wNome = watch("nome")?.toString() || "";
      const wCognome = watch("cognome")?.toString() || "";
      //rendiamo denominazione readonly e il suo valore nome cognome (controllare il servizio)
      entityFields.denominazione.readonly = true;
      entityFields.denominazione.value = `${wNome} ${wCognome}`;
      entityFields.denominazione.mandatory = false;
    }

    // array con la configurazione per ogni campo
    const fieldConfigs = [
      { field: "denominazione", size: "col-12 md:col-4" },
      { field: "nome", size: "col-12 md:col-4" },
      { field: "cognome", size: "col-12 md:col-4" },
      { field: "idSede", size: "col-12 md:col-6" },
      { field: "idOrarioGiornaliero", size: "col-12 md:col-6" },
    ];

    // Renderizziamo il contenuto in modo dinamico
    return (
      <>
        <div className="formgrid grid">
          {fieldConfigs.map(({ field, size }) => (
            <div key={field} className={`field ${size}`}>
              {entityFields[field] && createConfigurationField(entityFields[field] as EntityFieldAndValue)}
            </div>
          ))}
        </div>
      </>
    );
  }
  return (
    <div>
      <Toast ref={myToast} />
      <OverlayPanelWorkPlan visible={loading} />
      <ConfigurationPanelContent
        createCustomFormContent={createCustomFormContent}
        label={currentEntity.label}
        customHeaderName="Nuovo Dipendente"
        editable={currentEntity.editable ? currentEntity.editable : false}
        dataService={dataService}
        colorHeaderPdf="#b40d0d"
        culture={props.culture}
        filterHeader=""
        columnButtons={[
          {
            name: "EmployeeDetails",
            label: "EmployeeDetails",
            icon: "pi pi-list",
            onClick: (event) => {
              navigate(`/employees/${event.id}`, {
                state: {
                  id: event.id,
                  name: event.name,
                },
              });
            },
          },
        ]}
        headerCustomButtons={[
          {
            name: "sincronizza",
            label: i18next.t("Sincronizza", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
            icon: "pi pi-calendar",
            background: "#2c3e50",
            border: "#2c3e50",
            onClick: (rowData: any) => sincroCall(),
          },
        ]}
        calendarService={calendarService}
        headerCustomComponent={<FileUploadComponent page={"dipendenti"} refresh={refresh} setRefresh={setRefresh} />}
      />
    </div>
  );
}

export default PanelEmployees;
