import React, { Fragment, useEffect, useState } from "react";
import { COMMON_TRANSLATION_FILE, CUSTOM_PRJ_TRANSLATION_FILE, ConfigurationPanelContent } from "@lcs/frontend";
// import { ORDER_STATE, OrderOut } from "../services/order.service";
import api from "../api";
import i18next from "i18next";
// import OrderEditForm, { EDITING_MODE } from "./OrderEditForm";
import { Dialog } from "primereact/dialog";
import FilterByStatusService from "../services/filterByStato.services";
import { APPOINTMENT_WF_STATE } from "../services/appointment.service";

export interface PanelProcessedOrdersProps {
  culture: string;
}

function PanelProcessedOrders(props: PanelProcessedOrdersProps) {
  // const emptyOrder: OrderOut = {
  //   id: 0,
  //   denominazione: "",
  //   idCliente: {
  //     id: 0,
  //     value: "",
  //   },
  //   stato: ORDER_STATE.EVASO,
  //   idAzienda: {
  //     id: 0,
  //     value: "",
  //   },

  const [dataService, setDataService] = useState<any>(
    new FilterByStatusService(
      api,
      `${APPOINTMENT_WF_STATE.CHIUSO_FALLIMENTO},
       ${APPOINTMENT_WF_STATE.CHIUSO_INTERLOCUTORIO},
       ${APPOINTMENT_WF_STATE.CHIUSO_SUCCESSO}
      `
    )
  );
  const [formMaximized, setFormMaximized] = useState<boolean>(false);
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
  // const [currentOrder, setCurrentOrder] = useState<OrderOut>();

  function getDetailsButton() {
    return {
      name: "ProcessedOrderDetails",
      label: i18next.t("View", { ns: CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE }),
      icon: "pi pi-search",
      onClick: (event: any) => {
        setDetailsVisible(true);
      },
    };
  }

  return (
    <Fragment>
      <ConfigurationPanelContent
        label="Appuntamenti chiusi"
        // customHeaderName="Appuntamenti chiusi"
        editable={false}
        culture={props.culture}
        dataService={dataService}
        colorHeaderPdf={window.getComputedStyle(document.documentElement).getPropertyValue("--main-color")}
        filterHeader=""
        columnButtons={[getDetailsButton()]}
      />
      <Dialog
        header={i18next.t("AppointmentDetails", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
        visible={detailsVisible}
        onHide={() => {
          setDetailsVisible(false);
        }}
        resizable={true}
        maximizable
        maximized={formMaximized}
        onMaximize={() => setFormMaximized(!formMaximized)}
      >
        {/* <OrderEditForm
          culture={props.culture}
          order={currentOrder}
          editingMode={EDITING_MODE.EDIT}
          onSaveSuccess={() => {
            // workaround per scatenare un ricaricamento dei dati
            setDataService(new FilterByStatusService(api));
          }}
        /> */}
      </Dialog>
    </Fragment>
  );
}

export default PanelProcessedOrders;
