import { AxiosInstance } from "axios";
import { ConfigurationServiceSourceType, Entity, EntityField, EntityForRole } from "@lcs/frontend";
import { BE_PREFIX } from "../utils/Constants";

export interface IConfigurationService {
    getEntities (instance: string|null): Promise<Entity[]>;
    getEntitiesForRole (): Promise<EntityForRole[]>;
    getDefinitions (entityPath: string): Promise<EntityField[]>;
}

interface EntityDef {
    entity: Entity;
    entityFieldsJsonFilePath: string | null;
}


export interface AppointmentConfigurationServiceProps {
    entityDefinitionSource : ConfigurationServiceSourceType;
    axios: AxiosInstance | null;
    entityDefinitionJsonFilePath: string | null;
    entityRoleJsonFilePath: string | null;
}

class AppointmentConfigurationService implements IConfigurationService {
    private readonly entityDefinitionSource  : ConfigurationServiceSourceType;
    private readonly axios : AxiosInstance | null = null;
    private readonly entityDefinitionJsonFilePath: string | null = null;
    private readonly entityRoleJsonFilePath: string | null = null;
    private entityPathCache : any = null;
    private bePath : string = "";
    
    constructor(props: AppointmentConfigurationServiceProps) {
        this.bePath = (process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT;
        this.entityDefinitionSource = props.entityDefinitionSource;
        if (props.entityDefinitionSource === ConfigurationServiceSourceType.axios) {
            if (!props.axios)
                throw Error("If entityDefinitionJsonFilePath is axios, a valid axios instance should be suppied.")
            this.axios = props.axios;
        } else {
            if (!props.entityDefinitionJsonFilePath)
                throw Error("If entityDefinitionSource is json, a valid path to a Json file should be supplied.")
            this.entityDefinitionJsonFilePath = props.entityDefinitionJsonFilePath;
            if (!props.entityRoleJsonFilePath)
                throw Error("If entityDefinitionSource is json, a valid path to a Json file should be supplied.")
            this.entityRoleJsonFilePath = props.entityRoleJsonFilePath;
        }
    }

    private getEntitiesFromAxios = (confInstance: string | null, axios : AxiosInstance) : Promise<Entity[]> => {
        let path = (confInstance)? this.bePath +  "/api/configuration/entity/" + confInstance : this.bePath + "/api/configuration/entity";
        return axios
            .get<Entity[]>(path)
            .then(result => result.data);
    }

    private getEntityFieldsFromAxios (axios: AxiosInstance, entityPath: string) : Promise<EntityField[]> {
        return axios
            .get<EntityField[]>(this.bePath + "/api/configuration/entityField/" + entityPath)
            .then(result => result.data)
    }
    
    private getEntitiesForUserRoles (axios: AxiosInstance) : Promise<EntityForRole[]> {
        return axios
            .get<EntityForRole[]>(this.bePath + "/api/configuration/entityForUserRoles/")
            .then(result => result.data)
    }

    private readJsonFile = (filePath: string) : Promise<string> => {
        var rawFile = new XMLHttpRequest();
        rawFile.overrideMimeType("application/json");
        rawFile.open("GET", filePath, true);
        return new Promise((resolve, reject) => {
            rawFile.onreadystatechange = function() {
                if (rawFile.readyState === 4 && rawFile.status === 200) {
                    resolve(rawFile.responseText)
                }
            }
            rawFile.send(null);
        })
    }

    private updateEntityPathCache = (entityArray : EntityDef[]) : void => {
        this.entityPathCache = {};
        entityArray.forEach((entityDef : EntityDef) => {
            this.entityPathCache[entityDef.entity.apiPath] = entityDef.entityFieldsJsonFilePath;
        });
    }

    private readEntitiesFromFile (filePath: string) : Promise<Entity[]> {
        return this.readJsonFile(filePath)
            .then((text: string) => {
                let entityDefArray: EntityDef[] = JSON.parse(text);
                this.updateEntityPathCache(entityDefArray);
                return entityDefArray.map((entityDef) => entityDef.entity);
            });
    }

    private readEntitiesForUserRolesFromFile (filePath: string) : Promise<EntityForRole[]> {
        return this.readJsonFile(filePath)
            .then((text: string) => {
                let entityDefArray: EntityForRole[] = JSON.parse(text);
                return entityDefArray;
            });
    }

    private readEntityFieldsFromFile(entityFieldsFilePath: string ) : Promise<EntityField[]> {
        return this.readJsonFile(entityFieldsFilePath)
        .then((text: string) => {
            var a = JSON.parse(text);
            return a; 
        });
    }

    getEntities = (instance: string|null) : Promise<Entity[]> => {
        if (this.entityDefinitionSource === ConfigurationServiceSourceType.axios)
            return this.getEntitiesFromAxios(instance, this.axios as AxiosInstance);
        else if (this.entityDefinitionSource === ConfigurationServiceSourceType.json)
            return this.readEntitiesFromFile(this.entityDefinitionJsonFilePath as string);
        else
            throw Error("Only axios or json source are managed");
    }

    getEntitiesForRole = () : Promise<EntityForRole[]> => {
        if (this.entityDefinitionSource === ConfigurationServiceSourceType.axios)
            return this.getEntitiesForUserRoles(this.axios as AxiosInstance);
        else if (this.entityDefinitionSource === ConfigurationServiceSourceType.json)
            return this.readEntitiesForUserRolesFromFile(this.entityRoleJsonFilePath as string);
        else
            throw Error("Only axios source is managed");
    }

    getDefinitions (entityPath: string) : Promise<EntityField[]> {
        if (this.entityDefinitionSource === ConfigurationServiceSourceType.axios)
            return this.getEntityFieldsFromAxios(this.axios as AxiosInstance, entityPath);
        else if (this.entityDefinitionSource === ConfigurationServiceSourceType.json){
            let p: Promise<any>;
            if (this.entityPathCache === null)
                p = this.readEntitiesFromFile(this.entityDefinitionJsonFilePath as string);
            else
                p = new Promise((resolve) => resolve(""));
            let entityFieldsFilePath : string | null = this.entityPathCache[entityPath];
            if (entityFieldsFilePath)
                return p.then(() => this.readEntityFieldsFromFile(entityFieldsFilePath as string));
            else
                return p.then(() => this.getEntityFieldsFromAxios(this.axios as AxiosInstance, entityPath));
        }
        else
            throw Error("Only axios or json source ar managed");
    }
}

export default AppointmentConfigurationService;