import React, { useRef, useState } from "react"
import { Card } from 'primereact/card'
import { useForm, SubmitHandler } from "react-hook-form"
import { Button } from 'primereact/button'
import 'primeicons/primeicons.css'
import { InputText } from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog'
import DialogRegister from "./DialogRegister"
import i18next from "i18next"
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend"
import axios from "axios"
import { Toast } from "primereact/toast";
import { useNavigate } from 'react-router-dom'
import { BE_PREFIX } from "../utils/Constants"

type Inputs = {
    name: String,
    surname: String,
    username: String,
    password: String,
    repeatPassword: String,
    email: String,
    repeatEmail: String,
    customer: String,
};

const PanelRegister = () => {
    const myToast = useRef<any>();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = data =>{
    } ;

    const [errore, setErrore] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)
    const [name, setName] = useState<String>("")
    const [surname, setSurname] = useState<String>("")
    const [username, setUsername] = useState<any>("")
    const [password, setPassword] = useState<String>("")
    const [repeatPassword, setRepeatPassword] = useState<String>("")
    const [email, setEmail] = useState<String>("")
    const [repeatEmail, setRepeatEmail] = useState<String>("")
    const [customer, setCustomer] = useState<String>("")

    const language = "it-IT"
    const modelType = "APPOINTMENT_MANAGEMENT"
    const userGroup = "BasicUser"
    const timezone = "Europe/Rome"

    const chiamata = async () => {
        if(customer === "" || repeatEmail === "" || email === "" || repeatPassword === "" || password === "" || name === "" || surname === "" || username === "" ){
            return
        }
            
            if(email !== repeatEmail){
                //errore email diverse
                myToast.current.show({
                    severity: 'error',
                    summary: i18next.t('EmailDiverse', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                });
                return
            } else if(password !== repeatPassword){
                //errore password diverse
                myToast.current.show({
                    severity: 'error',
                    summary: i18next.t('PasswordDiverse', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                });
                return
            }
    
            const data = await axios.post((process.env.REACT_APP_BE_URL??"") + BE_PREFIX.APPOINTMENT + '/api/data/regista/createCustomers', {name, surname, username, password, email, customer, language, modelType, userGroup, timezone} )
            const register = data.data
    
            if(register.success){
                myToast.current.show({
                    severity: 'info',
                    summary: i18next.t('EmailInviata', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                });   
                setVisible(true)
            } else {
                myToast.current.show({
                    severity: 'error',
                    summary: i18next.t('RegistrazioneErrore', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                });
            }
        

        
    }

    function closeDialog() {
        setVisible(false)
    }

    const takeName = (e: any) => {
        setName(e.target.value)
    }

    const takeSurname = (e: any) => {
        setSurname(e.target.value)
    }

    const takeUsename = (e: any) => {
        setUsername(e.target.value)
    }

    const takePassword = (e: any) => {
        setPassword(e.target.value)
    }

    const takeRepeatPassword = (e: any) => {
        setRepeatPassword(e.target.value)
    }

    const takeEmail = (e: any) => {
        setEmail(e.target.value)
    }

    const takeRepeatEmail = (e: any) => {
        setRepeatEmail(e.target.value)
    }

    const takeAzienda = (e: any) => {
        setCustomer(e.target.value)
    }

    return(
        <>
        <Toast ref={myToast} />
        <div className="card-register">
            <Card title={i18next.t('Registrati', { ns: CUSTOM_PRJ_TRANSLATION_FILE })} >
                <div className="card-content">
                    <div className="register-first">

                        <div className="panel-reg-div-row"> 
                            <label>{i18next.t('Nome', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}*</label>
                            
                            <InputText {...register("name", { required: true})} onChange={takeName} className="panel-reg-input"/>
                            {errors.name?.type === 'required' && <p role="alert" style={{color: "red", textAlign: "center", marginTop: "0px", marginBottom: "0px"}}>{i18next.t("IS_REQUIRED")}</p>}
                            
                        </div>

                        <div className="panel-reg-div-row">
                            <label>{i18next.t('Cognome', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}*</label>
                            
                            <InputText {...register("surname", { required: true})} onChange={takeSurname} className="panel-reg-input"/>
                            {errors.surname?.type === 'required' && <p role="alert" style={{color: "red", textAlign: "center", marginTop: "0px", marginBottom: "0px"}}>{i18next.t("IS_REQUIRED")}</p>}
                            
                        </div>

                        <div className="panel-reg-div-row">
                            <label>{i18next.t('Azienda', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}*</label>
                            
                            <InputText {...register("customer", { required: true, maxLength: 218 })} onChange={takeAzienda} className="panel-reg-input"/>
                            {errors.customer?.type === 'required' && <p role="alert" style={{color: "red", textAlign: "center", marginTop: "0px", marginBottom: "0px"}}>{i18next.t("IS_REQUIRED")}</p>}
                            
                        </div>

                        <div className="panel-reg-div-row">
                            <label>{i18next.t('Username', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}*</label>
                           
                            <InputText {...register("username", { required: true})} onChange={takeUsename} className="panel-reg-input"/>
                            {errors.username?.type === 'required' && <p role="alert" style={{color: "red", textAlign: "center", marginTop: "0px", marginBottom: "0px"}}>{i18next.t("IS_REQUIRED")}</p>}
                            
                        </div>
                    </div>

                    <div className="register-second">
                        <div className="panel-reg-div-row">
                            <label>Password*</label>
                            
                            <InputText {...register("password", { required: true})} onChange={takePassword} type="password" className="panel-reg-input"/>
                            {errors.password?.type === 'required' && <p role="alert" style={{color: "red", textAlign: "center", marginTop: "0px", marginBottom: "0px"}}>{i18next.t("IS_REQUIRED")}</p>}
                            
                        </div>

                        <div className="panel-reg-div-row">
                            <label>{i18next.t('RipetiPassword', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}*</label>
                            
                            <InputText {...register("repeatPassword", { required: true})} onChange={takeRepeatPassword} type="password" className="panel-reg-input"/>
                            {errors.repeatPassword?.type === 'required' && <p role="alert" style={{color: "red", textAlign: "center", marginTop: "0px", marginBottom: "0px"}}>{i18next.t("IS_REQUIRED")}</p>}
                            
                        </div>

                        <div className="panel-reg-div-row">
                            <label>Email*</label>
                            
                            <InputText {...register("email", { required: true})} onChange={takeEmail} className="panel-reg-input"/>
                            {errors.email?.type === 'required' && <p role="alert" style={{color: "red", textAlign: "center", marginTop: "0px", marginBottom: "0px"}}>{i18next.t("IS_REQUIRED")}</p>}
                            
                        </div>

                        <div className="panel-reg-div-row">
                            <label>{i18next.t('RipetiEmail', { ns: CUSTOM_PRJ_TRANSLATION_FILE })}*</label>
                            
                            <InputText {...register("repeatEmail", { required: true})} onChange={takeRepeatEmail} className="panel-reg-input"/>
                            {errors.repeatEmail?.type === 'required' && <p role="alert" style={{color: "red", textAlign: "center", marginTop: "0px", marginBottom: "0px"}}>{i18next.t("IS_REQUIRED")}</p>}
                           
                        </div>
                    </div>
                </div>

                <div >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="register-button">
                            <Button label={i18next.t('Annulla', { ns: CUSTOM_PRJ_TRANSLATION_FILE })} style={{width: "30vh", fontWeight: "bold"}} onClick={() => {navigate(-1)}}/>
                            <Button type="submit"  label={i18next.t('Registrati', { ns: CUSTOM_PRJ_TRANSLATION_FILE })} style={{width: "30vh", fontWeight: "bold"}} onClick={chiamata}/>
                        </div>
                    </form>
                </div>
                
            </Card>
        </div>

        <Dialog header={i18next.t('EmailCode', { ns: CUSTOM_PRJ_TRANSLATION_FILE })} 
                visible={visible} 
                style={{ width: '50vw' }} 
                onHide={() => setVisible(false)}>
            <DialogRegister username={username} change={closeDialog}/>
        </Dialog>
        </>
    )
}

export default PanelRegister