import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { CUSTOM_PRJ_TRANSLATION_FILE, ConfigurationPanelContent, TokenService, User } from "@lcs/frontend";
import api from "../api";
import AppointmentEsclService from "../services/appointment.escl.service";
import AppointmentSpecsService from "../services/appointment.specs.service";
import i18next from "i18next";

function PanelAppointmentsSpecEscl(props: { appointmentId: number | null }) {
  const getUserLocal = (): User | null => {
    var currentUser: User | null = {
      id: 0,
      name: "",
      surname: "",
      language: "",
      roles: [],
    };
    try {
      currentUser = TokenService.getUser();
    } catch (error) {}
    return currentUser;
  };

  let currentUser: User | null = getUserLocal();
  const dataServiceEscl = new AppointmentEsclService(api, props.appointmentId ?? 0);
  const dataServiceSpec = new AppointmentSpecsService(api, props.appointmentId ?? 0);

  return (
    <Accordion activeIndex={0}>
      <AccordionTab header={i18next.t("Specializzazioni", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}>
        <ConfigurationPanelContent
          label=""
          editable={true}
          culture={currentUser ? currentUser.language : "en-US"}
          dataService={dataServiceSpec}
          colorHeaderPdf="#b40d0d"
          filterHeader=""
        />
      </AccordionTab>
      <AccordionTab header={i18next.t("Esclusioni", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}>
        <ConfigurationPanelContent
          label=""
          editable={true}
          culture={currentUser ? currentUser.language : "en-US"}
          dataService={dataServiceEscl}
          colorHeaderPdf="#b40d0d"
          filterHeader=""
        />
      </AccordionTab>
    </Accordion>
  );
}

export default PanelAppointmentsSpecEscl;
