import {
  Configuration,
  IConfigurationService,
  ICustomService,
  ConfigurationServiceSourceType,
  TokenService,
  User,
} from "@lcs/frontend";
import api from "../api";
import React, { useEffect, useState } from "react";
import AppointmentCustomService from "../services/appointment.custom.service";
import AppointmentConfigurationService from "../services/appointment.configuration.service";
import AppointmentService from "../services/appointment.service";
import FileUploadComponent from "../components/FileUploadComponent";

function PageGeneralData() {
  const configurationService: IConfigurationService =
    new AppointmentConfigurationService({
      entityDefinitionSource: ConfigurationServiceSourceType.axios,
      axios: api,
      entityDefinitionJsonFilePath: null,
      entityRoleJsonFilePath: null,
    });

  const appointmentService: AppointmentService = new AppointmentService(api);

  const customService: ICustomService = new AppointmentCustomService(api);
  const getUserLocal = (): User | null => {
    var currentUser: User | null = {
      id: 0,
      name: "",
      surname: "",
      language: "",
      roles: [],
    };
    try {
      currentUser = TokenService.getUser();
    } catch (error) {}
    return currentUser;
  };
  let currentUser: User | null = getUserLocal();

  const [regole, setRegole] = useState<any>(null);
  const [listEntity, setListEntity] = useState<any>();
  const [page, setPage] = useState<string>("orarioGiornaliero");
  const [refresh, setRefresh] = useState<boolean>(false);

  const changeIndex = (data: any) => {
    if (!data) {
      setPage(listEntity[0].apiPath);
    } else {
      setPage(listEntity[data].apiPath);
    }
  };

  useEffect(() => {
    setRefresh(!refresh);
  }, [refresh]);

  useEffect(() => {
    configurationService.getEntities("Anagrafica").then((result: any) => {
      setListEntity(result);
    });

    if (regole) return;
    appointmentService.getRegoleConfigurazione().then((res: any) => {
      if (res.success && res.data)
        appointmentService.initRegoleConfigurazione();
    });
  }, []);

  return (
    <>
      <Configuration
        configurationService={configurationService}
        customService={customService}
        instance={"Anagrafica"}
        colorHeaderPdf="#b40d0d"
        culture={currentUser ? currentUser.language : "en-US"}
        filterHeader=""
        headerCustomComponent={
          <FileUploadComponent
            disabled={false}
            page={page}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        }
        changeIndex={changeIndex}
      />
    </>
  );
}

export default PageGeneralData;
