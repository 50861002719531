
import React from "react";
import CustomerUnavailabilityService from "../services/customer.unavailability.service";
import api from "../api";
import { ConfigurationPanelContent } from "@lcs/frontend";

export interface PanelCustomerUnavailabilityProps {
    culture: string;
    selectedCustomerId: number;
}

function PanelCustomerUnavailability(props: PanelCustomerUnavailabilityProps) {

    const dataService = new CustomerUnavailabilityService(api, props.selectedCustomerId);

    return <div>
        <ConfigurationPanelContent
            label="Customer unavailability"
            editable={true}
            culture={props.culture}
            dataService={dataService}
            colorHeaderPdf="blue"
            filterHeader=""
            refreshButton={false} />
    </div>;
}

export default PanelCustomerUnavailability;