import { IAuthService, LogoutRequest, RefreshTokenRequest, setComponentsCulture, TokenService } from "@lcs/frontend";
import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import i18next from "i18next";
import { defaultLanguage } from "../i18n";
import { BE_PREFIX, ROUTE} from "../utils/Constants";
import SmartHelperAuthService from "./smarthelper.auth.service";


export const shAppendInterceptors = (i : AxiosInstance) : void => {
  const authService : IAuthService = new SmartHelperAuthService(i);
  
  const appendAuthorizationHeader = (config: InternalAxiosRequestConfig) : InternalAxiosRequestConfig =>  {    
    const token = TokenService.getLocalAccessToken();
    if (token && token !== "") {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  }

  const doNothing = (response : AxiosResponse<any>) : AxiosResponse<any> => response;

  const forceLogout = async (callBELogout: boolean = true) => {
    const user = TokenService.getUser();
    if (user) {
      const request : LogoutRequest = {
        userId: user.id,
      };
      if (callBELogout){
        await authService.logout(request);
      }
      TokenService.removeUser();
      i18next.changeLanguage(defaultLanguage);
      setComponentsCulture(defaultLanguage);
    }    
    window.location.replace(process.env.PUBLIC_URL + ROUTE.SESSION_EXPIRED);
    //window.location.reload();
  };

  var refreshTokenPromise : Promise<any> | null = null;

  const manageError = (error: any) : Promise<any> => {
    const originalConfig = error.config;
    if (originalConfig.url.endsWith(BE_PREFIX.IDP + "/api/v1/auth/login"))
      return Promise.reject(error);

    if (originalConfig.url.endsWith(BE_PREFIX.IDP + "/api/v1/auth/refresh-token") &&
      error.response.status === 401){ // Refresh token scaduto
      //alert("Sessione utente scaduta");
      forceLogout();
      return Promise.resolve();//reject(error);
    }

    if (originalConfig.url.endsWith(BE_PREFIX.IDP + "/api/v1/auth/logout") &&
      error.response.status === 401){ 
      forceLogout(false);
      return Promise.reject(error);
    }

    if (!error.response || error.response.status !== 401)
      return Promise.reject(error); 

    if (error.response.status === 504){ 
      forceLogout(true); // TODO: redirect to error page
      return Promise.reject(error);
    }

    if (originalConfig._retry)
      return Promise.reject(error);
    
    originalConfig._retry = true;
    
    if (refreshTokenPromise) {
      return refreshTokenPromise.then(() => {
        return i(originalConfig);
      })
    } else {
      // cancello l'access token scaduto
      TokenService.updateLocalAccessToken("");        
      refreshTokenPromise = refreshToken(originalConfig, error);
      return refreshTokenPromise.then ((result) => {
        refreshTokenPromise = null;
        return result;
      });
    }
  };

  const refreshToken = (originalConfig: any, error: any) : Promise<any> => {
    let oldRefreshToken : string | null | undefined = TokenService.getLocalRefreshToken();
    if (oldRefreshToken) {
      try {
        const refreshTokenRequest : RefreshTokenRequest = {
          refreshToken: oldRefreshToken
        }
        return authService.refreshToken(refreshTokenRequest)
        .then((response) => {
          const { accessToken, refreshToken } = response;
          TokenService.updateLocalAccessToken(accessToken);
          TokenService.updateLocalRefreshToken(refreshToken);
          return i(originalConfig);
        }).catch((reason) => {
          forceLogout();
          return Promise.reject(reason);
        });
      } catch (_error) {
        forceLogout();
        return Promise.reject(_error);
      }
    } else {
      forceLogout();
      return Promise.reject(error);
    }
  };

  i.interceptors.request.use(appendAuthorizationHeader);
  i.interceptors.response.use(doNothing, manageError);

  // i.interceptors.response.use(
  //   response => response,
  //   error => {
  //     if (error.response.status === 403) {
  //       try{
  //         TokenService.removeUser();
  //       } catch (error){
  //         localStorage.removeItem("user");
  //       }
  //       i18next.changeLanguage(defaultLanguage);
  //       i18next.changeLanguage(defaultLanguage);
  //       setComponentsCulture(defaultLanguage);
  //       window.location.href = (process.env.REACT_APP_BE_URL??"") + BE_PREFIX.AUTHENTICATION + ROUTE.LOGIN;
  //     }
  //     else {
  //       return error.response
  //     }
  //   });
}
