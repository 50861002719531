import { CUSTOM_PRJ_TRANSLATION_FILE, ICustomServiceResponse } from "@lcs/frontend";
import i18next from "i18next";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ProblemService, { DefinedProblemResponse, IProblem, SubmitRequest } from "../services/problem.service";
import AppointmentService, { AppointmentOut, AppointmentServiceResponse, Plan } from "../services/appointment.service";
import api from "../api";
import { calculateAppointmentEndDate } from "../utils/Common";
import { useInterval } from "../hooks/useInterval";

function ButtonPlanning(props: { setRiepilogo: (arg1: boolean) => void; check: boolean; updateData: () => void }) {
  const PROBLEM_PLANNED_STATUS_COMPLETED = "COMPLETED";
  const [submittingProblem, setSubmittingProblem] = useState<boolean>(false);
  const myToast = useRef<any>();
  const problemService: ProblemService = new ProblemService(api);
  const appointmentService: AppointmentService = new AppointmentService(api);

  let POLLING_PLANNING_INTERVAL = 5000;
  const restartPlanningPollingInterval = useInterval(async () => {
    appointmentService
      .getMostRecentPlan()
      .then((plan: Plan) => {
        if (plan.stato === PROBLEM_PLANNED_STATUS_COMPLETED) {
          if (submittingProblem) {
            props.updateData();
            setSubmittingProblem(false);
            myToast.current.show({
              severity: "success",
              summary: i18next.t("PlanningCompleted", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              detail: "OK",
            });
            props.setRiepilogo(true);
          }
        } else setSubmittingProblem(true);
      })
      .catch((error: any) => {
        console.log("getMostRecentPlan with error: " + error);
        setSubmittingProblem(false);
      });
  }, POLLING_PLANNING_INTERVAL);

  const checkStartDate = (): Promise<boolean> => {
    let checkResult: boolean = false;
    let probStartDate: Date = new Date();
    return new Promise<boolean>((resolve, reject) => {
      // 1 - get start date of current problem
      problemService
        .getDefinedProblem()
        .then((probResp: DefinedProblemResponse) => {
          probStartDate = new Date(probResp.data[0].startDate);
        })
        .then(() => {
          // 2 - get closed appointments
          let maxEndDate: Date | undefined = undefined;
          appointmentService
            .getAllClosed()
            .then((res: AppointmentServiceResponse) => {
              if (res.success && res.data.length > 0) {
                res.data.forEach((appuntamento: AppointmentOut) => {
                  const eventStart: Date = appuntamento.orarioPianificato
                    ? new Date(appuntamento.orarioPianificato)
                    : new Date();
                  const endDate: Date = calculateAppointmentEndDate(eventStart, appuntamento.durataStimataInMezzore);
                  if (maxEndDate && maxEndDate >= endDate) {
                    return;
                  } else {
                    maxEndDate = endDate;
                  }
                });
                // 3 - return if there are closed appointements which ends after the start date.
                if (maxEndDate && maxEndDate <= probStartDate) checkResult = true;
                resolve(checkResult);
              } else if (res.success && res.data.length === 0) {
                resolve(true);
              } else {
                myToast.current.show({
                  severity: "error",
                  summary: i18next.t("Error", {
                    ns: CUSTOM_PRJ_TRANSLATION_FILE,
                  }),
                  detail: res.reason, // TODO: translate
                });
                reject(res.reason);
              }
            })
            .catch((reason: any) => {
              myToast.current.show({
                severity: "error",
                summary: i18next.t("Error"),
                detail: i18next.t("ErroreGeneraleChiusuraAppuntamento", {
                  ns: CUSTOM_PRJ_TRANSLATION_FILE,
                }),
              });
              // console.log(reason.message);
              reject(
                i18next.t("ErroreGeneraleChiusuraAppuntamento", {
                  ns: CUSTOM_PRJ_TRANSLATION_FILE,
                })
              );
            });
        })
        .catch((reason: any) => {
          myToast.current.show({
            severity: "error",
            summary: i18next.t("Error"),
            detail: i18next.t("ErroeGenericoDati", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
          });
          //   console.log(reason.message);
          reject(
            i18next.t("ErroreGeneraleChiusuraAppuntamento", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            })
          );
        });
    });
  };

  const getRegoleTerminazione = () => {
    return {
      terminationClass: null,
      terminationCompositionStyle: "OR",
      spentLimit: null,
      millisecondsSpentLimit: null,
      secondsSpentLimit: null,
      minutesSpentLimit: 10,
      hoursSpentLimit: null,
      daysSpentLimit: null,
      unimprovedSpentLimit: null,
      unimprovedMillisecondsSpentLimit: null,
      unimprovedSecondsSpentLimit: 60,
      unimprovedMinutesSpentLimit: null,
      unimprovedHoursSpentLimit: null,
      unimprovedDaysSpentLimit: null,
      unimprovedScoreDifferenceThreshold: null,
      bestScoreLimit: "[0/0]hard/[1000000/0/0/0]soft",
      bestScoreFeasible: null,
      stepCountLimit: null,
      unimprovedStepCountLimit: null,
      calculateCountLimit: null,
      scoreCalculationCountLimit: null,
      terminationConfigList: null,
    };
  };

  const submitProblemInternal = (regoleConversione: any[] | undefined, appProb: any) => {
    const subReq: SubmitRequest = {
      problemName: "Problema Unico",
      problemDescription: "APPOINTMENT_MANAGEMENT",
      rulesConfig: regoleConversione,
      problem: JSON.stringify(appProb),
      terminationConfig: null, //getRegoleTerminazione()
    };

    problemService.submit(subReq).then((result: ICustomServiceResponse) => {
      if (result.success) {
        myToast.current.show({
          severity: "success",
          summary: i18next.t("PlanningStarted", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }), // Pianificatore attivato
          detail: result.reason,
        });
        // TODO: refresh data -- library
      } else {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error"),
          detail: result.reason,
        });
        setSubmittingProblem(false);
        appointmentService.getMostRecentPlan().then((plan: Plan) => {
          appointmentService.deleteMostRecentPlan(plan).then((result) => {
            if (!result.success) {
              myToast.current.show({
                severity: "error",
                summary: i18next.t("PlanningAbortingError", {
                  ns: CUSTOM_PRJ_TRANSLATION_FILE,
                }),
                detail: result.reason,
              });
            }
          });
        });
      }
    });
  };

  const ExportAndSubmitProlem = () => {
    const myProb: IProblem = {
      id: null,
    };

    problemService
      .export(myProb)
      .then(async (appProb: any) => {
        if (appProb.error && appProb.message) {
          if (appProb.message === "Il problema deve contenere almeno un appuntamento") {
            myToast.current.show({
              severity: "error",
              summary: i18next.t("Error"),
              detail: i18next.t("ExportProblemaAppuntamenti", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
            });
            setSubmittingProblem(false);
          }
          if (appProb.message === "Il problema deve contenere almeno un dipendente") {
            myToast.current.show({
              severity: "error",
              summary: i18next.t("Error"),
              detail: i18next.t("ExportProblemaDipendenti", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
            });
            setSubmittingProblem(false);
          } else {
            myToast.current.show({
              severity: "error",
              summary: i18next.t("Error"),
              detail: "Generic error",
            });
            setSubmittingProblem(false);
          }
          return;
        }

        let regoleConversione: any[] | undefined = [];

        if (props.check) {
          regoleConversione = undefined;

          submitProblemInternal(regoleConversione, appProb);
        } else {
          if (appProb) {
            appointmentService.getRegoleConfigurazione().then((res: any) => {
              const data = res.data;

              if (data.length > 0) {
                data.forEach((element: any) => {
                  regoleConversione?.push(element);
                });
              } else {
                regoleConversione = undefined;
              }

              submitProblemInternal(regoleConversione, appProb);
            });
          }
        }
      })
      .catch((reason: any) => {});
  };

  const accept = () => {
    ExportAndSubmitProlem();
  };

  const reject = () => {
    myToast.current.show({
      severity: "warn",
      summary: i18next.t("Cancelled", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      detail: i18next.t("CancelledPlanning", {
        ns: CUSTOM_PRJ_TRANSLATION_FILE,
      }),
      life: 3000,
    });
  };

  const submitHandle = () => {
    restartPlanningPollingInterval();
    submit();
  };

  const submit = async (): Promise<void> => {
    await setSubmittingProblem(true);
    appointmentService.getAppointmentWithoutSpec().then((res: any) => {
      console.log(res);
      const data = res.success;

      checkStartDate().then((checkRes: boolean) => {
        if (data === false) {
          if (!checkRes) {
            confirmDialog({
              message: i18next.t("PlanningStartDateWarning", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              header: i18next.t("PlanningStartDateWarningHeader", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              icon: "pi pi-exclamation-triangle",
              accept,
              reject,
            });
            setSubmittingProblem(false);
            return;
          } else {
            ExportAndSubmitProlem();
          }
        } else {
          if (res.data.length > 0) {
            const stringMessggio: string = i18next.t("planningStartSpecWarning", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            });

            let appListString: string = "";

            if (Array.isArray(res.data)) {
              const appList = res.data;
              appList.forEach((app: any, index: number) => {
                // quando è ultimo elemento inserire il . altrimenti inserire la ,
                if (index === appList.length - 1) {
                  appListString += app.denominazione + ". ";
                } else {
                  appListString += app.denominazione + ", ";
                }
              });
            }

            confirmDialog({
              message:
                `${stringMessggio} ${appListString}` + i18next.t("procedere", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
              header: i18next.t("planningStartSpecWarningHeader", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              icon: "pi pi-exclamation-triangle",
              accept,
              reject,
            });
            setSubmittingProblem(false);
            return;
          } else {
            confirmDialog({
              message: "Vuoi eseguire la pianificazione?",
              header: i18next.t("Pianificazione pronta!", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              icon: "pi pi-spin pi-cog",
              accept,
              reject,
            });
            setSubmittingProblem(false);
            return;
          }
        }
      });
    });
  };

  return (
    <>
      <Fragment>
        <ConfirmDialog />
        <Toast ref={myToast} />
        <Button
          label={i18next.t("Submit", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
          icon="pi pi-play"
          style={{ margin: "0px" }}
          onClick={submitHandle}
          loading={submittingProblem}
          className="utility-button"
        />
      </Fragment>
    </>
  );
}

export default ButtonPlanning;
