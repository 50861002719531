import React from "react";
import api from "../api";
import { ConfigurationPanelContent } from "@lcs/frontend";
import EmployeeAbsenceService from "../services/employee.absence.service";

export interface PanelEmployeeAbsenceProps {
    culture: string;
    employeeId: number;
}

function PanelEmployeeAbsence(props: PanelEmployeeAbsenceProps) {

    const dataService = new EmployeeAbsenceService(api, props.employeeId);

    return <div>
        <ConfigurationPanelContent
            label="Employee absences"
            editable={true}
            culture={props.culture}
            dataService={dataService}
            colorHeaderPdf="blue"
            filterHeader=""
            refreshButton={false} />
    </div>;
}

export default PanelEmployeeAbsence;