import {
  COMMON_TRANSLATION_FILE,
  CUSTOM_PRJ_TRANSLATION_FILE,
  ICustomServiceResponse,
  IDataServiceResponse,
} from "@lcs/frontend";
import i18next from "i18next";
import React, { Fragment, useEffect, useRef, useState } from "react";
import AppointmentDetail from "./AppointmentDetail";
import { EventExtendedProps } from "../pages/PageWorkPlan";
import { Toast } from "primereact/toast";
import AppointmentService, {
  APPOINTMENT_WF_STATE,
  AppointmentOut,
  AppointmentServiceSingleResponse,
  ConfirmAppointmentRequest,
  SimpleAppointmentRequest,
} from "../services/appointment.service";
import api from "../api";
import DialogAppointmentClosure from "./DialogAppointmentClosure";
import { Button } from "primereact/button";
import DialogAppointmentManualPlanning from "./DialogAppointmentManualPlanning";
import AppointmentSpecsService from "../services/appointment.specs.service";
import { LinkedResource } from "../services/common.types";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { convertWfStateInState, getColorForEventState } from "../utils/Common";

export interface DialogEventDetailsProps {
  setEventDetailsVisible: any;
  eventDetailsVisible: boolean | undefined;
  eventDetails?: any;
  appointmentId?: number | null;
  appointmentName?: string | null;
  eventStatus?: any;
  onAction: () => void;
  culture: string;
  monIntegration: boolean;
}

function DialogEventDetails(props: DialogEventDetailsProps) {
  const myToast = useRef<any>();
  const [chooseCloseStateVisible, setChooseCloseStateVisible] = useState<boolean>(false);
  const [manualPlanningVisible, setManualPlanningVisible] = useState<boolean>(false);
  const [unplanningAppointment, setUnplanningAppointment] = useState<boolean>(false);
  const [confirmingAppointment, setConfirmingAppointment] = useState<boolean>(false);
  const [unconfirmingAppointment, setUnconfirmingAppointment] = useState<boolean>(false);
  const [eventDetails, setEventDetails] = useState<EventExtendedProps>({});

  const appointmentService = new AppointmentService(api);

  useEffect(() => {
    loadData();
  }, [props.appointmentId]);

  useEffect(() => {
    if (props.eventDetails) {
      if (
        props.appointmentId === props.eventDetails.id &&
        props.eventDetails.wfStatus === undefined // se cambiano ma sono incompleti
      ) {
        loadData(); // ricarico i dati
      } else {
        setEventDetails(props.eventDetails);
      }
    }
  }, [props.eventDetails]);

  function loadData() {
    if (props.appointmentId) {
      appointmentService
        .getById(props.appointmentId.toString())
        .then(async (result: AppointmentServiceSingleResponse) => {
          if (result.success) {
            let appuntamento: AppointmentOut = result.data;

            const appSpecService = new AppointmentSpecsService(api, appuntamento.id);
            let specializzazioni: LinkedResource[] = [];
            let appSpecRes: IDataServiceResponse = await appSpecService.getData();
            appSpecRes.data.forEach((spec: any) => {
              specializzazioni.push(spec.idSpecializzazione);
            });

            const extProps: EventExtendedProps = {
              luogoAppuntamento: appuntamento.luogoAppuntamento?.toString(),
              indirizzo: appuntamento.indirizzo ?? "",
              citta: appuntamento.citta ?? "",
              provincia: appuntamento.provincia ?? "",
              cliente: appuntamento?.idCliente?.value,
              tipoCliente: appuntamento.idTipoCliente?.value,
              durata: appuntamento.durataStimataInMezzore,
              specializzazioni: specializzazioni?.map((spec) => spec.value),
              idDipendente: appuntamento.idDipendentePianificato?.id,
              inizio: appuntamento.orarioPianificato ? new Date(appuntamento.orarioPianificato) : undefined,
              idAppuntamento: appuntamento.id.toString(),
              wfStatus: appuntamento.wfStatus,
              denominazione: appuntamento.denominazione,
              indirizzoPianificato: appuntamento.indirizzoPianificato,
              cittaPianificata: appuntamento.cittaPianificata ?? "",
              provinciaPianificata: appuntamento.provinciaPianificata ?? "",
              note: appuntamento.note,
              risultatoChecks: appuntamento.risultatoCheck,
              descrizione: appuntamento.descrizione,
            };
            setEventDetails(extProps);
          }
        });
    }
  }

  const openCloseAppointmentDialog = () => {
    setChooseCloseStateVisible(true);
  };

  const openDialogAppointementManualPlanning = () => {
    setManualPlanningVisible(true);
  };

  function onDialogAppointmentManualPlanningClose(refresh: boolean) {
    if (refresh) {
      loadData();
      setManualPlanningVisible(false);
      props.onAction();
    } else {
      setManualPlanningVisible(false);
    }
  }

  function onDialogAppointmentClosureClose(refresh: boolean) {
    if (refresh) {
      loadData();
      setChooseCloseStateVisible(false);
      props.onAction();
    } else {
      setChooseCloseStateVisible(false);
    }
  }

  const confirmAppointment = () => {
    setConfirmingAppointment(true);
    if (!eventDetails || !eventDetails.idAppuntamento) {
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: i18next.t("EmptyAppointment", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        }),
      });
      setConfirmingAppointment(false);
      return;
    }

    appointmentService.getById(eventDetails.idAppuntamento).then((res: AppointmentServiceSingleResponse) => {
      if (!eventDetails?.idDipendente || !eventDetails.inizio) {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          detail: i18next.t("NotPlanned", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
        });
        setConfirmingAppointment(false);
        return;
      }
      if (!res.success) {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          detail: i18next.t(res.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        });
        setConfirmingAppointment(false);
        return;
      }
      if (res.data.idDipendenteStabilito != null) {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          detail: i18next.t("ALREADY_CONFIRMED", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
        });
        setConfirmingAppointment(false);
        return;
      }
      let appData: ConfirmAppointmentRequest = {
        appointmentId: res.data.id,
        idDipendenteStabilito: eventDetails.idDipendente,
        orarioStabilito: eventDetails.inizio?.toISOString(),
      };
      appointmentService.comfirm(appData).then((result: ICustomServiceResponse) => {
        if (result.success) {
          loadData();
          myToast.current.show({
            severity: "success",
            summary: i18next.t("Success", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
            detail: i18next.t(result.reason, {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
          });
          props.onAction();
        } else {
          if (result.reason === "Invio appuntamento al monitoring fallito") {
            myToast.current.show({
              severity: "error",
              summary: i18next.t("Invio appuntamento al monitoring fallito", {
                //TODO: gestire con messaggio
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              detail: i18next.t(result.reason, {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              sticky: false,
            });
            props.onAction();
          } else {
            myToast.current.show({
              severity: "error",
              summary: i18next.t("Error", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              detail: i18next.t(result.reason, {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              sticky: false,
            });
          }
        }
      });
    });
    setConfirmingAppointment(false);
  };

  function unplanAppointment() {
    setUnplanningAppointment(true);
    if (!eventDetails || !eventDetails.idAppuntamento) {
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: i18next.t("EmptyAppointment", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        }),
      });
      setUnplanningAppointment(false);
      return;
    }

    appointmentService.getById(eventDetails.idAppuntamento).then((res: AppointmentServiceSingleResponse) => {
      if (!eventDetails?.idDipendente || !eventDetails.inizio) {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          detail: i18next.t("NotPlanned", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
        });
        setUnplanningAppointment(false);
        return;
      }
      if (!res.success) {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          detail: i18next.t(res.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        });
        setUnplanningAppointment(false);
        return;
      }

      let appData: SimpleAppointmentRequest = {
        appointmentId: res.data.id,
      };
      appointmentService.unplan(appData).then((result: ICustomServiceResponse) => {
        if (result.success) {
          myToast.current.show({
            severity: "success",
            summary: i18next.t("Success", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
            detail: i18next.t(result.reason, {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
          });
          setUnplanningAppointment(false);
          props.onAction();
        } else {
          myToast.current.show({
            severity: "error",
            summary: i18next.t("Error", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
            detail: i18next.t(result.reason, {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
          });
        }
      });
    });
    setUnplanningAppointment(false);
  }

  function unconfirmAppointment() {
    setUnconfirmingAppointment(true);
    if (!eventDetails || !eventDetails.idAppuntamento) {
      myToast.current.show({
        severity: "error",
        summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        detail: i18next.t("EmptyAppointment", {
          ns: CUSTOM_PRJ_TRANSLATION_FILE,
        }),
      });
      setUnconfirmingAppointment(false);
      return;
    }

    appointmentService.getById(eventDetails.idAppuntamento).then((res: AppointmentServiceSingleResponse) => {
      if (!eventDetails?.idDipendente || !eventDetails.inizio) {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          detail: i18next.t("NotPlanned", {
            ns: CUSTOM_PRJ_TRANSLATION_FILE,
          }),
        });
        setUnconfirmingAppointment(false);
        return;
      }
      if (!res.success) {
        myToast.current.show({
          severity: "error",
          summary: i18next.t("Error", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
          detail: i18next.t(res.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
        });
        setUnconfirmingAppointment(false);
        return;
      }

      let appData: SimpleAppointmentRequest = {
        appointmentId: res.data.id,
      };

      appointmentService.unconfirm(appData).then((result: ICustomServiceResponse) => {
        if (result.success) {
          loadData();
          myToast.current.show({
            severity: "success",
            summary: i18next.t("Success", {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
            detail: i18next.t(result.reason, {
              ns: CUSTOM_PRJ_TRANSLATION_FILE,
            }),
          });

          props.onAction();
        } else {
          if (result.reason === "Rimozione appuntamento dal monitoring fallita") {
            alert("Rimozione appuntamento dal monitoring fallita"); //TODO: gestire con messaggio
            setUnconfirmingAppointment(false);
            setTimeout(() => {
              props.onAction();
            }, 500);
          } else {
            myToast.current.show({
              severity: "error",
              summary: i18next.t("Error", {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
              detail: i18next.t(result.reason, {
                ns: CUSTOM_PRJ_TRANSLATION_FILE,
              }),
            });
          }
        }
      });
    });
    setUnconfirmingAppointment(false);
  }

  const getFooter = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          icon="pi pi-times"
          style={{ minWidth: "200px" }}
          label={i18next.t("Annulla", {
            ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
          })}
          onClick={() => props.setEventDetailsVisible(false)}
          className="panel-cancel-custom-button p-button p-component"
        />
        {eventDetails?.wfStatus === APPOINTMENT_WF_STATE.INIIZIALE && (
          <Button
            label={i18next.t("PlanAppointment", {
              ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
            })}
            onClick={openDialogAppointementManualPlanning}
            loading={confirmingAppointment}
          />
        )}
        {eventDetails?.wfStatus === APPOINTMENT_WF_STATE.PIANIFICATO && (
          <Fragment>
            <Button
              label={i18next.t("RimuoviPianificazione", {
                ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
              })}
              onClick={unplanAppointment}
              loading={unplanningAppointment}
            />
            <Button
              label={i18next.t("ConfermaAppuntamento", {
                ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
              })}
              onClick={confirmAppointment}
              loading={confirmingAppointment}
            />
          </Fragment>
        )}
        {eventDetails?.wfStatus === APPOINTMENT_WF_STATE.CONFERMATO && (
          <Fragment>
            <Button
              label={i18next.t("RimuoviConferma", {
                ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
              })}
              onClick={unconfirmAppointment}
              loading={unconfirmingAppointment}
            />
            <Button
              label={i18next.t("ChiudiAppuntamento", {
                ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE],
              })}
              onClick={openCloseAppointmentDialog}
            />
          </Fragment>
        )}
      </div>
    );
  };

  return (
    <>
      <Dialog
        visible={props.eventDetailsVisible}
        onHide={() => props.setEventDetailsVisible(false)}
        header={
          <div className="headerDialogDetails">
            <div>{eventDetails.denominazione}</div>
            <div>
              <Tag
                className="event-detail-status-tag"
                value={eventDetails.wfStatus}
                style={{
                  padding: "5px",
                  backgroundColor: getColorForEventState(
                    convertWfStateInState(eventDetails.wfStatus),
                    eventDetails.wfStatus === APPOINTMENT_WF_STATE.CONFERMATO.toString()
                  ),
                }}
              />
            </div>
          </div>
        }
        className="eventDetailsDialog"
        footer={getFooter()}
      >
        <Toast ref={myToast} />
        {eventDetails && (
          <>
            <AppointmentDetail
              eventDetails={eventDetails}
              culture={props.culture}
              monIntegration={props.monIntegration}
            />
            <DialogAppointmentClosure
              eventDetails={eventDetails}
              visible={chooseCloseStateVisible}
              onAction={onDialogAppointmentClosureClose}
            />
            {eventDetails.idAppuntamento && (
              <DialogAppointmentManualPlanning
                appointmentId={eventDetails.idAppuntamento}
                visible={manualPlanningVisible}
                onAction={onDialogAppointmentManualPlanningClose}
              />
            )}
          </>
        )}
      </Dialog>
    </>
  );
}

export default DialogEventDetails;
